import React, { useCallback, useRef, useEffect, useState } from 'react';
import { Form, Formik, ErrorMessage } from 'formik';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { IoAddSharp, IoCloseSharp } from 'react-icons/io5';
import { AiOutlineQuestionCircle, AiFillQuestionCircle } from 'react-icons/ai';
import ReactTooltip from 'react-tooltip';
import * as yup from 'yup';

import BusinessInformationBoard from 'components/business-information-board/business-information-board';
import TextInput from 'components/inputs/text-input/text-input';
import Textarea from 'components/inputs/textarea/textarea';
import Dropdown from 'components/inputs/dropdown/dropdown';
import Button from 'components/button/button';
import { getUserProfile } from 'redux/actions/user-actions';
import { formatNRIC, validNric } from 'helpers/utility';
import config from 'helpers/config';
import { useIsMount } from 'hooks/use-mount';
import { pathnames } from 'routes/routes';
import api from 'services/api';

const maxFiles = 10;

const fileErrors = [
    { type: 'fileSize', error: 'File Size is too large' },
    { type: 'fileType', error: 'Unsupported File Format' },
];

const validationSchemaCorporate = yup.object().shape({
    companyName: yup.string().required('Legal name / company name is required'),
    companyDescription: yup.string().required('Company description is required'),
    businessRegistrationNumber: yup.string().matches(/^[a-zA-Z0-9-_]+$/, "Business registration number can only contain alphanumeric and dash.").required('Business Registration Number is required'),
    companyIndustry: yup.string().required('Company Industry is required'),
    companyAddress: yup.string().required('Company address is required'),
    country: yup.number().moreThan(0, "Country is required").required('Country is required'),
    state: yup.string().required('State is required'),
    zipCode: yup.string().required('Zip / Postal Code is required'),
    identificationNumber: yup
        .string()
        .test('identificationNumber', 'Malaysia Identification Number is required', validNric),
    documents: yup.array().min(2, "Documents are required."),
    bankName: yup.string().required('Bank Name is required').nullable(),
    accountHolderName: yup.string().required('Account Holder Name is required'),
    accountNumber: yup.string().required('Account Number is required'),
    bankAddress: yup.string().required('Bank Address is required'),
    bankSwiftCode: yup.string().required('Bank Swift Code is required'),
    // ssmDoc: yup
    //     .mixed()
    //     .test(fileErrors[0].type, fileErrors[0].error, function (value) {
    //         const { ssmDoc } = this.parent;
    //         return value && ssmDoc?.size <= config.file.size ? true : !value ? true : false;
    //     })
    //     .test(fileErrors[1].type, fileErrors[1].error, function (value) {
    //         const { ssmDoc } = this.parent;
    //         return ssmDoc && config.file.docs.includes(ssmDoc.type) ? true : !value ? true : false;
    //     })
    //     .required('SSM document is required'),
});

const validationSchemaIndividual = yup.object().shape({
    personInCharge: yup.string().required('Full Name is required'),
    companyIndustry: yup.string().required('Company Industry is required'),
    companyAddress: yup.string().required('Company address is required'),
    country: yup.number().moreThan(0, "Country is required").required('Country is required'),
    state: yup.string().required('State is required'),
    zipCode: yup.string().required('Zip / Postal Code is required'),
    identificationNumber: yup
        .string()
        .test('identificationNumber', 'Malaysia Identification Number is required', validNric),
    documents: yup.array().min(2, "Documents are required."),
    bankName: yup.string().required('Bank Name is required').nullable(),
    accountHolderName: yup.string().required('Account Holder Name is required'),
    accountNumber: yup.string().required('Account Number is required'),
    bankAddress: yup.string().required('Bank Address is required'),
    bankSwiftCode: yup.string().required('Bank Swift Code is required'),
});

const AccountBusinessInformationPage = (props) => {
    const documentsRef = useRef();
    const formikRef = useRef();
    const isMount = useIsMount();
    const history = useHistory();
    const dispatch = useDispatch();
    const {
        companyName,
        personInCharge,
        companyAddress,
        state,
        zipCode,
        businessRegistrationNumber,
        companyDescription,
        companyIndustry,
        country,
        identificationNumber,
        companyVerified,
        companyDocuments,
        id,
        companyId,
        companyType,
        bankAccount,
    } = useSelector((state) => state.user.profile);
    const [onSubmit, setOnSubmit] = useState(false);
    const [countries, setCountries] = useState([]);
    const [files, setFiles] = useState([]);
    const [toolTipHover, setToolTipHover] = useState(false);
    const onHandleGetUserProfile = useCallback((o) => dispatch(getUserProfile(o)), [dispatch]);
    const submitBtnText = onSubmit ? 'UPLOADING' : 'SUBMIT TO VERIFY';
    const companyIsVerified = companyVerified === 1 || companyVerified === 3;
    const [hideBoard, setHideBoard] = useState(false);
	const [isOtherBank, setIsOtherBank] = useState(false);

    const initialValues = {
        companyName,
        personInCharge,
        businessRegistrationNumber,
        companyDescription,
        companyAddress,
        companyIndustry,
        state,
        zipCode,
        country,
        identificationNumber,
        documents: [],
        bankName: bankAccount && bankAccount.bankName,
        bankAddress: bankAccount && bankAccount.bankAddress,
        bankSwiftCode: bankAccount && bankAccount.bankSwiftCode,
        accountHolderName: bankAccount && bankAccount.accountHolderName,
        accountNumber: bankAccount && bankAccount.accountNumber,
    };

    const onHandleGetCountries = useCallback(async () => {
        try {
            const response = await api.get.countries();
            const mappedCountries = response.data.result.map((o) => ({
                label: o.country,
                value: o.id,
            }));
            const mysiaIndex = mappedCountries.findIndex(
                (o) => o.label.toLowerCase() === 'malaysia'
            );
            mappedCountries.unshift(mappedCountries[mysiaIndex]);
            mappedCountries.splice(mysiaIndex);
            formikRef.current.setFieldValue('country', mappedCountries[0].value);
            setCountries(mappedCountries);
        } catch (error) {
            console.log(error.response.data.message);
        }
    }, []);

    useEffect(() => {
        if (isMount) {
            onHandleGetCountries();
            if (companyIsVerified) {
                formikRef.current.setFieldValue('documents', companyDocuments);
            }
        }
    }, [isMount, onHandleGetCountries]);

    useEffect(() => {
		if (bankAccount && bankAccount.bankName) {
			let exist = config.banks.find((item) => item.value == bankAccount.bankName);
			
			if (exist) {
				setIsOtherBank(false)
			} else {
				setIsOtherBank(true)
			}
		}
	}, [bankAccount])

    const onHandleOnSubmit = async (values) => {
        setOnSubmit(true);
        try {
            const profileFormData = new FormData();
            const formData = new FormData();
            formData.append('companyId', companyId);

            Object.entries(values).forEach(([key, value]) => {
                if (key === 'documents') {
                    if (values.documents) {
                        values.documents.forEach((o) => {
                            formData.append('documents', o);
                        });
                    }
                } else if (key === 'personInCharge') {
                    profileFormData.append('id', id);
                    profileFormData.append(key, value)
                } else {
                    formData.append(key, value);
                }
            });

            if (values.bankName == "Others") {
                if (!values.otherBank) {
                    return props.onHandleShowMessage("Enter other bank name", true); 
                }
            }

            if (companyType == "Individual") {
                await api.post.profileUpdate(profileFormData);
            }
            await api.post.companyDetailsUpload(formData);
            onHandleGetUserProfile(id);
            props.onHandleShowMessage('Successful Uploaded');
        } catch (error) {
            props.onHandleShowMessage(error, true);
        } finally {
            setOnSubmit(false);
        }
    };

    const onHandleSetFiles = (file) => {
		setFiles((f) => [...f, file]);
		formikRef.current.setFieldValue('documents', [...files, file]);
	};

    const onHandleRemoveFiles = (index) => {
		const currentFiles = [...files];

		if (currentFiles.length === 1) {
			setFiles([]);
			formikRef.current.setFieldValue('documents', []);
		} else {
			currentFiles.splice(index, 1);
			setFiles(currentFiles);
			formikRef.current.setFieldValue('documents', currentFiles);
		}
	};

    const handleBusinessInformationBoard = () => {
        switch (companyVerified) {
            case 0: //Pending Verification Upload
                return <BusinessInformationBoard
                            title={companyType == "Corporate" ? "Upload document to verify your business account" : "Click here to upload your ID photo (Front & back), Bank statement (Latest 1 month)"}
                            desc={companyType == "Corporate" ? <span>Required document: SSM document, ID photo (front & back), Bank statement (Latest 1 month) <p className="m-txt--xs m-txt--grey">* You could also upload other supporting document for your business.</p></span> : null}
                            onClick={() => setHideBoard(true)}
                            image={true}
                        />
            case 1: //Verified
                return <BusinessInformationBoard
                            green={true}
                            title={<span>Your account has been <span className="m-txt m-txt--valid m-txt--bold">VERIFIED</span></span>}
                            desc="You could start to manage your product now."
                            onClick={() => setHideBoard(true)}
                            image={false}
                            button={<button onClick={() => history.push(pathnames.products)}>View / Add Product</button>}
                        />
            case 2: //Rejected
                return <BusinessInformationBoard
                            red={true}
                            title={<span>Verification <span className="m-txt m-txt--error m-txt--bold">FAILED</span></span>}
                            desc= {<span>Probably you’ve provided invalid info or files. Please try to upload and submit your info again or contact our customer service by email us at <a href="mailto:sample2021@gmail.com" className="m-link m-txt m-txt--bold">sample2021@gmail.com</a>.</span>}
                            onClick={() => setHideBoard(true)}
                            image={false}
                            button={<button>Re-upload</button>}
                        />
            case 3: //Submitted
                return <BusinessInformationBoard
                            title="Pending Verification"
                            desc={companyType == "Corporate" ? "We're verifying your business info. Please wait for 3-5 working days, you will get notified once the result is ready." : "We're verifying your info. Please wait for 3-5 working days, you will get notified once the result is ready."}
                            onClick={() => setHideBoard(true)}
                            image={false}
                        />
            default:
                break;
        }
    }

    return (
        <div className="account-buss-info">
            <div className="m-container">
                <div className="m-wrapper">
                    <div className="account-buss-info__card m-card">
                        <p className="account-buss-info__headline m-txt m-txt--bold m-txt--l">
                            Business Information
                        </p>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={companyType == "Corporate" ? validationSchemaCorporate : validationSchemaIndividual}
                            onSubmit={onHandleOnSubmit}
                            innerRef={formikRef}
                        >
                            {({ values, setFieldValue }) => (
                                <Form>
                                    <p className="m-txt m-txt--s">Documents For Verification</p>
                                    {!hideBoard ? (
                                        handleBusinessInformationBoard()
                                    ) : (
                                        <>
                                            <div className="account-buss-info__upload-wrapper">
                                                {companyDocuments.length > 1 && companyDocuments.map((o, i) => {
                                                    return (
                                                        <div className="account-buss-info__input-wrapper" key={i}>
                                                            <button
                                                                type="button"
                                                                className="account-buss-info__upload-button account-buss-info__upload-button--uploaded"
                                                                onClick={() => window.open(o, '_blank').focus()}
                                                            >
                                                                <div className="account-buss-info__icon-upload" />
                                                                <span>
                                                                    {o.split('/').pop()}
                                                                </span>
                                                            </button>
                                                        </div>
                                                    )
                                                })}
                                                {files.length > 0 && files.map((o, i) => {
                                                    return (
                                                        <div className="account-buss-info__input-wrapper" key={i}>
                                                            <button
                                                                type="button"
                                                                className="account-buss-info__upload-button account-buss-info__upload-button--uploaded"
                                                            >
                                                                <div className="account-buss-info__icon-upload" />
                                                                <span>
                                                                    {o.name}
                                                                </span>
                                                                <IoCloseSharp
                                                                    size={22}
                                                                    className="account-buss-info__icon-close"
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        onHandleRemoveFiles(i);
                                                                    }}
                                                                />
                                                            </button>
                                                        </div>
                                                    )
                                                })}
                                                {files.length < maxFiles && !companyIsVerified ? (
                                                    <div className="account-buss-info__input-wrapper">
                                                        <button
                                                            type="button"
                                                            className="account-buss-info__upload-button"
                                                            onClick={() => documentsRef.current.click()}
                                                        >
                                                            <IoAddSharp
                                                                size={22}
                                                                className="account-buss-info__icon-add"
                                                            />
                                                            <span>
                                                                Upload Documents
                                                            </span>
                                                        </button>
                                                        <input
                                                            type="file"
                                                            name="documents"
                                                            hidden
                                                            accept={config.file.businessDocs.toString()}
                                                            ref={documentsRef}
                                                            onChange={(e) => {
                                                                e.target.files.length !== 0 && onHandleSetFiles(e.target.files[0]);
                                                            }}
                                                        />
                                                        <p className="m-txt m-txt--s m-txt--error">
                                                            <ErrorMessage name="documents" />
                                                        </p>
                                                    </div>
                                                ) : null}
                                            </div>
                                            <p className="m-txt m-txt--s">Required Documents:</p>
                                            <p className="m-txt m-txt--s">{companyType == "Corporate" ? "SSM Document, " : null}ID photo (front & back)</p>
                                            {companyType == "Corporate" ? 
                                                <p className="m-txt m-txt--xs m-txt--grey">
                                                    <i> * You could also upload other supporting document for your business</i>
                                                </p> : null
                                            }
                                        </>
                                    )}
                                    <hr />
                                    {companyType == "Corporate" ? 
                                        <>
                                            <div className="account-buss-info__input-wrapper">
                                                <TextInput
                                                    outline
                                                    max
                                                    name="companyName"
                                                    placeholder="Enter company name"
                                                    label="Legal Name / Company Name"
                                                    disabled={companyIsVerified || onSubmit}
                                                />
                                                <p className="m-txt m-txt--s m-txt--error">
                                                    <ErrorMessage name="companyName" />
                                                </p>
                                            </div>
                                            <div className="account-buss-info__input-wrapper">
                                                <TextInput
                                                    outline
                                                    max
                                                    name="businessRegistrationNumber"
                                                    label="Business Registration Number"
                                                    placeholder="Enter business registration no."
                                                    disabled={companyIsVerified || onSubmit}
                                                />
                                                <p className="m-txt m-txt--s m-txt--error">
                                                    <ErrorMessage name="businessRegistrationNumber" />
                                                </p>
                                            </div>
                                            <div className="account-buss-info__input-wrapper">
                                                <Textarea
                                                    outline
                                                    max
                                                    name="companyDescription"
                                                    placeholder="Enter company description"
                                                    label="Company Description"
                                                    disabled={companyIsVerified || onSubmit}
                                                />
                                                <p className="m-txt m-txt--s m-txt--error">
                                                    <ErrorMessage name="companyDescription" />
                                                </p>
                                            </div>
                                            <div className="account-buss-info__input-wrapper">
                                                <TextInput
                                                    outline
                                                    max
                                                    name="companyIndustry"
                                                    label="Company Industry"
                                                    placeholder="Enter company industry"
                                                    disabled={companyIsVerified || onSubmit}
                                                />
                                                <p className="m-txt m-txt--s m-txt--error">
                                                    <ErrorMessage name="companyIndustry" />
                                                </p>
                                            </div>
                                            <div className="account-buss-info__input-wrapper">
                                                <TextInput
                                                    outline
                                                    max
                                                    onChange={(e) => {
                                                        setFieldValue(
                                                            'identificationNumber',
                                                            formatNRIC(e.target.value)
                                                        );
                                                    }}
                                                    name="identificationNumber"
                                                    placeholder="Enter ID no. e.g 990322-14-6033"
                                                    label="Malaysian Identification Number"
                                                    disabled={companyIsVerified || onSubmit}
                                                />
                                                <p className="m-txt m-txt--s m-txt--error">
                                                    <ErrorMessage name="identificationNumber" />
                                                </p>
                                            </div>
                                        </> : 
                                        <>
                                            <div className="account-buss-info__input-wrapper">
                                                <TextInput
                                                    outline
                                                    max
                                                    name="personInCharge"
                                                    placeholder="Enter full name"
                                                    label="Full Name"
                                                    disabled={companyIsVerified || onSubmit}
                                                />
                                                <p className="m-txt m-txt--s m-txt--error">
                                                    <ErrorMessage name="personInCharge" />
                                                </p>
                                            </div>
                                            <div className="account-buss-info__input-wrapper">
                                                <TextInput
                                                    outline
                                                    max
                                                    onChange={(e) => {
                                                        setFieldValue(
                                                            'identificationNumber',
                                                            formatNRIC(e.target.value)
                                                        );
                                                    }}
                                                    name="identificationNumber"
                                                    placeholder="E.g. 901122-14-6211"
                                                    label="Malaysian Identification Number (NRIC)"
                                                    disabled={companyIsVerified || onSubmit}
                                                />
                                                <p className="m-txt m-txt--s m-txt--error">
                                                    <ErrorMessage name="identificationNumber" />
                                                </p>
                                            </div>
                                            <div className="account-buss-info__input-wrapper">
                                                <TextInput
                                                    outline
                                                    max
                                                    name="companyIndustry"
                                                    label="Store Industry"
                                                    placeholder="Enter store industry. E.g. IT, Food & beverage & etc"
                                                    disabled={companyIsVerified || onSubmit}
                                                />
                                                <p className="m-txt m-txt--s m-txt--error">
                                                    <ErrorMessage name="companyIndustry" />
                                                </p>
                                            </div>
                                        </>
                                    }
                                    <hr />
                                    <div className="account-buss-info__input-wrapper">
                                        <TextInput
                                            outline
                                            max
                                            name="companyAddress"
                                            label="Address"
                                            placeholder={companyType == "Corporate" ? "Enter your company address" : "Enter your address"}
                                            disabled={companyIsVerified || onSubmit}
                                        />
                                        <p className="m-txt m-txt--s m-txt--error">
                                            <ErrorMessage name="companyAddress" />
                                        </p>
                                    </div>
                                    <div className="account-buss-info__input-wrapper">
                                        <Dropdown
                                            outline
                                            max
                                            value={
                                                config.malaysia.state.filter(
                                                    (o) => o.value === values.state
                                                )[0]
                                            }
                                            options={config.malaysia.state}
                                            disabled={companyIsVerified || onSubmit}
                                            label="State"
                                            placeholder="Select State"
                                            onChange={(state) =>
                                                setFieldValue('state', state.value)
                                            }
                                        />
                                        <p className="m-txt m-txt--s m-txt--error">
                                            <ErrorMessage name="state" />
                                        </p>
                                    </div>
                                    <div className="account-buss-info__input-wrapper">
                                        <Dropdown
                                            outline
                                            max
                                            value={
                                                countries.filter(
                                                    (o) => o.value === values.country
                                                )[0]
                                            }
                                            options={countries}
                                            disabled={companyIsVerified || onSubmit}
                                            label="Country / Region"
                                            placeholder="Country / Region"
                                            onChange={(country) =>
                                                setFieldValue('country', country.value)
                                            }
                                        />
                                        <p className="m-txt m-txt--s m-txt--error">
                                            <ErrorMessage name="country" />
                                        </p>
                                    </div>
                                    <div className="account-buss-info__input-wrapper">
                                        <TextInput
                                            outline
                                            max
                                            name="zipCode"
                                            label="Zip / Postal Code"
                                            placeholder="E.g. 12345"
                                            disabled={companyIsVerified || onSubmit}
                                        />
                                        <p className="m-txt m-txt--s m-txt--error">
                                            <ErrorMessage name="zipCode" />
                                        </p>
                                    </div>
                                    <hr />
                                    <p className="m-txt">Bank Account Details</p>
                                    <div className="account-buss-info__input-wrapper">
                                        <Dropdown
                                            outline
                                            max
                                            value={
                                                isOtherBank ? 
                                                "Others" : 
                                                config.banks.filter(
                                                    (o) => o.value === values.bankName
                                                )[0]
                                            }
                                            options={config.banks}
                                            disabled={companyIsVerified || onSubmit}
                                            label="Bank Name"
                                            placeholder="Bank Name"
                                            onChange={(bank) => {
                                                if (bank.value == "Others") {
                                                    setIsOtherBank(true)
                                                    setFieldValue('bankName', null)
                                                } else {
                                                    setIsOtherBank(false)
                                                    setFieldValue('bankName', bank.value)
                                                }
                                            }}
                                        />
                                        <p className="m-txt m-txt--s m-txt--error">
                                            <ErrorMessage name="bankName" />
                                        </p>
                                        {isOtherBank ? 
                                            <>
                                                <TextInput
                                                    outline
                                                    max
                                                    name="bankName"
                                                    placeholder="Enter Bank Name"
                                                    disabled={companyIsVerified || onSubmit}
                                                />
                                                <p className="m-txt m-txt--s m-txt--error">
                                                    <ErrorMessage name="bankName" />
                                                </p>
                                            </> : null
                                        }
                                    </div>
                                    <div className="account-buss-info__input-wrapper">
                                        <TextInput
                                            outline
                                            max
                                            name="accountHolderName"
                                            label="Account Holder Name"
                                            placeholder="Account Holder Name"
                                            disabled={companyIsVerified || onSubmit}
                                        />
                                        <p className="m-txt m-txt--s m-txt--error">
                                            <ErrorMessage name="accountHolderName" />
                                        </p>
                                    </div>
                                    <div className="account-buss-info__input-wrapper">
                                        <TextInput
                                            outline
                                            max
                                            name="accountNumber"
                                            label="Account Number"
                                            placeholder="Account Number"
                                            disabled={companyIsVerified || onSubmit}
                                        />
                                        <p className="m-txt m-txt--s m-txt--error">
                                            <ErrorMessage name="accountNumber" />
                                        </p>
                                    </div>
                                    <div className="account-buss-info__input-wrapper">
                                        <TextInput
                                            outline
                                            max
                                            name="bankAddress"
                                            label="Bank Address"
                                            placeholder="Bank Address"
                                            disabled={companyIsVerified || onSubmit}
                                        />
                                        <p className="m-txt m-txt--s m-txt--error">
                                            <ErrorMessage name="bankAddress" />
                                        </p>
                                    </div>
                                    <div className="account-buss-info__input-wrapper">
                                        <TextInput
                                            outline
                                            max
                                            name="bankSwiftCode"
                                            label={
                                                <span className="swift-code__tooltip--icon">Bank Swift Code 
                                                    <span data-tip data-for='swiftCode' className="swift-code__tooltip--icon">
                                                        {toolTipHover ? <AiFillQuestionCircle size={20} /> : <AiOutlineQuestionCircle size={20} />}
                                                    </span>
                                                </span>
                                            }
                                            placeholder="Bank Swift Code"
                                            disabled={companyIsVerified || onSubmit}
                                        />
                                        <ReactTooltip id='swiftCode' place="right" effect='solid' textColor="#000000" backgroundColor="#E6E6E6" afterShow={() => setToolTipHover(true)} afterHide={() => setToolTipHover(false)}>
                                            <div className="swift-code__tooltip">
                                                <p className="m-txt m-txt--s m-txt--bold">What is SWIFT Code?</p>
                                                <p className="m-txt m-txt--xs">
                                                    A SWIFT code is used to identify a particular branch of a bank. These codes play an important role in various bank transactions,
                                                    especially when it comes to international transactions. A SWIFT code may also be used by various banks to transfer other messages.
                                                </p>
                                                <br />
                                                <p className="m-txt m-txt--xs">Example:</p>
                                                <div className="swift-code__tooltip--wrapper">
                                                    <div>
                                                        <p className="m-txt m-txt--s m-txt--grey">Bank or Institution</p>
                                                        <p className="m-txt m-txt--s">AFFIN BANK BERHAD</p>
                                                    </div>
                                                    <div>
                                                        <p className="m-txt m-txt--s m-txt--grey">City</p>
                                                        <p className="m-txt m-txt--s">KUALA LUMPUR</p>
                                                    </div>
                                                    <div>
                                                        <p className="m-txt m-txt--s m-txt--grey">Swift Code</p>
                                                        <p className="m-txt m-txt--s">PHBMMYKL</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </ReactTooltip>
                                        <p className="m-txt m-txt--s m-txt--error">
                                            <ErrorMessage name="bankSwiftCode" />
                                        </p>
                                    </div>

                                    {!companyIsVerified ? 
                                        <div className="account-buss-info__button-wrapper">
                                            <Button
                                                type="submit"
                                                label={submitBtnText}
                                                disabled={companyIsVerified || onSubmit}
                                            />
                                        </div> : null
                                    }
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AccountBusinessInformationPage;
