import React, { useState } from 'react';
import { IoEllipseOutline, IoCheckmarkSharp, IoChevronForward } from 'react-icons/io5';

import CircleAvatar from 'components/circle-avatar/circle-avatar';
import RatingPicker from 'components/rating-picker/rating-picker';
import HeaderSort from 'components/headerSort/headerSort';

const ProductReviewTable = ({ productReviewList = [], onClick, onSort }) => {
    const [activeSort, setActiveSort] = useState(null);

    return (
        <table border="0" cellSpacing="0" cellPadding="0" className="product-review-table">
            <thead>
                <tr>
                    <th />
                    <th span="2">
                        <div className="order-table__header">
                            <p className="m-txt m-txt--white">Product name</p>
                            <HeaderSort 
                                sortFunction={onSort}
                                sortKey={"productName"}
                                setActiveSortOrder={setActiveSort}
                                activeSortOrder={activeSort}
                            />
                        </div>                    
                    </th>
                    <th span="2">
                        <p className="m-txt m-txt--white m-txt--bold">Product SKU</p>
                    </th>
                    <th>
                        <div className="order-table__header">
                            <p className="m-txt m-txt--white">No. reviews</p>
                            <HeaderSort 
                                sortFunction={onSort}
                                sortKey={"totalReview"}
                                setActiveSortOrder={setActiveSort}
                                activeSortOrder={activeSort}
                            />
                        </div> 
                    </th>
                    <th>
                        <div className="order-table__header">
                            <p className="m-txt m-txt--white">Average ratings</p>
                            <HeaderSort 
                                sortFunction={onSort}
                                sortKey={"rating"}
                                setActiveSortOrder={setActiveSort}
                                activeSortOrder={activeSort}
                            />
                        </div>
                    </th>
                    <th />
                </tr>
            </thead>
            <tbody>
                {productReviewList && productReviewList.map((o, i) => {
                    return (
                        <tr key={`${i}-product-review`} onClick={() => onClick(o)}>
                            <td span="2">
                                <CircleAvatar src={o.productImage} size={40} />
                            </td>
                            <td>
                                <p className="m-txt m-txt--bold">{o.productName}</p>
                            </td>
                            <td>
                                <p className="m-txt m-txt--bold">{o.sku}</p>
                            </td>
                            <td>
                                <p className="m-txt m-txt--s">
                                    {o.totalReview}
                                </p>
                            </td>
                            <td>
                                <div className="product-review-table__rating">
                                    <p className="m-txt">
                                        <span className="m-txt--bold">{o.rating}</span> 
                                        <span className="m-txt--grey"> / 5</span>
                                    </p>
                                    <RatingPicker value={o.rating} />
                                </div>
                            </td>
                            <td>
                                <IoChevronForward size={22} className="m-txt m-txt--grey" />
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};

export default ProductReviewTable;
