import React, { useState, useEffect } from 'react';
import EmptySearchIcon from 'assets/icons/icon-empty-search.svg';

const EmptyState = ({ error, emptyList, emptyFilter, emptyListMsg, errorIcon, emptyListIcon, button }) => {
    const [message, setMessage] = useState("");
	const [icon, setIcon] = useState("");

	useEffect(() => {
		onMessageHandler(); 
	}, [error, emptyList, emptyFilter])

	const onMessageHandler = () => {
		if (error) {
			setMessage("Something went wrong. Please try again later.")
		} else if (emptyList) {
			setMessage(emptyListMsg)
			setIcon(emptyListIcon)
		} else {
			setMessage("Sorry, we can't find any matches.")
			setIcon(EmptySearchIcon)
		}
	}

    return (
        <div className="empty-state">
            <img src={icon} alt="empty-state-icon" />
            <p className="m-txt m-txt--m m-txt--grey empty-state--msg">
                {message}
            </p>
            {emptyList && button ? 
                button : null
            }
        </div>

    )
}

export default EmptyState;