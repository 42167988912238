import React, { Fragment, memo, useCallback, useState, useEffect, useRef } from 'react';
import { Link, NavLink, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    IoChevronDownOutline,
    IoLogOutOutline,
    IoPersonOutline,
    IoSettingsOutline,
    IoNotificationsOutline,
    IoNotifications
} from 'react-icons/io5';

import { setUserLogout } from 'redux/actions/user-actions';
import { pathnames } from 'routes/routes';
import logo from 'assets/navbar/logo.png';
import api from 'services/api';
import { formatDate } from 'helpers/utility';
import Popup from 'components/popup/popup';

const NavBar = memo(() => {
    const history = useHistory();
    const location = useLocation();
    const scrollRef = useRef();
	const pagesRef = useRef({ size: 45, page: 0 });
    var totalPages = 0;

    const { auth, profile } = useSelector((state) => state.user);
    const [notifications, setNotifications] = useState([]);
    const [notificationHover, setNotificationHover] = useState(false);
    const [showAnnouncement, setShowAnnouncement] = useState(false);
    const [selectedAnnouncement, setSelectedAnnouncement] = useState();

    useEffect(() => {
        if (auth) {
            fetchNotifications()
        }
    }, [])

    const fetchNotifications = async () => {
        try {
            let payload = {
                ...pagesRef.current
            }
            const resp = await api.get.notifications(profile.id, payload);
            totalPages = resp.data.result.totalPage;

            if (pagesRef.current.page == 0) {
                setNotifications(resp.data.result.notifications)
            } else {
                setNotifications((o) => { return [...o, ...resp.data.result.notifications] })
            }

        } catch (error) {
            console.log(error)
            setNotifications([])
        }
    }

    const onHandleScrollGetNotification = () => {
        if (pagesRef.current.page + 1 < totalPages) {
            pagesRef.current.page += 1;
            fetchNotifications();
        }
    }

    useEffect(() => {
        const onHandleScroll = (e) => {
            const target = e.target;
            const scrollHeight = target.scrollHeight - target.clientHeight;
            const currentScroll = Math.round(target.scrollTop);

            if (scrollHeight <= currentScroll) {
                onHandleScrollGetNotification()
            }
        };
        scrollRef?.current?.addEventListener('scroll', onHandleScroll);
    }, [])

    const notificationDropdown = () => {
        return (
            <div className="nav-bar__notification--dropdown" ref={scrollRef}>
                <ul>
                    {notifications?.map((o,i) => {
                        return (
                            <li key={i} onClick={() => handleNotificationRedirect(o)}>
                                <div className={o.status == "unread" ? "nav-bar__notification--item" : "nav-bar__notification--item nav-bar__notification--read" }>
                                    <div className="nav-bar__notification--content">
                                        <p className="m-txt m-txt--bold">{o.title}</p>
                                        {o.notificationType == "order" ? 
                                            <p className="m-txt m-txt--xs">
                                                Order no: 
                                                <span className="m-txt m-txt--bold"> {o.orderNumber}</span>
                                            </p> :
                                            <p className="m-txt m-txt--xs" style={{textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"}}>
                                                {o.description} 
                                            </p>
                                        }
                                    </div>
                                    <div className="nav-bar__notification--date">
                                        <p className="m-txt m-txt--xs">{formatDate(o.updatedAt)}</p>
                                        {o.status == "unread" ? <span className="greenDot"></span> : null}
                                    </div>
                                </div>
                            </li>
                        )
                    })}
                </ul>
            </div>
        )
    }

    const handleNotificationRedirect = async (item) => {
        try {
            const payload = {
                adminId: null,
                customerId: null,
                merchantId: profile.id,
                notificationId: item.id, 
                notificationType: item.notificationType
            }
            await api.post.readNotification(payload);
            fetchNotifications()
        } catch (error) {
            console.log(error)
        }

        switch (item.notificationType) {
            case "order":
                history.push(pathnames.orderDetails, { orderId: item.id, editMode: true });
                break;
            case "announcement":
                setSelectedAnnouncement(item)
                setShowAnnouncement(true)
                break;
            default:
                break;
        }
    }

    return (
        <nav className="nav-bar">
            <div className="nav-container">
                {!auth ? (
                    <Link to="/">
                        <img className="nav-bar__logo" src={logo} alt="" />
                    </Link>
                ) : (
                    <Fragment>
                        <div />
                        <div className="nav-bar__action-wrapper">
                            <div className="nav-bar__notification" onMouseEnter={() => setNotificationHover(true)} onMouseLeave={() => setNotificationHover(false)}>
                                <span className="redDot"></span>
                                {notificationHover ? <IoNotifications size={20} color={"white"} /> : <IoNotificationsOutline size={20} color={"white"} /> }
                                {notificationDropdown()}
                            </div>
                            <span className="divider"></span>
                            <div className="nav-bar__account-wrapper">
                                <NavLink to="/accounts" activeClassName="nav-bar__link--active" className="nav-bar__link nav-bar__link--account">
                                    <IoPersonOutline size={18} />
                                    <span>My Account</span>
                                    <IoChevronDownOutline size={18} />
                                </NavLink>
                                <AccountDropdown />
                            </div>
                        </div>
                        <Popup open={showAnnouncement} onPopupClose={() => setShowAnnouncement(false)} >
                            <div className="announcement__popup">
                                <div className="announcement__popup--msg">
                                    <p className="m-txt m-txt--m m-txt--bold">Announcement</p>
                                    <p className="m-txt m-txt--xs">{formatDate(selectedAnnouncement?.updatedAt)}</p>
                                    <p className="m-txt">{selectedAnnouncement?.description}</p>
                                </div>
                            </div>
                        </Popup>
                    </Fragment>
                )}
            </div>
        </nav>
    );
});

export default NavBar;

const AccountDropdown = memo(() => {
    const dispatch = useDispatch();
    const logout = useCallback(() => dispatch(setUserLogout()), [dispatch]);
    const history = useHistory();

    const onHandleLogout = () => {
        logout();
        localStorage.clear();
        history.push(pathnames.home);
    };

    const accountSettings = [
        {
            to: '/account/profile',
            label: 'Profile',
            icon: <IoPersonOutline size={18} />,
        },
        {
            to: '/account/settings',
            label: 'Account Settings',
            icon: <IoSettingsOutline size={18} />,
        },
        {
            label: 'Logout',
            to: '',
            icon: <IoLogOutOutline size={18} />,
            onClick: onHandleLogout,
        },
    ];

    return (
        <div className="nav-bar__dropdown">
            <ul>
                {accountSettings.map((o, i) => {
                    return (
                        <li key={`${i}-${o.to}`} onClick={o.onClick}>
                            <Link to={o.to} className="nav-bar__link">
                                {o.icon}
                                <span>{o.label}</span>
                            </Link>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
});
