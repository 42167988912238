import { useState, memo } from 'react';
import { Field } from 'formik';
import { IoEyeOutline, IoEyeOffOutline } from 'react-icons/io5';

const TextInput = ({ outline, max, ...props }) => {
    const [eyes, setEyes] = useState(false);
    const passwordField = props.type === 'password';
    const passwordIsHidden = passwordField && !eyes;
    const passwordIsShow = passwordField && eyes;
    const inputType = passwordIsShow ? 'text' : passwordIsHidden ? props.type : props.type;
    const className = ['text-input__field'];

    if (passwordField) {
        className.push('text-input__field--password');
    }
    if (outline) {
        className.push('text-input__field--outline');
    }
    if (max) {
        className.push('text-input__field--max');
    }


    const passwordIcon = passwordIsHidden ? (
        <IoEyeOutline size={28} />
    ) : passwordIsShow ? (
        <IoEyeOffOutline size={28} />
    ) : null;

    const onHandleTogglePassword = () => {
        setEyes(!eyes);
    };

    return (
        <div className="text-input">
            <label className="m-txt m-txt--s text-input__label" htmlFor={props.name}>
                {props.label}
            </label>
            <div className="text-input__container">
                <Field {...props} className={className.join(" ")} type={inputType} />
                <div className="text-input__eyes" onClick={onHandleTogglePassword}>
                    {passwordIcon}
                </div>
            </div>
        </div>
    );
};

export default memo(TextInput);
