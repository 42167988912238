import React, { Fragment, useRef, useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import SearchBar from 'components/search-bar/search-bar';
import ProductCard from 'components/product-card/product-card';
import ProductTable from 'components/tables/product-table/product-table';
import createProductIcon from 'assets/icons/icon-create-product.svg';
import listIcon from 'assets/icons/icon-list.svg';
import gridIcon from 'assets/icons/icon-grid.svg';
import activateProductIcon from 'assets/icons/icon-activate-product.svg';
import { useIsMount } from 'hooks/use-mount';
import { pathnames } from 'routes/routes';
import api from 'services/api';
import Button from 'components/button/button';
import Filter from 'components/filter/filter';
import Pagination from 'components/pagination/pagination';
import Popup from 'components/popup/popup';
import Alert from 'components/alert/alert';
import EmptyState from 'components/empty-state/empty-state';
import EmptyProductsIcon from 'assets/icons/icon-empty-products.svg';

const initialData = {
    allDisabled: false,
    productList: [],
    totalPage: 0,
};

const ProductsPage = (props) => {
    const isMount = useIsMount();
    const history = useHistory();
    const { companyId } = useSelector((state) => state.user.profile);
    const [merchant, setMerchant] = useState(initialData);
    const [isGrid, setIsGrid] = useState(false);
    const [showActivateProduct, setShowActivateProduct] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [selectedFilter, setSelectedFilter] = useState(0);
    const [search, setSearch] = useState();
    const [sort, setSort] = useState({
        key: "",
        order: ""
    });
    const [alert, setAlert] = useState({
		show: false,
		message: ""
	})
    const [filters, setFilters] = useState([
        { label: "All", value: "" },
    ])

    const onHandleGetProducts = useCallback(
        async (params = {}) => {
            try {
                const payload = { 
                    companyId, 
                    sort: sort.key,
                    order: sort.order,
                    value: search,
                    page: currentPage,
                    length: 11,
                    ...params 
                };
                const response = await api.get.products(payload);
                const data = response.data.result;
                setMerchant(data);
            } catch (error) {
                props.onHandleShowMessage(error);
                history.goBack();
            }
        },
        [companyId, merchant, props, history, currentPage, search]
    );

    const getActiveCategories = async () => {
        try {
            const response = await api.get.activeCategories();
            const data = response.data.result;

            let newFilters = [];
            data.map((o) => {
                newFilters.push({
                    label: o.name,
                    value: o.id
                })
            })

            setFilters(filters => [...filters, ...newFilters])
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        if (selectedFilter !== 0) {
            onHandleGetProducts({ categories: filters[selectedFilter].value })
        } else {
            onHandleGetProducts()
        }
    }, [currentPage, selectedFilter, search])

    useEffect(() => {
        if (isMount) {
            onHandleGetProducts();
            getActiveCategories()
            setIsGrid(localStorage.getItem('isGrid') == "true")
        }
    }, [isMount, onHandleGetProducts]);

    const onHandleOnSubmit = (o) => {
        setSearch(o)
        setCurrentPage(0)
        setSelectedFilter(0)
        
        onHandleGetProducts({ page: 0, value: o });
    };

    const onHandleEditProduct = (o) => {
        history.push(pathnames.productCreate, { product: o, editMode: true });
    };

    const onHandleCreateProduct = (o) => {
        history.push(pathnames.productCreate);
    };

    const onHandleChangeGrid = () => {
        localStorage.setItem('isGrid', !isGrid);
        setIsGrid(!isGrid);
    };

    const handleFilter = (item) => {
        setSelectedFilter(_.findIndex(filters, item))
        setCurrentPage(0)
        setSearch("")
        setSort({
            key: "",
            order: ""
        })

        if (item.value) {
            onHandleGetProducts({ categories: item.value });
        } else {
            onHandleGetProducts()
        }
    }

    const handleSort = (sortKey, order) => {
        setSort({
            key: sortKey,
            order: order
        })

        onHandleGetProducts({ sort: sortKey, order: order })
    }

    const handlePagination = (value) => {
        const currentIndex = Math.max(0, Math.min(currentPage + value, merchant.totalPage - 1));
		setCurrentPage(currentIndex);
	};
    
	const handleSpecificPagination = (value) => {
        setCurrentPage(value)
	};

    const handleActivateProduct = async () => {
        try {
            const resp = await api.patch.productActivateAll(companyId)
            if (resp.data.success) {
                setShowActivateProduct(false)
                onHandleGetProducts()
                setAlert({
                    show: true,
                    message: "Products published"
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <>
            {merchant.productList.length == 0 && !search && selectedFilter == 0 ? 
                <EmptyState 
                    emptyList={true} 
                    emptyListMsg="Sorry, no product yet"
                    emptyListIcon={EmptyProductsIcon}
                    button={<Button small gold label="ADD PRODUCT" onClick={onHandleCreateProduct} />}
                /> :
                <div className="products">
                    <div className="m-container">
                        <div className="m-wrapper">
                            <div className="products__header">
                                <SearchBar onSubmit={onHandleOnSubmit} value={search} placeholder="Search Product Id, Name, Category"/>
                                <div className="products__add-action">
                                    <Button small label="ADD NEW PRODUCT" onClick={onHandleCreateProduct} />
                                    <div className="products__grid-action" onClick={onHandleChangeGrid}>
                                        <img
                                            src={isGrid ? listIcon : gridIcon}
                                            className="products__grid-icon"
                                            alt=""
                                        />
                                    </div>
                                </div>
                            </div>
                            <Filter filters={filters} selected={selectedFilter} onClick={(item) => handleFilter(item)}/>
                            {merchant.allDisabled ? 
                                <div className="products__disable-wrapper">
                                    <p className="m-txt">Activate and publish all products</p>
                                    <button className="products__disable-btn products__disable-btn--disabled" onClick={() => setShowActivateProduct(true)}>
                                        <Fragment>
                                            <span></span>
                                        </Fragment>
                                    </button> 
                                </div>
                                : null
                            }
                            {merchant.productList.length == 0 && search ? 
                                <EmptyState emptyFilter={true}/> :
                                <>
                                    <div className="products__card-wrapper">
                                        {isGrid ? 
                                            (
                                                <ProductCard
                                                    products={merchant.productList}
                                                    onClick={onHandleEditProduct}
                                                    triggerRefresh={onHandleGetProducts}
                                                />
                                            ) : 
                                            (
                                                <ProductTable
                                                    onNavigate={onHandleEditProduct}
                                                    productList={merchant.productList}
                                                    onSort={handleSort}
                                                    triggerRefresh={onHandleGetProducts}
                                                    allDisabled={merchant.allDisabled}
                                                />
                                            )
                                        }
                                    </div>
                                    <Pagination 
                                        totalPages={merchant.totalPage} 
                                        currentPage={currentPage} 
                                        onPageClick={handleSpecificPagination} 
                                        onArrowClick={handlePagination}
                                    />
                                </>
                            }
                            <Popup open={showActivateProduct} onPopupClose={() => setShowActivateProduct(false)} icon={<img src={activateProductIcon}/>} >
                                <div className="products__popup">
                                    <div className="products__popup--msg">
                                        <p className="m-txt m-txt--m m-txt--bold">Are you sure you want to activate and <br /> publish all the products?</p>
                                    </div>
                                    <div className="products__popup--btn">
                                        <Button text label="CANCEL" onClick={() => setShowActivateProduct(false)} />
                                        <Button text gold label="PUBLISH ALL" onClick={() => handleActivateProduct()}/>
                                    </div>
                                </div>
                            </Popup>
                            <Alert message={alert.message} show={alert.show} onClose={(show) => setAlert({show})} />
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default ProductsPage;
