import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { IoChevronForward } from 'react-icons/io5';

import BusinessInformationBoard from 'components/business-information-board/business-information-board';
import CircleAvatar from 'components/circle-avatar/circle-avatar';
import toProcess from 'assets/icons/icon-process.svg';
import toShipping from 'assets/icons/icon-ready-to-ship.svg';
import toShipped from 'assets/icons/icon-shipped.svg';
import toCancellation from 'assets/icons/icon-cancellation.svg';
import toProductReview from 'assets/icons/icon-product-review.svg';
import { pathnames } from 'routes/routes';
import Popup from 'components/popup/popup';

const AccountPage = () => {
    const history = useHistory();
    const { profile, notifications } = useSelector((state) => state.user);
    const [showPopup, setShowPopup] = useState(false);

    const onHandleNavigate = (status) => {
        history.push(pathnames.orders, { status });
    };

    const businessInfoStatus = () => {
        switch (profile.companyVerified) {
            case 0:
                return {
                    image: true,
                    title: <span className="m-txt--error">{profile.companyType == "Corporate" ? 'Update business info for verification' : 'Update personal info for verification'}</span>,
                    desc: profile.companyType == "Corporate" ? 'Start to sell the products by updating the business info' : 'Start to sell the products by updating the related info for verification',
                };
            case 2:
                return {
                    red: true,
                    title: <span>Verification <span className="m-txt m-txt--error m-txt--bold">FAILED</span></span>,
                    desc: <span>Probably you’ve provided invalid info or files. Please try to upload and submit your info again or contact our customer service by email us at <a href="mailto:sample2021@gmail.com" className="m-link m-txt m-txt--bold">sample2021@gmail.com</a>.</span>,
                };
            case 3:
                return {
                    title: 'Pending verification',
                    desc: "We are working on the verification, you will get notified when it's ready. It will not take too long. Thanks.",
                };
            default:
                break;
        }
    };

    const onHandleNavigateBusinessInfo = () => {
        history.push(pathnames.accountSettings);
    };

    const onHandleNavigateProductReviews = () => {
        history.push(pathnames.accountProductReview)
    }

    const onHandleNavigateAdApplication = () => {
        history.push(pathnames.adApplication)
    }

    return (
        <div className="account">
            <div className="m-container">
                <div className="m-wrapper">
                    <div className="account__wrapper">
                        <div className="account__header">
                            <CircleAvatar src={profile.companyImage} size={63} />
                            <p className="account__name">{profile.companyName}</p>
                        </div>
                        {profile.companyVerified !== 1 ? (
                            <BusinessInformationBoard
                                {...businessInfoStatus()}
                                onClick={onHandleNavigateBusinessInfo}
                            />
                        ) : null}
                        <div className="account__body">
                            <div className="account__card--text">
                                <p className="m-txt m-txt--bold">Orders</p>
                                    <Link to={pathnames.orders} className="m-txt m-txt--bold m-link">
                                    View All
                                </Link>
                            </div>

                            <div className="account__card-items">
                                <div className="account__card" onClick={() => onHandleNavigate("toPack")}>
                                    <div className="account__card-wrapper">
                                        <div
                                            className="account__icon"
                                            style={{ backgroundImage: `url(${toProcess})` }}
                                            alt="to_pay"
                                        />
                                        <p className="m-txt m-txt--bold m-txt--s">To pack</p>
                                        {notifications && notifications.toPayCount ? (
                                            <div className="account__badge">
                                                {notifications.toPayCount}
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="account__card" onClick={() => onHandleNavigate("packing")}>
                                    <div className="account__card-wrapper">
                                        <div
                                            className="account__icon"
                                            style={{ backgroundImage: `url(${toShipping})` }}
                                            alt="to_ship"
                                        />
                                        <p className="m-txt m-txt--bold m-txt--s">Packing</p>
                                        {notifications && notifications.toShipCount ? (
                                            <div className="account__badge">
                                                {notifications.toShipCount}
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="account__card" onClick={() => onHandleNavigate("shipped")}>
                                    <div className="account__card-wrapper">
                                        <div
                                            className="account__icon"
                                            style={{ backgroundImage: `url(${toShipped})` }}
                                            alt="to_receive"
                                        />
                                        <p className="m-txt m-txt--bold m-txt--s">Shipped</p>
                                        {notifications && notifications.toReceiveCount ? (
                                            <div className="account__badge">
                                                {notifications.toReceiveCount}
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="account__card" onClick={() => onHandleNavigate("cancelled")}>
                                    <div className="account__card-wrapper">
                                        <div
                                            className="account__icon"
                                            style={{ backgroundImage: `url(${toCancellation})` }}
                                            alt="to_review"
                                        />
                                        <p className="m-txt m-txt--bold m-txt--s">Cancellation</p>
                                        {notifications && notifications.toReviewCount ? (
                                            <div className="account__badge">
                                                {notifications.toReviewCount}
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="account__footer">
                            <div className="account__footer--item" onClick={onHandleNavigateProductReviews}>
                                <div className="account__review">
                                    <p className="m-txt m-txt--bold">Product reviews</p>
                                    {/* <span>99+</span> */}
                                </div>
                                <IoChevronForward size={22} className="m-txt m-txt--grey" />
                            </div>
                            <div className="account__footer--item" onClick={() => setShowPopup(true)}>
                                <div>
                                    <p className="m-txt m-txt--bold">Ad application</p>
                                    <p className="m-txt m-txt--s m-txt--grey">Post your unique advertisement to user’s mobile app and website.</p>
                                </div>
                                <IoChevronForward size={22} className="m-txt m-txt--grey" />
                            </div>
                        </div>
                        <Popup open={showPopup} onPopupClose={() => setShowPopup(false)} >
                            <div className="account__popup">
                                <div className="account__popup--msg">
                                    <p className="m-txt m-txt--m m-txt--bold">Place your store advertisement on customer app</p>
                                    <p className="m-txt">To request, please contact our customer service</p>
                                    <div className="account__popup--wrapper">
                                        <p className="m-txt m-txt--s">Call us </p>
                                        <p className="m-txt">+6012 3345 6688</p>
                                    </div>
                                    <div className="account__popup--wrapper">
                                        <p className="m-txt m-txt--s">Email us </p>
                                        <a href="mailto:sample2021@gmail.com" className="m-txt m-txt--gold">sample2021@gmail.com</a>
                                    </div>
                                </div>
                            </div>
                        </Popup>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AccountPage;
