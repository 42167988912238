import React, { useCallback, useRef, useState, memo } from 'react';
import { Form, Formik, ErrorMessage } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { IoChevronForward } from 'react-icons/io5';

import { validMobileNumbers } from 'helpers/utility';
import Button from 'components/button/button';
import TextInput from 'components/inputs/text-input/text-input';
import Textarea from 'components/inputs/textarea/textarea';
import CircleAvatar from 'components/circle-avatar/circle-avatar';
import { getUserProfile } from 'redux/actions/user-actions';
import config from 'helpers/config';
import api from 'services/api';
import { pathnames } from 'routes/routes';
import BreadCrumb from 'components/breadcrumb/breadcrumb';
import Dropdown from 'components/inputs/dropdown/dropdown';

const fileErrors = [
    { type: 'fileSize', error: 'File Size is too large' },
    { type: 'fileType', error: 'Unsupported File Format' },
];

const genderOptions = [
    { label: "Male", value: "Male"},
    { label: "Female", value: "Female"},
    { label: "Others", value: "Others"},
]

const validationSchemaCorporate = yup.object().shape({
    companyName: yup.string().required(),
    companyDescription: yup.string().required(),
    email: yup.string().email().required(),
    personInChargeEmail: yup.string().email("Person in charge email must be a valid email").required(),
    mobile: yup
        .string()
        .test('mobile', 'Please enter a valid mobile number', validMobileNumbers)
        .required('Mobile number is required'),
    officeNumber: yup
        .string()
        .matches(/^[0-9]*$/, "Please provide a valid Office Number")
        .required('Office number is required'),
    personInCharge: yup.string().required('Person in charge is required'),
    gender: yup.string().required('Gender is required'),
    image: yup
        .mixed()
        .test(fileErrors[0].type, fileErrors[0].error, function (value) {
            const { image } = this.parent;
            return value && image?.size <= config.file.size ? true : !value ? true : false;
        })
        .test(fileErrors[1].type, fileErrors[1].error, function (value) {
            const { image } = this.parent;
            return image && config.file.images.includes(image.type) ? true : !value ? true : false;
        }),
});

const validationSchemaIndividual = yup.object().shape({
    companyName: yup.string().required(),
    companyDescription: yup.string().required(),
    email: yup.string().email().required(),
    personInChargeEmail: yup.string().email("Person in charge email must be a valid email").required(),
    mobile: yup
        .string()
        .test('mobile', 'Please enter a valid mobile number', validMobileNumbers)
        .required('Mobile number is required'),
    officeNumber: yup
        .string()
        .matches(/^[0-9]*$/, "Please provide a valid Office Number"),
    nickName: yup.string().required('Nick name is required'),
    gender: yup.string().required('Gender is required'),
    image: yup
        .mixed()
        .test(fileErrors[0].type, fileErrors[0].error, function (value) {
            const { image } = this.parent;
            return value && image?.size <= config.file.size ? true : !value ? true : false;
        })
        .test(fileErrors[1].type, fileErrors[1].error, function (value) {
            const { image } = this.parent;
            return image && config.file.images.includes(image.type) ? true : !value ? true : false;
        }),
});

const ProfilePage = (props) => {
    const history = useHistory();
    const uploadRef = useRef();
    const dispatch = useDispatch();
    const [onSubmit, setOnSubmit] = useState(false);
    const submitBtnText = onSubmit ? 'UPDATING' : 'UPDATE';
    const {
        companyImage,
        companyName,
        companyDescription,
        companyVerified,
        companyType,
        personInCharge,
        nickName,
        email,
        mobileNumber,
        id,
        gender, 
        officeNumber,
        personInChargeEmail
    } = useSelector((state) => state.user.profile);
    const onHandleGetUserProfile = useCallback((o) => dispatch(getUserProfile(o)), [dispatch]);

    const initialValues = {
        companyName,
        companyDescription,
        email,
        mobile: mobileNumber,
        personInCharge,
        nickName,
        gender, 
        officeNumber,
        personInChargeEmail : personInChargeEmail ? personInChargeEmail : email,
    };

    const onHandleOnSubmit = async (values) => {
        setOnSubmit(true);
        try {
            const formData = new FormData();
            formData.append('id', id);
            for (var key in values) {
                formData.append(key, values[key]);
            }
            await api.post.profileUpdate(formData);
            onHandleGetUserProfile(id);
            props.onHandleShowMessage('Profile Updated');
        } catch (error) {
            props.onHandleShowMessage(error, true);
        } finally {
            setOnSubmit(false);
        }
    };

    const onHandleTriggerUpload = () => {
        if (uploadRef) {
            uploadRef.current.click();
        }
    };

    const onHandleNavigateBusinessInfo = () => {
        history.push(pathnames.accountSettings);
    };

    return (
        <div className="account-profile">
            <div className="m-container">
                <div className="m-wrapper">
                    <BreadCrumb
                        paths={[
                            { onClick: history.goBack, text: 'My account' },
                            'Profile',
                        ]}
                    />
                    <div>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={companyType == "Corporate" ? validationSchemaCorporate : validationSchemaIndividual}
                            onSubmit={onHandleOnSubmit}
                        >
                            {({ values, setFieldValue }) => (
                                <Form>
                                    <div className="account-profile__header">
                                        <Circle
                                            onClick={onHandleTriggerUpload}
                                            img={values.image}
                                            url={companyImage}
                                        />
                                        <p className="m-txt m-txt--xl">{companyName}</p>
                                        <input
                                            type="file"
                                            name="image"
                                            hidden
                                            accept={config.file.images.toString()}
                                            ref={uploadRef}
                                            onChange={(e) => {
                                                setFieldValue('image', e.target.files[0]);
                                            }}
                                        />
                                    </div>
                                    <div className="account-profile__card m-card">
                                        <span onClick={onHandleNavigateBusinessInfo}>
                                            <p className="m-txt">Business Information</p>
                                            <IoChevronForward size={12} />
                                            {companyVerified !== 1 ? <span className="redDot"></span> : null}
                                        </span>
                                        <p className="m-txt m-txt--l m-txt--bold">Online store profile</p>
                                        <div className="account-profile__input-wrapper">
                                            <p className="m-txt m-txt--s m-txt--error">
                                                <ErrorMessage name="image" />
                                            </p>
                                        </div>
                                        <div className="account-profile__input-wrapper">
                                            <TextInput
                                                outline
                                                max
                                                name="companyName"
                                                placeholder="Online Store Name"
                                                label="Online Store Name"
                                                disabled={onSubmit}
                                            />
                                            <p className="m-txt m-txt--s m-txt--error">
                                                <ErrorMessage name="companyName" />
                                            </p>
                                        </div>
                                        <div className="account-profile__input-wrapper">
                                            <Textarea
                                                outline
                                                max
                                                name="companyDescription"
                                                placeholder="Online Store Description"
                                                label="Online Store Description"
                                                disabled={onSubmit}
                                            />
                                            <p className="m-txt m-txt--s m-txt--error">
                                                <ErrorMessage name="companyDescription" />
                                            </p>
                                        </div>
                                        <div className="account-profile__input-wrapper">
                                            <TextInput
                                                outline
                                                max
                                                name="email"
                                                label="Account Email"
                                                placeholder="Email Address"
                                                disabled={true}
                                            />
                                            <p className="m-txt m-txt--s m-txt--error">
                                                <ErrorMessage name="email" />
                                            </p>
                                        </div>
                                        <div className="account-profile__input-wrapper">
                                            <TextInput
                                                outline
                                                max
                                                name={companyType == "Corporate" ? "personInCharge" : "nickName"}
                                                label={companyType == "Corporate" ? "Person In Charge" : "Nick Name"}
                                                placeholder={companyType == "Corporate" ? "Person In Charge" : "Nick Name"}
                                                disabled={onSubmit}
                                            />
                                            <p className="m-txt m-txt--s m-txt--error">
                                                <ErrorMessage name={companyType == "Corporate" ? "personInCharge" : "nickName"} />
                                            </p>
                                        </div>
                                        <div className="account-profile__input-wrapper">
                                            <TextInput
                                                outline
                                                max
                                                name="mobile"
                                                placeholder="Mobile Number"
                                                label="Person In Charge Mobile Number"
                                                disabled={onSubmit}
                                            />
                                            <p className="m-txt m-txt--s m-txt--error">
                                                <ErrorMessage name="mobile" />
                                            </p>
                                        </div>
                                        <div className="account-profile__input-wrapper">
                                            <TextInput
                                                outline
                                                max
                                                name="officeNumber"
                                                placeholder="Office Number"
                                                label={companyType == "Corporate" ? "Office Number" : "Office Number (Optional)"}
                                                disabled={onSubmit}
                                            />
                                            <p className="m-txt m-txt--s m-txt--error">
                                                <ErrorMessage name="officeNumber" />
                                            </p>
                                        </div>
                                        <div className="account-profile__input-wrapper">
                                            <TextInput
                                                outline
                                                max
                                                name="personInChargeEmail"
                                                label="Person In Charge Email"
                                                placeholder="Person in charge email"
                                                disabled={onSubmit}
                                            />
                                            <p className="m-txt m-txt--s m-txt--error">
                                                <ErrorMessage name="personInChargeEmail" />
                                            </p>
                                        </div>
                                        <div className="account-profile__input-wrapper">
                                            <Dropdown
                                                outline
                                                max
                                                value={
                                                    genderOptions.filter(
                                                        (o) => o.value === values.gender
                                                    )[0]
                                                }
                                                options={genderOptions}
                                                disabled={onSubmit}
                                                label="Person In Charge Gender"
                                                placeholder="Person in charge gender"
                                                onChange={(gender) =>
                                                    setFieldValue('gender', gender.value)
                                                }
                                            />
                                            <p className="m-txt m-txt--s m-txt--error">
                                                <ErrorMessage name="gender" />
                                            </p>
                                        </div>

                                        <div className="account-profile__button-wrapper">
                                            <Button
                                                type="submit"
                                                label={submitBtnText}
                                                disabled={onSubmit}
                                            />
                                        </div>

                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProfilePage;

const Circle = memo(({ img, url, onClick }) => {
    return <CircleAvatar src={img ? URL.createObjectURL(img) : url} onClick={onClick} size={63} />;
});
