import React, { useRef, useState, useCallback, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import SearchBar from 'components/search-bar/search-bar';
import OrderTable from 'components/tables/order-table/order-table';
import { useIsMount } from 'hooks/use-mount';
import { pathnames } from 'routes/routes';
import api from 'services/api';
import Filter from 'components/filter/filter';
import Pagination from 'components/pagination/pagination';
import EmptyState from 'components/empty-state/empty-state';
import EmptyOrdersIcon from 'assets/icons/icon-empty-orders.svg';

const initialData = {
    ordersList: [],
    totalPage: 0,
};

const filters = [
    { label: "All", value: "" },
    { label: "To pack (New)", value: "toPack" },
    { label: "Packing", value: "packing" },
    { label: "Shipped", value: "shipped" },
    { label: "Cancelled", value: "cancelled" },
    { label: "Delivered", value: "delivered" },
];

const OrdersPage = (props) => {
    const { state } = useLocation();
    const history = useHistory();
    const scrollableRef = useRef();
    const currentParams = useRef();
    const { id } = useSelector((state) => state.user.profile);
    const isMount = useIsMount();
    const [merchant, setMerchant] = useState(initialData);
    const [currentPage, setCurrentPage] = useState(0);
    const [selectedFilter, setSelectedFilter] = useState(0);
    const [search, setSearch] = useState();
    const [sort, setSort] = useState({
        key: "",
        order: ""
    });

    useEffect(() => {
        if (isMount && state) {
            setSelectedFilter(_.findIndex(filters, function(o) { return o?.value == state.status; }))
            onHandleGetOrders({ status: state.status })
        }
    }, [isMount, state]);

    useEffect(() => {
        if (selectedFilter !== 0) {
            onHandleGetOrders({ status: filters[selectedFilter].value })
        } else {
            onHandleGetOrders()
        }
        
        const interval = setInterval(() => {
            if (selectedFilter !== 0) {
                onHandleGetOrders({ status: filters[selectedFilter].value })
            } else {
                onHandleGetOrders()
            }
        }, 3000);
        return () => clearInterval(interval);
    }, [currentPage, selectedFilter, search])

    const onHandleGetOrders = useCallback(
        async (params = {}) => {
            try {
                currentParams.current = params;
                const payload = {
                    id,
                    page: currentPage,
                    sort: sort.key,
                    order: sort.order,
                    value: search,
                    length: 11,
                    ...currentParams.current,
                };
                const response = await api.get.orders(payload);
                const data = response.data.result;
                setMerchant(data);
            } catch (error) {
                props.onHandleShowMessage(error);
            }
        },
        [id, merchant, currentPage, props, search]
    );

    const onHandleOnSubmit = (o) => {
        setSearch(o)
        setCurrentPage(0)
        setSelectedFilter(0)
        
        onHandleGetOrders({ page: 0, value: o });
    };

    const handleFilter = (item) => {
        setSelectedFilter(_.findIndex(filters, item))
        setCurrentPage(0)
        setSearch("")
        setSort({
            key: "",
            order: ""
        })

        if (item.value) {
            onHandleGetOrders({ status: item.value });
        } else {
            onHandleGetOrders()
        }
    }

    const handleSort = (sortKey, order) => {
        setSort({
            key: sortKey,
            order: order
        })

        onHandleGetOrders({ sort: sortKey, order: order })
    }
    
    const handlePagination = (value) => {
        const currentIndex = Math.max(0, Math.min(currentPage + value, merchant.totalPage - 1));
		setCurrentPage(currentIndex);
	};
    
	const handleSpecificPagination = (value) => {
        setCurrentPage(value)
	};
    
    const onHandleNavigateDetails = (o) => {
        history.push(pathnames.orderDetails, { orderId: o.id, editMode: true });
    };

    return (
        <>
            {merchant.ordersList.length == 0 && !search && selectedFilter == 0 ? 
                <EmptyState 
                    emptyList={true} 
                    emptyListMsg="Sorry, no any order yet"
                    emptyListIcon={EmptyOrdersIcon}
                /> :
                <div className="orders" ref={scrollableRef}>
                    <div className="m-container">
                        <div className="m-wrapper">
                            <div className="orders__search-wrapper">
                                <SearchBar onSubmit={onHandleOnSubmit} value={search} placeholder="Search Order Id, Customer name" />
                                <Filter filters={filters} selected={selectedFilter} onClick={(item) => handleFilter(item)}/>
                            </div>
                            {merchant.ordersList.length == 0 && search ? 
                                <EmptyState emptyFilter={true}/> :
                                <>
                                    <div className="orders__wrapper m-wrapper">
                                        <OrderTable onClick={onHandleNavigateDetails} onSort={handleSort} ordersList={merchant.ordersList} />
                                    </div>
                                    <Pagination 
                                        totalPages={merchant.totalPage} 
                                        currentPage={currentPage} 
                                        onPageClick={handleSpecificPagination} 
                                        onArrowClick={handlePagination}
                                    />
                                </>
                            }
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default OrdersPage;
