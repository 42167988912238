import React, { memo } from 'react';

const CircleAvatar = ({ src, size = 80, onClick = () => {} }) => {
    const style = {
        backgroundImage: `url(${src})`,
        width: size,
        height: size,
        minWidth: size,
        minHeight: size,
    };
    return <div className="circle-avatar" style={style} onClick={onClick} />;
};

export default memo(CircleAvatar);
