import React from 'react';
import { FaExclamation } from 'react-icons/fa';
import uploadBusinessInfoIcon from 'assets/icons/icon-upload-business.svg';

const BusinessInformationBoard = ({ onClick, title, desc, red, green, image, button }) => {
    return (
        <div className={`business-info-board ${red && "business-info-board--red"} ${green && "business-info-board--green"}`} onClick={onClick}>
            <div className="business-info-board__wrapper">
                {image ? (
                    <div className="business-info-board__badge">
                        <FaExclamation size={13} />
                    </div>
                ) : null}
                <div className="business-info-board__content">
                    <p className="m-txt m-txt--bold">{title}</p>
                    <p className="m-txt">{desc}</p>
                </div>
                {image ? 
                    <div className="business-info-board__content">
                        <div className="business-info-board__icon">
                            <img src={uploadBusinessInfoIcon} alt="" />
                        </div>
                    </div> : null
                }
            </div>
            <div className="business-info-board__button">
                {button}
            </div>
        </div>
    );
};

export default BusinessInformationBoard;
