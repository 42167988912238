import React, { Fragment, useMemo, useState, useCallback, useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { IoAddSharp, IoClose, IoCheckmarkCircle, IoCloseCircle, IoTrashOutline, IoCloseOutline, IoRemove, IoAdd } from 'react-icons/io5';
import { FiEdit2 } from "react-icons/fi";
import { Form, Formik, ErrorMessage } from 'formik';
import Modal from 'react-responsive-modal';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import moment from 'moment';

import BreadCrumb from 'components/breadcrumb/breadcrumb';
import TextInput from 'components/inputs/text-input/text-input';
import Textarea from 'components/inputs/textarea/textarea';
import Dropdown from 'components/inputs/dropdown/dropdown';
import Button from 'components/button/button';
import config from 'helpers/config';
import api from 'services/api';
import uploadBusinessInfoIcon from 'assets/icons/icon-upload-business.svg';
import peakHourPopupIcon from 'assets/icons/icon-activate-product.svg';

import { useIsMount } from 'hooks/use-mount';
import { pathnames } from 'routes/routes';
import Popup from 'components/popup/popup';

const validationSchema = yup.object().shape({
    images: yup.array().min(1, 'Product image is required').required('Product image is required'),
    name: yup.string().required('Product name is required'),
    description: yup.string().required('Product description is required'),
    category: yup.string().required('Product category is required'),
    stock: yup.number().when('variations', {
        is: (value) => !value,
        then: yup
            .number()
            .positive('Product stock is required')
            .required('Product stock is required')
            .typeError('Product stock is required'),
    }),
    price: yup.number().when('variations', {
        is: (value) => !value,
        then: yup
            .number()
            .positive('Product price is required')
            .required('Product price is required')
            .typeError('Product price is required'),
    }),
    weight: yup
        .number()
        .typeError('Product stock is required')
        .required('Product weight is required'),
});

const ProductCreatePage = (props) => {
    const galleryRef = useRef();
    const formikRef = useRef();
    const isMount = useIsMount();
    const history = useHistory();
    const { state } = useLocation();
    const { companyId, companyVerified } = useSelector((state) => state.user.profile);
    const [variationsModalVisible, setVariationsModalVisible] = useState(false);
    const [onSubmit, setOnSubmit] = useState(false);
    const emptyOption = { stock: 0, price: 0 };
    const emptyVariation = { title: '', options: [] };
    const [variations, setVariations] = useState([emptyVariation]);
    const [tableVariations, setTableVariations] = useState(state?.product?.variations);
    const [categories, setCategories] = useState([]);
    const [productStatus, setProductStatue] = useState(state ? state.product.status : true);
    const [variationToggle, setVariationToggle] = useState(state?.product?.variations ? true : false);
    const [showStockPriceTable, setShowStockPriceTable] = useState(state?.product?.variations ? true : false);
    const [showInactivePopup, setShowInactivePopup] = useState(false);
    const [isPeakHourModalVisible, setIsPeakHourModalVisible] = useState(false);
    const editMode = state?.editMode;
    const ButtonText = editMode ? (onSubmit ? 'UPDATING' : 'UPDATE') : (onSubmit ? 'ADDING' : 'ADD');
    const headerClass = `product-create__header ${
        !editMode ? 'product-create__header--create' : ''
    }`;
    const toggleActiveBtnClass = `product-create__status-btn ${
        !productStatus ? 'product-create__status-btn--disabled' : ''
    }`;
    const toggleActiveVariationClass = `product-create__variation-toggle ${
        !variationToggle ? 'product-create__variation-toggle--disabled' : ''
    }`;

    useEffect(() => {
        // SET DATA FIELD FOR VARIATION MODAL
        if (isMount) {
            if (state?.product?.variations) {
                const data = state?.product?.variations;
                let prevVariations = [];

                const filterOptions = (type) => {
                    const options = [];
                    data.forEach((o) => {
                        if (!options.filter((k) => k.name === o.variations[type])[0]) {
                            options.push({
                                name: o.variations[type],
                                stock: o.stock,
                                price: o.price,
                                id: o.id,
                            });
                        }
                    });
                    return options;
                };
                if (data[0]?.titles[0]) {
                    prevVariations.push({
                        title: data[0].titles[0],
                        options: filterOptions('variationOne'),
                    });
                }

                if (data[0]?.titles[1]) {
                    prevVariations.push({
                        title: data[0].titles[1],
                        options: filterOptions('variationTwo'),
                    });
                }
                setVariations(prevVariations);
            }
        }
    }, [isMount, state]);

    const sanitizedData = () => {
        var html = state.product.description;
        var div = document.createElement("div");
        div.innerHTML = html;
        var text = div.textContent || div.innerText || "";

        return text;
    }

    const initialValues = useMemo(
        () => ({
            name: state ? state.product.name : '',
            description: state ? sanitizedData() : '',
            images: state ? [...state.product.productImages, ...state.product.productVideos] : [],
            previousImages: state
                ? [...state.product.productImages, ...state.product.productVideos]
                : [],
            price: state ? state.product.price : '',
            stock: state ? state.product.stock : '',
            weight: state ? state.product.weight : '',
            category: state ? state.product.category : '',
            mininumOrder: 1,
            status: state ? state.product.status : 1,
            companyId: companyId,
            properties: null,
            deleteFileIndex: [],
        }),
        [state, companyId]
    );

    const onHandleGetCategories = useCallback(async () => {
        try {
            const response = await api.get.categories();
            const mappedCategories = response.data.result.map((o) => ({
                label: o.name,
                value: o.id,
            }));
            setCategories(mappedCategories);
        } catch (error) {
            props.onHandleShowMessage(error);
            history.goBack();
        }
    }, [props, history]);

    useEffect(() => {
        if (isMount) {
            onHandleGetCategories();
        }
    }, [isMount, onHandleGetCategories]);

    const onHandleSubmit = useCallback(
        async (v) => {
            setOnSubmit(true);
            let apiUrl = editMode ? api.post.productUpdate : api.post.productCreate;
            let successfulMsg = editMode
                ? 'Product successful updated'
                : 'Product successful created';

            const formData = new FormData();
            formData.append('companyId', v.companyId);
            formData.append('name', v.name);
            formData.append('description', v.description);
            formData.append('price', v.price || 0);
            formData.append('category', v.category);
            formData.append('status', v.status);
            formData.append('stock', v.stock || 0);
            formData.append('weight', v.weight);
            formData.append('properties', tableVariations ? JSON.stringify(tableVariations) : null);
            formData.append('minimumOrder', '1');

            if (editMode) {
                formData.append('productId', state.product.id);
            }

            if (v.images) {
                v.images.forEach((o) => {
                    if (typeof o !== 'string') {
                        formData.append('file', o);
                    }
                });
            }

            if (v.deleteFileIndex.length) {
                const jsonArray = JSON.stringify(v.deleteFileIndex);
                const removeBraket = jsonArray.slice(1, -1);
                formData.append('deleteFileIndex', removeBraket);
            }

            try {
                await apiUrl(formData);
                props.onHandleShowMessage(successfulMsg);
                history.push(pathnames.products);
            } catch (error) {
                props.onHandleShowMessage(error);
            } finally {
                setOnSubmit(false);
            }
        },
        [tableVariations, state, editMode, history, props]
    );

    const onHandleDelete = useCallback(async () => {
        setOnSubmit(true);
        try {
            await api.patch.productDelete(state.product.id);
            props.onHandleShowMessage('Product sucessful deleted');
            history.push(pathnames.products);
        } catch (error) {
            props.onHandleShowMessage(error);
        } finally {
            setOnSubmit(false);
        }
    }, [state, history, props]);

    const onHandleTriggerUpload = () => {
        galleryRef.current.click();
    };

    const onHandleUploadGallery = (e) => {
        if (formikRef.current) {
            const files = e.target.files;
            const currentImages = formikRef.current.values.images;
            const arrayGallery = [...currentImages];
            const maxLimit = Object.keys(files).length + currentImages.length > 6;
            const maxFileSize = 25000000;
            let videoLength = 0;

            if (maxLimit) {
                props.onHandleShowMessage('Maximum 5 photos & 1 video', true);
                return;
            }

            for (const key in files) {
                const file = files[key];

                if (file.type === config.file.videos[0]) {
                    if (arrayGallery.length) {
                        const previousVideo = arrayGallery.filter(
                            (o) => o.split('.').pop() === 'mp4'
                        )[0];

                        if (previousVideo) {
                            videoLength += 1;
                        }
                    }

                    videoLength += 1;
                }

                if (videoLength >= 2) {
                    props.onHandleShowMessage('Maximum 1 video', true);
                    break;
                }

                if (file.size > maxFileSize) {
                    props.onHandleShowMessage('Maximum 25mb per image/video', true);
                    break;
                }

                if (file.type) {
                    arrayGallery.push(file);
                }
            }

            formikRef.current.setFieldValue('images', arrayGallery);
            galleryRef.current.value = '';
        }
    };

    const onHandleGalleryImages = (files) => {
        if (files) {
            return files.map((o, i) => {
                let src = '';
                const key = `${o.name}-${i}`;
                const urlKey = `${o}-${i}`;
                if (typeof o === 'string') {
                    src = o;
                } else {
                    src = URL.createObjectURL(o);
                }

                if (typeof o === 'string') {
                    const isVideo = o.split('.').pop();

                    if (isVideo === 'mp4') {
                        return (
                            <div key={urlKey} className="product-create__gallery-video">
                                <IoClose
                                    className="product-create__gallery-remove"
                                    onClick={() => onHandleRemoveAssetUrl(i)}
                                />
                                <video src={src} muted autoPlay />
                            </div>
                        );
                    } else {
                        return (
                            <div key={urlKey} className="product-create__gallery-image">
                                <IoClose
                                    className="product-create__gallery-remove"
                                    onClick={() => onHandleRemoveAssetUrl(i)}
                                />
                                <img src={src} alt=""/>
                            </div>
                        );
                    }
                } else {
                    if (o.type === config.file.videos[0]) {
                        return (
                            <div key={key} className="product-create__gallery-video">
                                <IoClose
                                    className="product-create__gallery-remove"
                                    onClick={() => onHandleRemoveAsset(i)}
                                />
                                <video src={src} muted autoPlay />
                            </div>
                        );
                    } else {
                        return (
                            <div key={key} className="product-create__gallery-image">
                                <IoClose
                                    className="product-create__gallery-remove"
                                    onClick={() => onHandleRemoveAsset(i)}
                                />
                                <img src={src} alt=""/>
                            </div>
                        );
                    }
                }
            });
        } else {
            return null;
        }
    };

    const onHandleRemoveAsset = (i) => {
        const images = formikRef.current.values.images;
        images.splice(i, 1);
        formikRef.current.setFieldValue('images', images);
    };

    const onHandleRemoveAssetUrl = (i) => {
        const values = formikRef.current.values;
        const images = values.images;
        const previousImages = values.previousImages;
        const previousDeletedIndex = values.deleteFileIndex;
        const deleteIndex = previousImages.findIndex((o) => o === images[i]);
        const newDeletedFileIndex = previousDeletedIndex.length
            ? [...previousDeletedIndex, previousImages[deleteIndex]]
            : [previousImages[deleteIndex]];

        images.splice(i, 1);
        formikRef.current.setFieldValue('images', images);
        formikRef.current.setFieldValue('deleteFileIndex', newDeletedFileIndex);
    };

    const onHandleShowVariationsModal = (v) => {
        setVariationsModalVisible(v);
    };

    const onHandleRemoveVariations = () => {
        setVariations(emptyVariation);
        setTableVariations(null);
        formikRef.current.setFieldValue('variations', 0);
    };

    const onHandleCreateVariations = (values) => {
        setTableVariations(values);
        onHandleShowVariationsModal(false);
        formikRef.current.setFieldValue('variations', 1);
    };

    const onHandleOptionStockQuantity = (value, index) => {
        
        const quantity = value;
        const currentVarations = [...tableVariations];
        currentVarations[index].stock = quantity;
        setTableVariations(currentVarations);
    };

    const onHandleOptionPriceChanges = (event, index) => {
        const price = event.currentTarget.value.trim();
        const currentVarations = [...tableVariations];
        currentVarations[index].price = price;
        setTableVariations(currentVarations);
    };

    const onHandleProductStatus = () => {
        const status = !productStatus ? 1 : 0;
        setProductStatue(!productStatus);
        formikRef.current.setFieldValue('status', status);
    };

    const onHandleDisableUpdate = (isDirty) => {
        if (isDirty) {
            return false;
        } else if (onSubmit) {
            return true;
        } else if (tableVariations === state?.product?.variations) {
            return true;
        }
    }

    const onHandleSubmitButtonAction = (handleSubmit) => {
        let currentTime = moment().format("HHmm");
		let peakHourStart = moment("06:00 AM", "hh:mm A").format("HHmm");

        if (currentTime >= peakHourStart) {
            setIsPeakHourModalVisible(true);
        } else {
            if (companyVerified == 3) {
                setShowInactivePopup(true)
            } else {
                handleSubmit()
            }
        }
    }

    const onHandlePeakHourPopupSubmit = (handleSubmit) => {
		if (companyVerified == 3) {
			setShowInactivePopup(true);
		} else {
			handleSubmit();
		}

        setIsPeakHourModalVisible(false);
	}

    return (
        <div className="product-create">
            <div className="m-container">
                <div className="m-wrapper">
                    <BreadCrumb
                        paths={[{ onClick: history.goBack, text: 'Products' }, editMode ? 'Product details' : 'Add new product']}
                    />
                </div>
            </div>
            <div className="m-container">
                <div className="m-wrapper">
                    <div className="product-create__wrapper">
                        <div className={headerClass}>
                            {editMode ? (
                                <div className="product-create__delete" onClick={onHandleDelete}>
                                    <IoTrashOutline className="m-txt--error" size={20} />
                                    <p className="m-txt m-txt--s m-txt--error">Delete</p>
                                </div> 
                            ) : null}
                            <button className={toggleActiveBtnClass} onClick={onHandleProductStatus}>
                                {productStatus ? (
                                    <Fragment>
                                        <p className="m-txt m-txt--valid">ACTIVE</p>
                                        <IoCheckmarkCircle className="m-txt--valid" size={30} />
                                    </Fragment>
                                ) : (
                                    <Fragment>
                                        <p className="m-txt m-txt--grey">INACTIVE</p>
                                        <IoCloseCircle className="m-txt--grey" size={30} />
                                    </Fragment>
                                )}
                            </button>
                        </div>
                        <Formik
                            innerRef={formikRef}
                            enableReinitialize
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={onHandleSubmit}
                        >
                            {({ values, setFieldValue, submitForm, dirty }) => (
                                <Form>
                                    <p className="m-txt m-txt--m m-txt--bold">{editMode ? "Product details" : "Add new product"}</p>
                                    <div className="product-create__gallery-wrapper">
                                        <p className="m-txt m-txt--s">
                                            Product visual | Maximum 5 photos & 1 video
                                        </p>
                                        <div className="product-create__gallery-action">
                                            <button
                                                type="button"
                                                className="product-create__gallery-add"
                                                onClick={onHandleTriggerUpload}
                                                disabled={onSubmit || values.images >= 6}
                                            >
                                                <IoAddSharp
                                                    size={40}
                                                    className="product-create__gallery-icon"
                                                />
                                            </button>
                                            {onHandleGalleryImages(values.images)}
                                            <input
                                                type="file"
                                                name="images"
                                                hidden
                                                multiple
                                                accept={[
                                                    ...config.file.images,
                                                    ...config.file.videos,
                                                ].toString()}
                                                ref={galleryRef}
                                                onChange={onHandleUploadGallery}
                                            />
                                        </div>
                                        <p className="m-txt m-txt--s m-txt--error">
                                            <ErrorMessage name="images" />
                                        </p>
                                    </div>
                                    <div className="product-create__input-wrapper">
                                        <p className="m-txt m-txt--s">Product Name</p>
                                        <TextInput
                                            outline
                                            max
                                            name="name"
                                            placeholder="Enter your product name"
                                            disabled={onSubmit}
                                        />
                                        <p className="m-txt m-txt--s m-txt--error">
                                            <ErrorMessage name="name" />
                                        </p>
                                    </div>
                                    <div className="product-create__input-wrapper">
                                        <p className="m-txt m-txt--s">Product Description</p>
                                        <Textarea
                                            outline
                                            max
                                            name="description"
                                            placeholder="Describe about your product"
                                            disabled={onSubmit}
                                        />
                                        <p className="m-txt m-txt--s m-txt--error">
                                            <ErrorMessage name="description" />
                                        </p>
                                    </div>
                                    <div className="product-create__input-wrapper product-create__input-s">
                                        <p className="m-txt m-txt--s">Product Category</p>
                                        <Dropdown
                                            outline
                                            max
                                            value={
                                                categories.filter((o) => o.value === values.category)[0]
                                            }
                                            options={categories}
                                            placeholder="Select category"
                                            onChange={(o) => setFieldValue('category', o.value)}
                                            disabled={onSubmit}
                                        />
                                        <p className="m-txt m-txt--s m-txt--error">
                                            <ErrorMessage name="category" />
                                        </p>
                                    </div>

                                    <div className="product-create__input-wrapper product-create__input-xs">
                                        <p className="m-txt m-txt--s">Weight/unit (kg)</p>
                                        <TextInput
                                            outline
                                            max
                                            name="weight"
                                            placeholder="Enter product weight"
                                            disabled={onSubmit}
                                        />
                                        <p className="m-txt m-txt--s m-txt--error">
                                            <ErrorMessage name="weight" />
                                        </p>
                                    </div>
                                    <div>
                                        <div className="product-create__input-wrapper--variations">
                                            <p className="m-txt m-txt--s">Product Variation</p>
                                            <button type="button" className={toggleActiveVariationClass} onClick={() => setVariationToggle(!variationToggle)}>
                                                <Fragment>
                                                    <span></span>
                                                </Fragment>
                                            </button>
                                        </div>
                                        {variationToggle ? 
                                            <>  
                                                <AddVariationForm 
                                                    initialVariations={state?.product?.variations}
                                                    variations={variations}
                                                    emptyOption={emptyOption}
                                                    emptyVariation={emptyVariation}
                                                    setVariations={setVariations}
                                                    onHandleSubmit={onHandleCreateVariations}
                                                    setShowStockPriceTable={setShowStockPriceTable}
                                                />
                                                {showStockPriceTable && 
                                                    <VariationStockPriceTable
                                                        data={tableVariations}
                                                        onHandleOptionStockQuantity={onHandleOptionStockQuantity}
                                                        onHandleOptionPriceChanges={onHandleOptionPriceChanges}
                                                    />
                                                }
                                            </>
                                            : <p className="m-txt m-txt--s m-txt--grey">Turn on to add product variation (E.g. Product size, colour & etc.)</p>
                                        }
                                        
                                    </div>
                                    {!showStockPriceTable && (
                                        <Fragment>
                                            <div className="product-create__input-wrapper">
                                                <p className="m-txt m-txt--s">Price/unit (RM)</p>
                                                <TextInput
                                                    outline
                                                    max
                                                    name="price"
                                                    placeholder="Price per unit"
                                                />
                                                <p className="m-txt m-txt--s m-txt--error">
                                                    <ErrorMessage name="price" />
                                                </p>
                                            </div>
                                            <div className="product-create__input-wrapper product-create__input-xs">
                                                <p className="m-txt m-txt--s">Available Stock</p>
                                                <TextInput
                                                    outline
                                                    max
                                                    typ="number"
                                                    name="stock"
                                                    placeholder="Eg: 100"
                                                    disabled={onSubmit}
                                                />
                                                <p className="m-txt m-txt--s m-txt--error">
                                                    <ErrorMessage name="stock" />
                                                </p>
                                            </div>
                                        </Fragment>
                                    )}

                                    <div className="product-create__button-wrapper">
                                        <Button 
                                            label={ButtonText} 
                                            type="button" 
                                            disabled={onHandleDisableUpdate(dirty)} 
                                            onClick={() => onHandleSubmitButtonAction(submitForm)}
                                        />
                                    </div>
                                    <Popup open={showInactivePopup} onPopupClose={() => setShowInactivePopup(false)} icon={<img src={uploadBusinessInfoIcon}/>}>
                                        <div className="product-create__inactive-popup">
                                            <div className="product-create__inactive-popup--msg">
                                                <p className="m-txt m-txt--m m-txt--bold">Inactive product mode</p>
                                                <p className="m-txt m-txt--m">Product inactive, because your account haven’t been verified. <br /> To proceed, please update or check your business verifcation status.</p>
                                            </div>
                                            <div className="product-create__inactive-popup--btn">
                                                <Button text label="LATER" type="button" onClick={() => {setShowInactivePopup(false); submitForm()}}/>
                                                <Button text gold label="CHECK NOW" type="button" onClick={() => history.push(pathnames.accountSettings)}/>
                                            </div>
                                        </div>
                                    </Popup>
                                    <Popup open={isPeakHourModalVisible} onPopupClose={() => setIsPeakHourModalVisible(false)} icon={<img src={peakHourPopupIcon}/>}>
                                        <div className="product-create__inactive-popup">
                                            <div className="product-create__inactive-popup--msg">
                                                <p className="m-txt m-txt--m m-txt--bold">Are you sure you want to update this product now? <br /> To avoid any issues, please update during off-peak hours</p>
                                            </div>
                                            <div className="product-create__inactive-popup--btn">
                                                <Button text label="DO IT LATER" type="button" onClick={() => {setIsPeakHourModalVisible(false); history.goBack()}}/>
                                                <Button text gold label="PUBLISH NOW" type="button" onClick={() => onHandlePeakHourPopupSubmit(submitForm)}/>
                                            </div>
                                        </div>
                                    </Popup>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductCreatePage;

const AddVariationForm = ({ 
    initialVariations,
    variations,
    emptyVariation,
    setVariations,
    onHandleSubmit,
    setShowStockPriceTable,
}) => {
    const [variationInputRefs, setVariationInputRefs] = useState([]);
    const [simplifiedVariations, setSimplifiedVariations] = useState(false);

    useEffect(() => {
        setVariationInputRefs(variations.map(() => React.createRef()));
    }, [variations]);

    useEffect(() => {
        if (initialVariations) {
            setSimplifiedVariations(true)
        }
    }, [])

    //Add more variation
    const onHandleAddVariation = () => {
        const currentVariations = [...variations];

        if (currentVariations[0].title && currentVariations[0].options.length > 0 && currentVariations.length < 2) {
            currentVariations.push(emptyVariation);
            setVariations(currentVariations);
        }
    };

    //Remove variation
    const onHandleRemoveVariation = (index) => {
        const currentVariations = [...variations];
        const removedVariation = currentVariations.filter((o, i) => index !== i);
        setVariations(removedVariation);
    };

    //Set variation title value
    const onHandleVariationChanges = (event, index) => {
        const value = event.currentTarget.value.trim();
        const currentVariations = [...variations];
        currentVariations[index].title = value;
        setVariations(currentVariations);
    };

    //Enter options from input
    const inputKeyDown = (e, index) => {
        const val = e.target.value;
        const currentVariations = [...variations];
        const variationdOptions = currentVariations[index].options;

        if (e.key === 'Enter' && val) {
            if (variationdOptions.find(item => item.name.toLowerCase() === val.toLowerCase())) {
                return;
            }
            variationdOptions.push({
                name: val,
                stock: 0,
                price: 0
            })
            setVariations(currentVariations);

            variationInputRefs[index].current.value = null;
        } else if (e.key === 'Backspace' && !val) {
            removeOptions(index, variationdOptions.length - 1);
        }
        
    };

    //Remove options
    const removeOptions = (variationIndex, optionsLength) => {
        const currentVariations = [...variations];
        const variationdOptions = currentVariations[variationIndex].options;

        variationdOptions.splice(optionsLength, 1);
        setVariations(currentVariations);
    };
    
    //Submit variations
    const onSubmit = () => {
        const sanitizedData = [];
        if (variations.length === 1) {
            const variationNameOne = variations[0].title;
            variations[0].options.forEach((i) => {
                const formattedData = {
                    titles: [variationNameOne],
                    variationId: i?.id,
                    stock: i?.stock || 0,
                    price: i?.price || 0,
                    variations: {
                        variationOne: i.name,
                    },
                };

                sanitizedData.push(formattedData);
            });
        } else {
            variations[0].options.forEach((i) => {
                for (let item = 0; item < variations[1].options.length; item++) {
                    const variationNameOne = variations[0].title;
                    const variationNameTwo = variations[1].title;
                    const formattedData = {
                        titles: [variationNameOne, variationNameTwo],
                        variationId: i?.id,
                        stock: i?.stock || 0,
                        price: i?.price || 0,
                        variations: {
                            variationOne: i.name,
                            variationTwo: variations[1].options[item].name,
                        },
                    };

                    sanitizedData.push(formattedData);
                }
            });
        }

        setSimplifiedVariations(true)
        setShowStockPriceTable(true)
        onHandleSubmit(sanitizedData);
    };

    const onHandleDisabledButton = () => {
        let disabled = false;

        variations.forEach((o) => {
            if (!o.title) disabled = true;
            if (o.options.length == 0) disabled = true;
        });

        return disabled;
    };

    return (
        <div className="product-create__variation-form">
            {simplifiedVariations ? 
                <div className="product-create__variation-simplified">
                    <div>
                        {variations.map((item, i) => (
                            <div key={i} className="product-create__variation-simplified--text">
                                <p className="m-txt">{item.title}: </p>
                                <p className="m-txt">
                                    {variations[i].options.map((option, j) => (
                                        `${j ? ", " : ""}${option.name}`
                                    ))}
                                </p>
                            </div>
                        ))}
                    </div>
                    <div className="product-create__variation-simplified--text" onClick={() => setSimplifiedVariations(false)}>
                        <FiEdit2 size={16}/>
                        <p className="m-txt">Edit</p>
                    </div>
                </div>
                :
                <>
                    <table>
                        <thead>
                            <tr>
                                <th className="m-txt m-txt--s">Variation name</th>
                                <th className="m-txt m-txt--s">Options</th>
                                <th/>
                            </tr>
                        </thead>
                        <tbody>
                            {variations.map((item, i) => {
                                const variationHaveTwoType = variations.length >= 2;
                                
                                return (
                                    <tr key={i}>
                                        <td>
                                            <input
                                                name="variation"
                                                placeholder="E.g Colour / Size / Flavour"
                                                value={item.title}
                                                onChange={(event) => onHandleVariationChanges(event, i)}
                                            />
                                        </td>
                                        <td>
                                            <div className="variation__options--wrapper">
                                                {variations[i].options.map((options, j) => (
                                                    <div key={options.name} className="variation__options-items">
                                                        {options.name}
                                                        <div className="variation__options-items--remove">
                                                            <IoCloseOutline size={15} color={"grey"} onClick={() => removeOptions(i, j)}/>
                                                        </div>
                                                    </div>
                                                ))}
                                                <input
                                                    ref={variationInputRefs[i]}
                                                    name="variation"
                                                    placeholder="E.g Red / XL / Strawberry"
                                                    onKeyDown={(e) => inputKeyDown(e, i)}
                                                />
                                                <p className="m-txt m-txt--xs variation__options-limit">
                                                    <span className="m-txt--valid">{variations[i].options.length} </span>
                                                    / 10
                                                </p>
                                            </div>
                                        </td>
                                        <td>
                                            {variations.length <= 1 ?
                                                <span className="variation__options--add" onClick={() => onHandleAddVariation()}>
                                                    <IoAddSharp size={20} color={"grey"}/>
                                                </span>
                                                : null
                                            }
                                            {variationHaveTwoType ? 
                                                <span className="variation__options--remove">
                                                    <IoCloseOutline size={20} color={"white"} onClick={() => onHandleRemoveVariation(i)}/>
                                                </span> : null
                                            }
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    <div className="product-create__variation-form--btn">
                        <Button small gold label="Set stock & price now" type="button" onClick={onSubmit} disabled={onHandleDisabledButton()} />
                    </div>
                </>
            }
        </div>
    )
}

const VariationStockPriceTable = ({
    data,
    onHandleOptionStockQuantity,
    onHandleOptionPriceChanges,
}) => {
    if (!data) return null;
    return (
        <table className="product-create__variations-table">
            <thead className="table-header">
                <tr>
                    {data[0].titles.map((o, i) => (
                        <th className="m-txt m-txt--white" key={i}>{o}</th>
                    ))}
                    <th className="m-txt m-txt--white">Avail Stock</th>
                    <th className="m-txt m-txt--white">Price/unit (RM)</th>
                </tr>
            </thead>

            {data.map((o, i) => {
                const stock = o.stock;
                const price = o.price.toString();
                const key = `table-body-${i}`;
                let variationsOptionsOne = o.variations.variationOne;
                let variationsOptionsTwo = null;

                if (Object.keys(o.variations).length > 1) {
                    variationsOptionsTwo = o.variations.variationTwo
                }

                return (
                    <tbody key={key}>
                        <tr>
                            <td>
                                <p className="m-txt">{variationsOptionsOne}</p>
                            </td>
                            {variationsOptionsTwo &&
                                <td>
                                    <p className="m-txt">{variationsOptionsTwo}</p>
                                </td>
                            }
                            <td>
                                <div className="product-create__variations-stock">
                                    <span className="product-create__variations-stock--minus" onClick={() => onHandleOptionStockQuantity(parseInt(stock) - 1, i)}>
                                        <IoRemove size={20} color={"white"}/>
                                    </span>
                                    <input
                                        value={stock}
                                        placeholder="0"
                                        type="number"
                                        onChange={(event) => onHandleOptionStockQuantity(event.target.value, i)}
                                    />
                                    <span className="product-create__variations-stock--add" onClick={() => onHandleOptionStockQuantity(parseInt(stock) + 1, i)}>
                                        <IoAdd size={20} color={"white"}/>
                                    </span>
                                </div>
                            </td>
                            <td>
                                <input
                                    value={price}
                                    placeholder="0"
                                    onChange={(event) => onHandleOptionPriceChanges(event, i)}
                                />
                            </td>
                        </tr>
                    </tbody>
                );
            })}
        </table>
    );
};
