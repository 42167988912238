import React, { useCallback, useState, useEffect, useRef } from 'react';
import { Form, Formik, ErrorMessage } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';

import Button from 'components/button/button';
import TextInput from 'components/inputs/text-input/text-input';
import Dropdown from 'components/inputs/dropdown/dropdown';
import { useIsMount } from 'hooks/use-mount';
import { getUserProfile } from 'redux/actions/user-actions';
import api from 'services/api';
import { pathnames } from 'routes/routes';
import { formatCurrency } from 'helpers/utility';

const validationSchema = yup.object().shape({
    courier: yup.string().required('New shipping partner is required'),
    city: yup.string().required('New shipping area is required'),
});

const AccountShippingPage = (props) => {
    const formikRef = useRef();
    const isMount = useIsMount();
    const history = useHistory();
    const dispatch = useDispatch();
    const [couriers, setCouriers] = useState([]);
    const [cities, setCities] = useState([]);
    const [shippingArea, setShippingArea] = useState([]);
    const [courierList, setCourierList] = useState([]);
    const [selectedCourier, setSelectedCourier] = useState();
    const [countryCoverage, setCountryCoverage] = useState();
    const [onSubmit, setOnSubmit] = useState(false);
    const [initialValues, setInitialValues] = useState({ courier: '', flatRate: '', city: '' });
    const submitBtnText = onSubmit ? 'UPDATING' : 'UPDATE';
    const { id, city, courier, cityName } = useSelector((state) => state.user.profile);
    const onHandleGetUserProfile = useCallback((o) => dispatch(getUserProfile(o)), [dispatch]);

    useEffect(() => {
		fetchCouriers();
	}, []);

	useEffect(() => {
		getCourierStatesAndFee()
	}, [selectedCourier])

	const fetchCouriers = useCallback(
		async () => {
			try {
				const respCouries = await api.get.couriers();
				setCourierList(respCouries.data.result)

				const couriesDropdownItems = respCouries.data.result.map(item => {
					return {
						label: item.courier,
						value: item.id
					}
				});

				const selectedCourier = respCouries.data.result.find(e => e.id === courier)

				const shippingAreaDropdownItems = selectedCourier.states.map(item => {
					return {
						label: item,
						value: item
					}
				})

				if (courier > 0) {
					setSelectedCourier(courier)
					setInitialValues({
						courier: courier.toString(),
						flatRate: selectedCourier.fee.amount.toString(),
						city: cityName,
					});
				}

				setCouriers(couriesDropdownItems);
				setShippingArea(shippingAreaDropdownItems);
			} catch (error) {
				console.log(error);
			}
		},
		[id],
	)

	const getCourierStatesAndFee = useCallback(async () => {
		try {
			if (selectedCourier) {
				const selectedCourierDetails = courierList.find(e => e.id === parseInt(selectedCourier))

                const shippingAreaDropdownItems = selectedCourierDetails.states.map(item => {
					return {
						label: item,
						value: item
					}
				})
	
				formikRef?.current?.setFieldValue("flatRate", selectedCourierDetails.fee.amount.toString())
				setShippingArea(shippingAreaDropdownItems);
                setCountryCoverage(selectedCourierDetails.country)
			}
		} catch (error) {
			console.log(error);
		}
	}, [selectedCourier]);

    // const onHandleGetCourier = useCallback(async () => {
    //     try {
    //         const citiesResponse = await api.get.cities();
    //         const couriesResponse = await api.get.couriers();
    //         const mappedCities = citiesResponse.data.result.map((o) => ({
    //             label: o.city,
    //             value: o.id,
    //         }));
    //         const mappedCouries = couriesResponse.data.result.map((o) => ({
    //             label: o.courier,
    //             flatRate: o.flatRate,
    //             value: o.id,
    //         }));
    //         setCities(mappedCities);
    //         setCouriers(mappedCouries);
    //     } catch (error) {
    //         console.log(error.response.data.message);
    //     }
    // }, []);

    // useEffect(() => {
    //     if (isMount) {
    //         onHandleGetCourier();
    //     }
    // }, [isMount, onHandleGetCourier]);

    const onHandleOnSubmit = async (values) => {
        setOnSubmit(true);
        try {
            const payload = { ...values };
            payload.id = id;
            await api.post.companyDetailsUpdate(payload);
            props.onHandleShowMessage('Shipping Updated');
            onHandleGetUserProfile(id);
            history.push(pathnames.accounts);
        } catch (error) {
            props.onHandleShowMessage(error, true);
        } finally {
            setOnSubmit(false);
        }
    };

    return (
        <div className="account-shipping">
            <div className="m-container">
                <div className="m-wrapper">
                    <div className="account-shipping__card m-card">
                        <p className="account-shipping__headline m-txt m-txt--bold m-txt--l">
                            Shipping Information
                        </p>
                        <Formik
                            enableReinitialize
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={onHandleOnSubmit}
                            innerRef={formikRef}
                        >
                            {({ values, setFieldValue }) => (
                                <Form>
                                    <div className="account-shipping__input-wrapper">
                                        <Dropdown
                                            outline
                                            max
                                            value={couriers.find((o) => o.value == values['courier'])}
                                            options={couriers}
                                            disabled={true}
                                            label="Shipping Partner"
                                            placeholder="Shipping Partnet"
                                            onChange={(couriers) => {
                                                setSelectedCourier(couriers.value)
                                                setFieldValue('courier', couriers.value)
                                            }}
                                        />
                                        <p className="m-txt m-txt--s m-txt--error">
                                            <ErrorMessage name="courier" />
                                        </p>
                                    </div>
                                    <div className="account-shipping__input-wrapper">
                                        <p className="m-txt m-txt--s m-txt--grey">Shipping Fee Type</p>
                                        <p className="m-txt m-txt--bold">Flat Rate</p>
                                    </div>
                                    <div className="account-shipping__input-wrapper">
                                        <p className="m-txt m-txt--s m-txt--grey">Shipping Fee</p>
                                        <p className="m-txt m-txt--bold">RM {formatCurrency(initialValues.flatRate)}</p>
                                    </div>
                                    <div className="account-shipping__input-wrapper">
                                        <p className="m-txt m-txt--s m-txt--grey">Coverage Country & States</p>
                                        <p className="m-txt m-txt--bold">{countryCoverage}</p>
                                        <p className="m-txt m-txt--s">
                                            {shippingArea.map((item, index) => (index !== 0 ? ", " : "") + `${item.label}`)}
                                        </p>
                                    </div>
                                    {/* <div className="account-shipping__input-wrapper">
                                        <TextInput
                                            outline
                                            max
                                            name="flatRate"
                                            placeholder="Flat rate per order (RM)"
                                            label="Flat rate per order (RM)"
                                            disabled={true}
                                        />
                                        <p className="m-txt m-txt--s m-txt--error">
                                            <ErrorMessage name="flatRate" />
                                        </p>
                                    </div>
                                    <div className="account-shipping__input-wrapper">
                                        <Dropdown
                                            outline
                                            max
                                            value={cities.filter((o) => o.value === values.city)[0]}
                                            options={cities}
                                            disabled={onSubmit}
                                            label="New shipping area"
                                            placeholder="New shipping area"
                                            onChange={(city) => setFieldValue('city', city.value)}
                                        />
                                        <p className="m-txt m-txt--s m-txt--error">
                                            <ErrorMessage name="city" />
                                        </p>
                                    </div>

                                    <div className="account-shipping__button-wrapper">
                                        <Button
                                            type="submit"
                                            label={submitBtnText}
                                            disabled={onSubmit}
                                        />
                                    </div> */}
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AccountShippingPage;
