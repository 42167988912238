import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setInfoShow } from 'redux/actions/info-actions';

const withMessages = (WrappedComponent) => ({ ...props }) => {
    const dispatch = useDispatch();
    const onHandleShowMessage = useCallback((o, e) => dispatch(setInfoShow(o, e)), [dispatch]);

    return <WrappedComponent {...props} onHandleShowMessage={onHandleShowMessage} />
};
export default withMessages;
