const Tabs = ({ items, value, onChangeTab, disabled }) => {
    return (
        <div className="tabs">
            {!!items &&
                items.map((o, i) => {
                    const className =
                        o.value === value ? `tabs__button tabs__button--active` : `tabs__button`;

                    return (
                        <button
                            className={className}
                            key={i}
                            onClick={() => onChangeTab(o.value)}
                            disabled={disabled}
                        >
                            <p className="m-txt">{o.label}</p>
                        </button>
                    );
                })}
        </div>
    );
};

export default Tabs;
