import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import BreadCrumb from 'components/breadcrumb/breadcrumb';
import SearchBar from 'components/search-bar/search-bar';
import ProductReviewTable from 'components/tables/product-review-table/product-review-table';
import api from 'services/api';
import { pathnames } from 'routes/routes';
import Pagination from 'components/pagination/pagination';

const initialData = {
    reviewList: [],
    totalPage: 0,
};

const AccountProductReviewPage = () => {
    const history = useHistory();
    const { id } = useSelector((state) => state.user.profile);
    const [reviewLists, setReviewLists] = useState(initialData);
    const [currentPage, setCurrentPage] = useState(0);
    const [search, setSearch] = useState();
    const [sort, setSort] = useState({
        key: "",
        order: ""
    });

    useEffect(() => {
        onHandleGetReviewList();
    }, [currentPage]);

    const onHandleGetReviewList = async (params) => {
        const payload = { 
            id: id,
            sort: sort.key,
            order: sort.order,
            value: search,
            page: currentPage,
            ...params 
        };
        
        try {
          const resp = await api.get.productReviewLists(payload);
          setReviewLists({
              reviewList: resp.data.result.merchantRatingItems,
              totalPage: resp.data.result.totalPage
          });
        } catch (error) {
          console.log(error);
        }
    };
    
    const onHandleOnSubmit = (o) => {
        setCurrentPage(0)
        setSearch(o)

        onHandleGetReviewList({ value: o })
    };

    const handleSort = (sortKey, order) => {
        setSort({
            key: sortKey,
            order: order
        })

        onHandleGetReviewList({ sort: sortKey, order: order })
    }

    const handlePagination = (value) => {
        const currentIndex = Math.max(0, Math.min(currentPage + value, reviewLists.totalPage - 1));
		setCurrentPage(currentIndex);
	};
    
	const handleSpecificPagination = (value) => {
        setCurrentPage(value)
	};

    const onHandleReviewDetails = (item) => {
        history.push({
            pathname: pathnames.accountProductReviewDetail,
            state: { product: item }
        });
    };

    return (
        <div className="account-product-review">
            <div className="m-container">
                <div className="m-wrapper">
                    <BreadCrumb
                        paths={[
                            { onClick: history.goBack, text: 'My account' },
                            'Product Reviews',
                        ]}
                    />
                    <p className="m-txt m-txt--xl m-txt--bold">Product Reviews</p>
                    <div className="account-product-review__search">
                        <SearchBar onSubmit={onHandleOnSubmit} value={search} placeholder="Search Product SKU, Product name"/>
                    </div>
                    <div>
                        <ProductReviewTable productReviewList={reviewLists.reviewList} onClick={onHandleReviewDetails} onSort={handleSort} />
                    </div>
                    <Pagination 
                        totalPages={reviewLists.totalPage} 
                        currentPage={currentPage} 
                        onPageClick={handleSpecificPagination} 
                        onArrowClick={handlePagination}
                    />
                </div>
            </div>
        </div>
    )
}

export default AccountProductReviewPage;