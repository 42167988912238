import React, { useCallback, useState, useEffect, useRef } from 'react';
import { IoAddCircleSharp } from 'react-icons/io5';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';

import { pathnames } from 'routes/routes';
import LiveStreamTable from 'components/tables/live-stream-table/live-stream-table';
import api from 'services/api';
import { useIsMount } from 'hooks/use-mount';
import SearchBar from 'components/search-bar/search-bar';
import Filter from 'components/filter/filter';
import Pagination from 'components/pagination/pagination';
import Button from 'components/button/button';
import EmptyState from 'components/empty-state/empty-state';
import EmptyStreamsIcon from 'assets/icons/icon-empty-streams.svg';

const initial = {
    streamAnnouncementsList: [],
    totalPage: 0,
};

const filters = [
    { label: "All", value: "all" },
    { label: "Upcoming", value: "upcoming" },
    { label: "Past", value: "past" },
];

const LiveStreamListsPage = (props) => {
    const isMount = useIsMount();
    const history = useHistory();
    const currentParams = useRef();
    const { id } = useSelector((state) => state.user.profile);
    const [streams, setStreams] = useState(initial);
    const [currentPage, setCurrentPage] = useState(0);
    const [selectedFilter, setSelectedFilter] = useState(0);
    const [search, setSearch] = useState();
    const [sort, setSort] = useState({
        key: "",
        order: ""
    });

    const onHandleGetStreamList = useCallback(
        async (params = {}) => {
            try {
                currentParams.current = params;
                const payload = { 
                    companyId: id, 
                    type: 'live',
                    status: filters[selectedFilter].value,
                    page: currentPage,
                    search: search,
                    ...currentParams.current,
                };
                const response = await api.get.streams(payload);
                const data = response.data.result;
                setStreams(data);
            } catch (error) {
                props.onHandleShowMessage(error);
            }
        }, [id, streams, currentPage, selectedFilter, search, props]
    );

    useEffect(() => {
        if (isMount) {
            onHandleGetStreamList();
        }
    }, [isMount, onHandleGetStreamList]);

    useEffect(() => {
        onHandleGetStreamList()
    }, [currentPage, selectedFilter, search]);

    const onHandleOnSubmit = (o) => {
        setSearch(o)
        setCurrentPage(0)
        setSelectedFilter(0)
        
        onHandleGetStreamList({ page: 0, search: o });
    };

    const handleFilter = (item) => {
        setSelectedFilter(_.findIndex(filters, item))
        setCurrentPage(0)
        setSearch("")
        setSort({
            key: "",
            order: ""
        })

        onHandleGetStreamList({ status: item.value });
        
    }

    const handleSort = (sortKey, order) => {
        setSort({
            key: sortKey,
            order: order
        })

        onHandleGetStreamList({ sort: sortKey, order: order })
    }
    
    const handlePagination = (value) => {
        const currentIndex = Math.max(0, Math.min(currentPage + value, streams.totalPage - 1));
		setCurrentPage(currentIndex);
	};
    
	const handleSpecificPagination = (value) => {
        setCurrentPage(value)
	};

    const onHandleCreateStream = () => {
        history.push(pathnames.liveStream, { createMode: true });
    };

    const onHandleNavigateDetails = (o) => {
        history.push(pathnames.liveStream, { stream: o, editMode: o.streamStatus == 0 ? true : false });
    }

    return (
        <>
            {streams.streamAnnouncementsList.length == 0 && !search && selectedFilter == 0 ? 
                <EmptyState 
                    emptyList={true} 
                    emptyListMsg="Sorry, no scheduled live stream"
                    emptyListIcon={EmptyStreamsIcon}
                    button={<Button small gold label="SCHEDULE NEW" onClick={onHandleCreateStream} />}
                /> :
                <div className="live-stream">
                    <div className="m-container">
                        <div className="m-wrapper">
                            <div className="live-stream__header">
                                <SearchBar onSubmit={onHandleOnSubmit} value={search} placeholder="Search Live stream Id, Title"/>
                                <Button small label="SCHEDULE NEW" onClick={onHandleCreateStream} />
                            </div>
                            <Filter filters={filters} selected={selectedFilter} onClick={(item) => handleFilter(item)}/>
                        </div>
                    </div>
                    <div className="m-container">
                        <div className="m-wrapper">
                            {streams.streamAnnouncementsList.length == 0 && search ? 
                                <EmptyState emptyFilter={true}/> :
                                <div>
                                    <LiveStreamTable onClick={onHandleNavigateDetails} onSort={handleSort} liveStreamList={streams.streamAnnouncementsList} />
                                    <Pagination 
                                        totalPages={streams.totalPage} 
                                        currentPage={currentPage} 
                                        onPageClick={handleSpecificPagination} 
                                        onArrowClick={handlePagination}
                                    />
                                </div>
                            }
                        </div>
                    </div>
                </div>
            }
        </>
    );
};
export default LiveStreamListsPage;
