import React, { Fragment } from 'react';
import moment from 'moment';

import constant from 'helpers/constants';
import { stockColor } from 'helpers/utility';
import ProductStock from 'components/product-stock/product-stock';

const ProductCard = ({ products, onClick, triggerRefresh }) => {
    return (
        <Fragment>
            {products.map((o, i) => {
                const key = `${i}-product-card`;
                const stock = stockColor(o.stock);
                return (
                    <div key={key} className="product-card" onClick={() => onClick(o)}>
                        <div className="product-card__item">
                            <div
                                className="product-card__image"
                                style={{ backgroundImage: `url(${o.productImages[0]})` }}
                            />
                            <div className="product-card__content">
                                <p className="m-txt m-txt--s">{o.name}</p>
                                <p className="m-txt m-txt--bold">RM {o.price}</p>
                                <div
                                    className={`m-txt m-txt--s m-txt--${stock.color} product-card__stock`}
                                    onClick={(e) => e.stopPropagation()}
                                >
                                    {o.stock > 50 ? "Stock:" : null} <ProductStock product={o} triggerRefresh={triggerRefresh} />
                                </div>
                            </div>
                            <div className="product-card__content product-card__content--created-date">
                                <p className="m-txt m-txt--grey m-txt--s">
                                    {moment(o.createdAt).format(constant.DATE)}
                                </p>
                            </div>
                        </div>
                    </div>
                );
            })}
        </Fragment>
    );
};

export default ProductCard;
