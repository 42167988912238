import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import ReactDropdown from 'react-dropdown';

const Dropdown = ({ label, max, placeholder, outline, options, value, onChange, disabled }) => {
    const className = ['dropdown__field'];

    if (max) {
        className.push('dropdown__field--max');
    }

    if (outline) {
        className.push('dropdown__field--outline');
    }

    if (value) {
        className.push('dropdown__field--inserted');
    }

    return (
        <div className="dropdown">
            <p className="m-txt m-txt--s dropdown__label">{label}</p>
            <ReactDropdown
                controlClassName={className.join(' ')}
                menuClassName="dropdown__menu"
                className="dropdown__container"
                arrowClassName="hidden"
                options={options}
                value={value}
                onChange={onChange}
                disabled={disabled}
                placeholder={placeholder ?? 'Select'}
                arrowClosed={<FiChevronDown />}
                arrowOpen={<FiChevronUp />}
            />
        </div>
    );
};

export default Dropdown;
