import React, { useState, useEffect, useRef, useCallback } from 'react';
import DatePicker from 'react-datepicker';
import moment from "moment";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { FiCheck, FiPhoneCall } from 'react-icons/fi';
import { IoMailOutline } from 'react-icons/io5';

import Button from 'components/button/button';
import config from 'helpers/config';
import api from 'services/api';
import Popup from 'components/popup/popup';

//General configs
const configs = {
    rate: 5,       
}

const AdApplicationPage = () => {
    const inputDesktopFileRef = useRef( null );
    const inputMobileFileRef = useRef( null );

    const { id } = useSelector((state) => state.user.profile);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [totalDays, setTotalDays] = useState(null);
    const [totalPrice, setTotalPrice] = useState(null);
    const [onSubmit, setOnSubmit] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false)
    const [bannerData, setBannerData] = useState({
        desktop: null,
        mobile: null,
        desktopName: "",
        mobileName: "",
        desktopFile: null,
        mobileFile: null,
    });
    
    useEffect(() => {
        var start = moment(startDate);
        var end = moment(endDate);
        var totalDays = moment.duration(end.diff(start)).asDays() + 1;

        setTotalDays(totalDays)
        setTotalPrice(totalDays * configs.rate)
    }, [startDate, endDate]);    

    const onChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };

    const onHandleDrop = (e, adType, uploadMethod, nameType, fileType) => {
        let maxImageSize = 20000000;

        if (uploadMethod === "browse") {
            if (e.target.files[0].size < maxImageSize) {
                let image = URL.createObjectURL(e.target.files[0]);
                let name = e.target.files[0].name;
                setBannerData({
                    ...bannerData,
                    [adType]: image,
                    [nameType]: name,
                    [fileType]: e.target.files[0],
                });
            } else {
                toast.error("File size exceeded the limit of 20MB");
            }
        } else {
            e.preventDefault();
            const {
                dataTransfer: { files },
            } = e;
            const { size, type, name } = files[0];
            const reader = new FileReader();
            const fileTypes = ["image/jpeg", "image/jpg", "image/png, image/bmp"];

            if (!fileTypes.includes(type)) {
                toast.error("File format must be either png, jpg or bmp");
                return false;
            }
            if (size > maxImageSize) {
                toast.error("File size exceeded the limit of 20MB");
                return false;
            }

            reader.readAsDataURL(files[0]);
            reader.onload = (loadEvt) => {
                setBannerData({
                    ...bannerData,
                    [adType]: loadEvt.target.result,
                    [nameType]: name,
                    [fileType]: files[0],
                });
            };
        }
    };
    
    const onHandleDragOver = (e) => {
        e.preventDefault();
    };

    const onHandleSubmit = useCallback(async () => {

        if(!startDate || !endDate ){
          toast.error("Please select start and end dates.")
          return;
        }
    
        if(!bannerData.desktopFile || !bannerData.mobileFile ){
            toast.log("Please select desktop and mobile images to be uploaded")
          return;
        }

        setOnSubmit(true);

        try {
          let formData = new FormData();
          formData.append("sellerId", id);
          formData.append(
            "name",
            `desktopName:${bannerData.desktopName}, mobileName:${bannerData.mobileName}`
          );
          formData.append("mobileImage", bannerData.mobileFile);
          formData.append("desktopImage", bannerData.desktopFile);
          formData.append("startDate", startDate);
          formData.append("endDate", endDate);

          const resp = await api.post.bannerCreate(formData);
          console.log(resp)

          setShowSuccess(true);

        } catch (error) {
          toast.error(error, "Failed to create banner");
        } finally {
          setOnSubmit(false);
        }
    }, [
        bannerData.desktopFile,
        bannerData.desktopName,
        bannerData.mobileFile,
        bannerData.mobileName,
        endDate,
        startDate,
        id,
    ]);
    

    const onHandleTriggerUpload = (type) => {
        if (type == "desktop") {
            inputDesktopFileRef.current.click();
        } else {
            inputMobileFileRef.current.click();
        }
    };
     
    return (
        <div className="adApplication">
            <div className="m-container">
                <div className="m-wrapper">
                    <div className="adApplication__header">
                        <p className="m-txt m-txt--m m-txt--bold">Shows your store advertisement to your customers</p>
                    </div>
                    <div className="adApplication__body m-card">
                        <p className="m-txt m-txt--m m-txt--bold">To request, please contact us.</p>
                        <div className="adApplication__body-item">
                            <FiPhoneCall size={22} color={"grey"} />
                            <div>
                                <p className="m-txt m-txt--s">Call us</p>
                                <p className="m-txt m-link m-txt--gold">+60 12-345 6789</p>
                            </div>
                        </div>
                        <div className="adApplication__body-item">
                            <IoMailOutline size={22} color={"grey"} />
                            <div>
                                <p className="m-txt m-txt--s">Email us</p>
                                <a href="mailto:sample2021@gmail.com" className="m-link m-txt--gold">sample2021@gmail.com</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        // MIGHT BE USED IN THE FUTURE
        // <div className="adApplication">
        //     <div className="m-container">
        //         <div className="m-wrapper">
        //             <div className="adApplication__container">
        //                 <div className="adApplication__header">
        //                     <p className="m-txt m-txt--m m-txt--bold">Upload Banner</p>
        //                     <p className="m-txt m-txt--s m-txt--grey">The uploaded banner will take 3-4 working days to verify & approve. Please make sure your uploaded banner are meet the banner rules.</p>
        //                 </div>
        //                 <div className="adApplication__form">
        //                     <p className="m-txt m-txt--s">Date to post (RM {configs.rate.toFixed(2)}/ day)</p>
        //                     <div className="adApplication__form--actions">
        //                         <div>
        //                             <DatePicker
        //                                 selected={startDate}
        //                                 onChange={onChange}
        //                                 startDate={startDate}
        //                                 endDate={endDate}
        //                                 minDate={new Date()}
        //                                 selectsRange
        //                                 placeholderText="Please select start and end date"
        //                                 dateFormat="d MMMM yyyy"
        //                             />
        //                         </div>
        //                         <div>
        //                             <p className="m-txt m-txt--s">RM{configs.rate.toFixed(2)} x {totalDays ? totalDays : 0} day(s)</p>
        //                             <p className="m-txt m-txt--l m-txt--bold">RM{totalPrice ? totalPrice.toFixed(2) : 0}</p>
        //                         </div>
        //                     </div>
        //                 </div>
        //                 <div className="adApplication__form--button">
        //                     <div>
        //                         <p className="m-txt m-txt--s m-txt--bold">Please make sure your banner</p>
        //                         <p className="m-txt m-txt--s m-txt--grey">1) File Size: Maximum 20MB</p>
        //                         <p className="m-txt m-txt--s m-txt--grey">2) Format: *.jpg, *.png, *.bmp</p>
        //                     </div>
        //                     <Button
        //                         small
        //                         label="CONFIRM & SUBMIT"
        //                         type="button"
        //                         disabled={onSubmit}
        //                         onClick={onHandleSubmit}
        //                     />
        //                 </div>
        //                 <div className="adApplication__desktop">
        //                     <div className="adApplication__desktop--title">
        //                         <p className="m-txt m-txt--s m-txt--grey">Desktop Ad</p>
        //                         <p className="m-txt m-txt--s">Required dimension: 
        //                             <span className="m-txt m-txt--bold"> 1100px (Width) x 400px (Height)</span>
        //                         </p>
        //                     </div>
        //                     <div 
        //                         className="adApplication__desktop--container" 
        //                         onDrop={(e) => onHandleDrop(e, "desktop", "drag", "desktopName", "desktopFile")}
        //                         onDragOver={(e) => onHandleDragOver(e)}
        //                     >
        //                         {bannerData.desktop ? 
        //                             <div className="adApplication__image">
        //                                 <img src={bannerData.desktop} alt="" />
        //                             </div> :
        //                             <div>
        //                                 <p className="m-txt m-txt--m m-txt--grey">Drag the banner here</p>
        //                                 <p className="m-txt m-txt--m m-txt--grey adApplication__desktop--margin">Or</p>
        //                             </div>
        //                         }
        //                         <div>
        //                             <input
        //                                 multiple
        //                                 hidden
        //                                 ref={inputDesktopFileRef}
        //                                 id="uploadDesktopImage"
        //                                 className="hidden"
        //                                 accept={[config.file.images].toString()}
        //                                 type="file"
        //                                 onChange={(e) =>
        //                                     onHandleDrop(
        //                                     e,
        //                                     "desktop",
        //                                     "browse",
        //                                     "desktopName",
        //                                     "desktopFile"
        //                                     )
        //                                 }
        //                             />
        //                             <Button
        //                                 small
        //                                 outline
        //                                 label="BROWSE"
        //                                 type="button"
        //                                 onClick={() => onHandleTriggerUpload("desktop")}
        //                             />
        //                         </div>
        //                     </div>
        //                 </div>
        //                 <div className="adApplication__mobile">
        //                     <div className="adApplication__mobile--title">
        //                         <p className="m-txt m-txt--s m-txt--grey">Mobile Ad</p>
        //                         <p className="m-txt m-txt--s">Required dimension: 
        //                             <span className="m-txt m-txt--bold"> 500px (Width) x 360px (Height)</span>
        //                         </p>
        //                     </div>
        //                     <p className="m-txt m-txt--s m-txt--error">Please keep top area of banner to have 100px empty space. &nbsp; 
        //                         <span className="m-txt m-link m-txt--error">Guide</span>
        //                     </p>
        //                     <div className="adApplication__mobile--wrapper">
        //                         <div 
        //                             className="adApplication__mobile--container"
        //                             onDrop={(e) => onHandleDrop(e, "mobile", "drag", "mobileName", "mobileFile")}
        //                             onDragOver={(e) => onHandleDragOver(e)}
        //                         >
        //                             {bannerData.mobile ? 
        //                                 <div className="adApplication__image">
        //                                     <img src={bannerData.desktop} alt="" />
        //                                 </div> : 
        //                                 <div>
        //                                     <p className="m-txt m-txt--m m-txt--grey">Drag the banner here</p>
        //                                     <p className="m-txt m-txt--m m-txt--grey adApplication__mobile--margin">Or</p>
        //                                 </div>
        //                             }
        //                             <div>
        //                                 <input
        //                                     multiple
        //                                     hidden
        //                                     ref={inputMobileFileRef}
        //                                     id="uploadDesktopImage"
        //                                     className="hidden"
        //                                     accept={[config.file.images].toString()}
        //                                     type="file"
        //                                     onChange={(e) =>
        //                                         onHandleDrop(
        //                                         e,
        //                                         "mobile",
        //                                         "browse",
        //                                         "mobileName",
        //                                         "mobileFile"
        //                                         )
        //                                     }
        //                                 />
        //                                 <Button
        //                                     small
        //                                     outline
        //                                     label="BROWSE"
        //                                     type="button"
        //                                     onClick={() => onHandleTriggerUpload("mobile")}
        //                                 />
        //                             </div>
        //                         </div>
        //                     </div>
        //                 </div>
        //             </div>
        //             <Popup open={showSuccess} onPopupClose={() => setShowSuccess(false)} icon={<FiCheck size={35} color={"#54bf86"}/>} >
        //                 <div className="adApplication__popup">
        //                     <div className="adApplication__popup--msg">
        //                         <p className="m-txt m-txt--m m-txt--bold">Thank you! You will get notified when you banner is approved.</p>
        //                         <p className="m-txt m-txt--s m-txt--grey">We will take 3-5 working days for the banner to be approved. Thanks.</p>
        //                     </div>
        //                 </div>
        //             </Popup>
        //         </div>
        //     </div>
        // </div>
    )
}

export default AdApplicationPage;