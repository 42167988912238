import React, { useState } from 'react';
import moment from 'moment';
import { IoChevronForward } from 'react-icons/io5';
import { useLocation } from 'react-router-dom';

import constanst from 'helpers/constants';
import { orderStatusFormat } from 'helpers/utility';
import CircleAvatar from 'components/circle-avatar/circle-avatar';
import HeaderSort from 'components/headerSort/headerSort';
import { formatCurrency } from 'helpers/utility';

const OrderTable = ({ ordersList = [], onClick, onSort }) => {
    const location = useLocation();

    const [activeSort, setActiveSort] = useState(null);

    return (
        <>
            <table border="0" cellSpacing="0" cellPadding="0" className="order-table">
                <thead>
                    {location.pathname == "/orders" ?
                        <tr>
                            <th />
                            <th span="2">
                                <div className="order-table__header">
                                    <p className="m-txt m-txt--white">Customer Name</p>
                                    <HeaderSort 
                                        sortFunction={onSort}
                                        sortKey={"name"}
                                        setActiveSortOrder={setActiveSort}
                                        activeSortOrder={activeSort}
                                    />
                                </div>
                            </th>
                            <th>
                                <p className="m-txt m-txt--white">Order Number</p>
                            </th>
                            <th>
                                <div className="order-table__header">
                                    <p className="m-txt m-txt--white">Order Date</p>
                                    <HeaderSort 
                                        sortFunction={onSort}
                                        sortKey={"date"}
                                        setActiveSortOrder={setActiveSort}
                                        activeSortOrder={activeSort}
                                    />
                                </div>
                            </th>
                            <th>
                                <div className="order-table__header">
                                    <p className="m-txt m-txt--white">Amount (RM)</p>
                                    <HeaderSort 
                                        sortFunction={onSort}
                                        sortKey={"totalAmount"}
                                        setActiveSortOrder={setActiveSort}
                                        activeSortOrder={activeSort}
                                    />
                                </div>
                            </th>
                            <th>
                                <p className="m-txt m-txt--white m-txt--bold">Status</p>
                            </th>
                            <th />
                        </tr> : 
                        <tr>
                            <th />
                            <th span="2">
                                <p className="m-txt m-txt--white">Customer Name</p>
                            </th>
                            <th>
                                <p className="m-txt m-txt--white">Order Number</p>
                            </th>
                            <th>
                                <p className="m-txt m-txt--white">Order Date</p>
                            </th>
                            <th>
                                <p className="m-txt m-txt--white m-txt--bold">Status</p>
                            </th>
                            <th />
                        </tr>     
                    }
                </thead>
                <tbody>
                    {ordersList.map((o, i) => {
                        const status = orderStatusFormat(o.status);

                        return (
                            <tr key={`${i}-order-item`} onClick={() => onClick(o)}>
                                <td span="2">
                                    <div className="order-table__badge">
                                        <span>{o.quantity}</span>
                                        <CircleAvatar src={o.userImage} size={40} />
                                    </div>
                                </td>
                                <td>
                                    <p className="m-txt m-txt--bold">{o.deliveryName}</p>
                                </td>
                                <td>
                                    <p className="m-txt m-txt--bold m-txt--grey">{o.orderNumber}</p>
                                </td>
                                <td>
                                    <p className="m-txt m-txt--bold m-txt--grey">
                                        {moment(o.createdAt).format(constanst.DATE)}
                                    </p>
                                </td>
                                {location.pathname == "/orders" ?
                                    <td>
                                        <p className="m-txt m-txt--bold m-txt--grey">{formatCurrency(o.totalAmount)}</p>
                                    </td> : null
                                }
                                <td>
                                    <p className={`m-txt m-txt--bold m-txt--${status.color}`}>
                                        {status.label}
                                    </p>
                                </td>
                                <td>
                                    <div className="order-table__selector">
                                        <IoChevronForward size={22} className="m-txt m-txt--grey" />
                                    </div>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            {ordersList.length == 0 && <div className="m-container--center m-wrapper"><p>No items found</p></div>}
        </>
    );
};

export default OrderTable;
