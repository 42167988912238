import React, { useRef, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';

import { setUserLogout } from 'redux/actions/user-actions';
import { pathnames } from 'routes/routes';
import api from 'services/api';
import { useIsMount } from 'hooks/use-mount';

const initialStyled = 'padding: 5px 2.5px; border-radius: 3px; background: #673ab7; color: #ffffff';
const requestSuccessStyled = 'padding: 5px 2.5px; border-radius: 3px; background: #FFBF00; color: #ffffff';
const requestErrorStyled = 'padding: 5px 2.5px; border-radius: 3px; background: #40E0D0; color: #ffffff';
const responseSuccessStyled = 'padding: 5px 2.5px; border-radius: 3px; background: #14d3a7; color: #ffffff';
const responseErrorStyled = 'padding: 5px 2.5px; border-radius: 3px; background: #CD5C5C; color: #ffffff';

const withInterceptor = (WrappedComponent) => ({ ...props }) => {
    const isMount = useIsMount();
    const dispatch = useDispatch();
    const history = useHistory();
    const logout = useCallback(() => dispatch(setUserLogout()), [dispatch]);
    const { profile, auth } = useSelector((state) => state.user);
    const requestInterceptor = useRef();
    const responseInterceptor = useRef();

    const onHandleForceLogout = useCallback(() => {
        logout();
        localStorage.clear();
        history.push(pathnames.login);
    }, [logout, history]);

    const initAxiosInterceptor = useCallback(async () => {
        requestInterceptor.current = axios.interceptors.request.use(
            (config) => {
                console.log('%c REQUESTING 🚀', requestSuccessStyled);
                console.log(config.url);
                return config;
            },
            (error) => {
                console.log('%c REQUESTING ERROR 👎', requestErrorStyled);
                return Promise.reject(error);
            },
        );

        responseInterceptor.current = axios.interceptors.response.use(
            (response) => {
                console.log('%c RESPONSE SUCCESS: ✨', responseSuccessStyled);
                console.log(response.data);
                return response;
            },
            async (error) => {
                console.log('%c RESPONSE ERROR 🔥', responseErrorStyled);
                const statusCode = error.response.status;
                console.log(statusCode);

                if (statusCode === 999) {
                    onHandleForceLogout();
                    return Promise.reject(error);
                }
                else if (statusCode === 401) {
                    try {
                        const refreshPayload = { jsonWebToken: auth.jsonWebToken, email: profile?.email };
                        const response = await api.post.refreshToken(refreshPayload);
                        const token = response.data.result.jsonWebToken;
                        error.response.config.headers['Authorization'] = 'Bearer ' + token;
                        localStorage.setItem("@storage_token", token);
                        return axios(error.response.config);
                    } catch (error) {
                        onHandleForceLogout();
                        return Promise.reject(error);
                    }
                }
                else {
                    return Promise.reject(error);
                }
            },
        );
    }, [onHandleForceLogout, profile, auth]);

    useEffect(() => {
        if (isMount) {
            console.log('%c Initial Axios Interceptor! 🎉', initialStyled);
            initAxiosInterceptor();

        }
    }, [isMount, initAxiosInterceptor]);

    useEffect(() => {
        return () => {
            console.log('%c Ejected Axios Interceptor! 👋', initialStyled);
            axios.interceptors.request.eject(requestInterceptor.current);
            axios.interceptors.response.eject(responseInterceptor.current);
        };
    }, []);

    return <WrappedComponent {...props} />;
};

export default withInterceptor;
