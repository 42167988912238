import React, { useState, useEffect, useCallback } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import toast from 'react-hot-toast';
import moment from 'moment';
import { IoCalendarOutline } from 'react-icons/io5';

import { formatDate, sanitizeError } from 'helpers/utility';
import api from 'services/api';
import CircleAvatar from 'components/circle-avatar/circle-avatar';
import OrderTable from 'components/tables/order-table/order-table';
import { pathnames } from 'routes/routes';
import constants from 'helpers/constants';
import TimePeriodSelector from 'components/time-period-selector/time-period-selector';

//General configs
const config = {
    timePeriod: [
        { label: '1 W', type: 'week' },
        { label: '1 M', type: 'month' },
        { label: '3 M', type: '3month' },
        { label: '1 Y', type: 'year' },
        { label: 'All', type: 'all' },
    ],
};

const DashboardPage = () => {
    const profile = useSelector((state) => state.user.profile);
    const history = useHistory();
    const today = new Date();
    const [startDate, setStartDate] = useState(
        moment().subtract(60, 'days').format(constants.DATE)
    );
    const [endDate, setEndDate] = useState(moment().format(constants.DATE));
    const [stateGraphData, setStateGraphData] = useState();
    const [merchantIndex, setMerchantIndex] = useState();

    //Graph configs
    const graphOptions = {
        chart: {
            zoomType: 'x',
            type: 'areaspline',
        },
        title: { text: null },
        subtitle: {
            text: '',
        },
        legend: {
            enabled: false,
        },
        series: [
            {
                data: stateGraphData,
                name: 'Total Revenue (RM)',
            },
        ],
        xAxis: {
            type: 'datetime',
        },
        yAxis: [
            {
                title: {
                    text: 'RM',
                },
            },
        ],
        plotOptions: {
            areaspline: {
                pointStart: formatDate(startDate, 'timestamp'),
                marker: {
                    symbol: 'circle',
                    radius: 3,
                },
                lineWidth: 1.5,
                fillColor: {
                    linearGradient: [0, 0, 0, 300],
                    stops: [
                        [0, '#2FA8FF'],
                        [1, '#FFFFFF'],
                    ],
                },
            },
        },
        tooltip: {
            pointFormat: '',
            formatter: function () {
                var s = new Date(this.x).toLocaleDateString('en-GB');
                return s + '<br/><b>Total Revenue (RM): ' + this.y.toFixed(2) + '</b>';
            },
        },
    };

    useEffect(() => {
        if (profile?.companyVerified !== 1) {
            toast.error(
                'Account not verified. Please upload business information on the Profile Section.'
            );
        }
    }, [profile?.companyVerified]);

    const getSalesReport = useCallback(async () => {
        try {
            const payload = {
                companyId: profile?.id,
                from: startDate,
                to: endDate,
            };
            const resp = await api.post.salesReport(payload);
            const merchantResp = await api.get.merchantIndex(profile?.id);
            generateGraph(resp?.data?.result?.revenueInterval);
            setMerchantIndex(merchantResp.data.result);
        } catch (error) {
            console.log(error);
            toast.error(sanitizeError(error));
        }
    }, [startDate, endDate, profile?.id]);

    useEffect(() => {
        if (startDate !== null && endDate !== null) {
            getSalesReport();
        }
    }, [getSalesReport, startDate, endDate, profile?.id]);

    const generateGraph = (data = []) => {
        let graphData = [];

        // temp solution for unsorted data from backend
        const modifiedArray = data.sort((a, b) => moment(a.date) - moment(b.date));
        modifiedArray.forEach((sale) => {
            let x = formatDate(sale.date, 'timestamp');
            let y = parseFloat(sale.totalSales);
            const payload = x && y ? [x, y] : [x, 0];
            graphData.push(payload);
        });
        setStateGraphData(graphData);
    };

    const datePicked = (value, id) => {
        let formattedDate = formatDate(value, constants.DATE);
        if (id === 'startDate') {
            setStartDate(formattedDate);
        }
        if (id === 'endDate') {
            setEndDate(formattedDate);
        }
    };

    const setDatesHandler = (dates) => {
        setStartDate(dates.startDate);
        setEndDate(dates.endDate);
    };

    const onHandleNavigateDetails = (o) => {
        history.push(pathnames.orderDetails, { orderId: o.id, editMode: false });
    };

    return (
        <div className="dashboard">
            <div className="m-container">
                <div className="m-wrapper">
                    <div className="dashboard__shop">
                        <div className="dashboard__profile">
                            <CircleAvatar src={profile?.companyImage} />
                            <div className="dashboard__profile--text">
                                <p className="m-txt m-txt--xl m-txt--bold">Hi {profile?.companyName}</p>
                                <p className="m-txt m-txt--m">
                                    Here is what happening with your store today.
                                </p>
                            </div>
                        </div>
                        <div className="dashboard__stats--wrapper">
                            <div className="dashboard__stats--card">
                                <div className="superscript__text m-txt m-txt--s m-txt--white">
                                    RM
                                </div>
                                <div className="m-txt m-txt--xl m-txt--bold m-txt--white">
                                    {merchantIndex ? merchantIndex.totalSales : 0}
                                </div>
                                <div className="m-txt m-txt--s m-txt--white">Total Revenue</div>
                            </div>
                            <div className="dashboard__stats--card">
                                <div className="superscript__text--invisible">RM</div>
                                <div className="m-txt m-txt--xl m-txt--bold m-txt--white">
                                    {merchantIndex ? merchantIndex.totalProducts : 0}
                                </div>
                                <div className="m-txt m-txt--s m-txt--white">Products</div>
                            </div>
                            <div className="dashboard__stats--card">
                                <div className="superscript__text--invisible">RM</div>
                                <div className="m-txt m-txt--xl m-txt--bold m-txt--white">
                                    {merchantIndex ? merchantIndex.totalStreams : 0}
                                </div>
                                <div className="m-txt m-txt--s m-txt--white">Live-Streams</div>
                            </div>
                        </div>
                    </div>
                    <div className="dashboard__revenue">
                        <div className="m-txt m-txt--m m-txt--bold">Earning Revenue</div>
                        <div className="dashboard__revenue--container">
                            <div className="dashboard__revenue--sort">
                                <div className="dashboard__revenue--period">
                                    <TimePeriodSelector
                                        item={config.timePeriod}
                                        setDates={setDatesHandler}
                                    />
                                </div>
                                <div className="dashboard__revenue--date">
                                    <input
                                        className="m-txt m-txt--s"
                                        type="date"
                                        placeholder="dd-mm-yyyy"
                                        max={formatDate(today, 'YYYY-MM-DD')}
                                        onChange={(e) => datePicked(e.target.value, 'startDate')}
                                        value={formatDate(startDate, 'YYYY-MM-DD')}
                                    />
                                    <div className="dash">-</div>
                                    <input
                                        min={(formatDate, startDate, 'YYYY-MM-DD')}
                                        max={formatDate(today, 'YYYY-MM-DD')}
                                        className="m-txt m-txt--s"
                                        placeholder="dd-mm-yyyy"
                                        type="date"
                                        onChange={(e) => datePicked(e.target.value, 'endDate')}
                                        value={formatDate(endDate, 'YYYY-MM-DD')}
                                    />
                                    <div className="calendar-icon">
                                        <IoCalendarOutline />
                                    </div>
                                </div>
                            </div>
                            <div className="dashboard__revenue--graph">
                                <HighchartsReact highcharts={Highcharts} options={graphOptions} />
                            </div>
                        </div>
                    </div>
                    <div className="dashboard__orders">
                        <div className="dashboard__orders--text">
                            <div className="m-txt m-txt--m m-txt--bold">New Orders</div>
                            <Link to={pathnames.orders} className="m-txt m-link">
                                View All
                            </Link>
                        </div>
                        {merchantIndex?.orders ? (
                            <OrderTable
                                onClick={onHandleNavigateDetails}
                                ordersList={merchantIndex.orders}
                            />
                        ) : (
                            'No New Orders Found.'
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DashboardPage;
