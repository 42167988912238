import React from 'react';
import ItubePolicy from '../../assets/documents/Itube_Policy.pdf';
import ItubePrivacy from '../../assets/documents/Itube_Privacy.pdf';
import ItubeHelp from '../../assets/documents/Itube_Help.pdf';
import { pathnames } from 'routes/routes';
import { useHistory } from 'react-router-dom';

const Footer = () => {
    const history = useHistory();

    return (
        <div className="footer">
            <div className="m-container">
                <section className="footer__section">
                    <p className="m-txt m-txt--xs">
                        © 2021 Seller. All rights reserved.
                        <div className="footer__section--links">
                            <a href="https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf" target="_blank">Terms & condition&nbsp;&nbsp;|&nbsp;&nbsp; </a>
                            <a href="https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf" target="_blank">Privacy policy&nbsp;&nbsp;|&nbsp;&nbsp;</a>
                            <a onClick={() => history.push(pathnames.help)}>Help</a>
                        </div>
                    </p>
                </section>
            </div>
        </div>
    );
};

export default Footer;
