import React, { useState } from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import { useSelector } from 'react-redux';
import * as yup from 'yup';

import { yupPasswordConfig } from 'helpers/utility';
import TextInput from 'components/inputs/text-input/text-input';
import Button from 'components/button/button';
import api from 'services/api';

const initialValues = {
    password: '',
    confirmPassword: '',
    originalPassword: '',
};

const validationSchema = yup.object().shape({
    originalPassword: yup.string().required('Original Password required'),
    password: yupPasswordConfig(),
    confirmPassword: yup
        .string()
        .oneOf([yup.ref('password'), null], 'Confirm password is not matched')
        .required('password is required.'),
});

const AccountResetPasswordPage = (props) => {
    const [onSubmit, setOnSubmit] = useState(false);
    const submitBtnText = onSubmit ? 'RESETING' : 'RESET PASSWORD';
    const { id } = useSelector((state) => state.user.profile);

    const onHandleResetPassword = async (values) => {
        setOnSubmit(true);
        try {
            const payload = { ...values, userId: id };
            const response = await api.post.resetPassword(payload);
            props.onHandleShowMessage('Password Updated');
            console.log(response);
        } catch (error) {
            props.onHandleShowMessage(error, true);
            console.log(error.response.data.message);
        } finally {
            setOnSubmit(false);
        }
    };

    return (
        <div className="account-reset-pwd">
            <div className="m-container">
                <div className="m-wrapper">
                    <div className="account-reset-pwd__card m-card">
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={onHandleResetPassword}
                        >
                            {(formik) => (
                                <Form>
                                    <p className="account-reset-pwd__headline m-txt m-txt--bold m-txt--l">
                                        Reset Password
                                    </p>
                                    <div className="account-reset-pwd__input-wrapper">
                                        <TextInput
                                            outline
                                            max
                                            label="Old Password"
                                            name="originalPassword"
                                            placeholder="Enter your old password"
                                            type="password"
                                            disabled={onSubmit}
                                        />
                                        <p className="m-txt m-txt--small m-txt--error">
                                            <ErrorMessage name={'originalPassword'} />
                                        </p>
                                    </div>
                                    <div className="account-reset-pwd__input-wrapper">
                                        <TextInput
                                            outline
                                            max
                                            name="password"
                                            type="password"
                                            label="New Password"
                                            placeholder="Enter your new password"
                                            disabled={onSubmit}
                                        />
                                        <p className="m-txt m-txt--small m-txt--error">
                                            <ErrorMessage name={'password'} />
                                        </p>
                                    </div>
                                    <div className="account-reset-pwd__input-wrapper">
                                        <TextInput
                                            outline
                                            max
                                            name="confirmPassword"
                                            label="Confirm New Password"
                                            placeholder="Enter again the new password"
                                            type="password"
                                            disabled={onSubmit}
                                        />
                                        <p className="m-txt m-txt--small m-txt--error">
                                            <ErrorMessage name={'confirmPassword'} />
                                        </p>
                                    </div>
                                    <div className="account-reset-pwd__button-wrapper">
                                        <Button
                                            type="submit"
                                            label={submitBtnText}
                                            disabled={onSubmit || !(formik.isValid && formik.dirty)}
                                            />
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AccountResetPasswordPage;
