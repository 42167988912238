import React from "react";
import { AiFillCaretLeft, AiFillCaretRight } from "react-icons/ai";

const Pagination = ({ totalPages, currentPage, onPageClick, onArrowClick }) => {
  return (
    <div className="pagination">
      <button onClick={() => onArrowClick(-1)}>
        <AiFillCaretLeft size="16" color={currentPage == 0 ? "#D2D2D2" : "#000000"}/>
      </button>
      {Array.from(Array(totalPages), (e, i) => {
        if (i === 8) {
          return (
            <span className='pagination__item no-border' key={i}>
              ...
            </span>
          );
        }
        if (i > 8) {
          return '';
        } else {
          return (
            <span 
                className={
                    i == currentPage ? 
                    "pagination__item pagination__item--active m-txt m-txt--grey" : 
                    "pagination__item m-txt m-txt--grey"
                }
                key={i}
                onClick={() => onPageClick(i)}
            >
              {i + 1}
            </span>
          );
        }
      })}
      <button onClick={() => onArrowClick(+1)}>
        <AiFillCaretRight size="16" color={currentPage == totalPages - 1  ? "#D2D2D2" : "#000000"}/>
      </button>
    </div>
  );
};

export default Pagination;
