import React, { useState, useRef } from 'react';
import DateTime from 'react-datetime-picker';
import DatePicker from "react-datepicker";
import { IoChevronForwardSharp, IoChevronBackSharp, IoCalendarOutline } from 'react-icons/io5';
import { HiChevronDoubleLeft, HiChevronDoubleRight } from 'react-icons/hi';

const DateTimePicker = ({ label, onChange, ...props }) => {
    const datePickerRef = useRef();
    const [startDate, setStartDate] = useState();

    return (
        <div className="date-time-picker">
            <p className="m-txt m-txt--s date-time-picker__label">{label}</p>
            {/* <DateTime
                {...props}
                className="date-time-calendar"
                clearIcon={null}
                // calendarIcon={null}
                disableClock
                format="y-MM-dd HH:mm"
                dayPlaceholder="dd"
                monthPlaceholder="mmm"
                yearPlaceholder="yyyy"
                hourPlaceholder="HH"
                minutePlaceholder="mm"
                minDate={new Date()}
                prevLabel={<IoChevronBackSharp size={15} />}
                prev2Label={<HiChevronDoubleLeft size={15} />}
                nextLabel={<IoChevronForwardSharp size={15} />}
                next2Label={<HiChevronDoubleRight size={15} />}
            /> */}
            <div className="date-time-picker__wrapper">
                <DatePicker
                    {...props}
                    ref={datePickerRef}
                    selected={startDate}
                    onChange={(date) => { setStartDate(date), onChange(date) }}
                    minDate={new Date()}
                    timeInputLabel="Time:"
                    dateFormat="MM/dd/yyyy HH:mm"
                    placeholderText="Select date & time"
                    showTimeInput
                />
                <div className="date-time-picker__icon">
                    <IoCalendarOutline size={22} onClick={() => datePickerRef.current.setFocus(true)} />
                </div>
            </div>
        </div>
    );
};

export default DateTimePicker;
