import React, { useRef, useEffect } from 'react';
import { Form, Formik, Field } from 'formik';
import { IoSearchOutline, IoChevronDown } from 'react-icons/io5';

const initialValues = {
    value: '',
};

const SearchBar = ({ onSubmit, value, placeholder, options = [] }) => {
    const formikRef = useRef();

    useEffect(() => {
        formikRef.current.setFieldValue('name', value)
    }, [value])

    const onHandleSortBySubmit = (o, searchValue) => {
        const newParams = { params: { ...JSON.parse(o.value), name: searchValue } };
        onSubmit(newParams);
    };

    const onHandleInputSubmit = (o) => {
        // const sortByParams = o.params ? JSON.parse(o.params.value) : {};
        // const newParams = { ...sortByParams, name: o.name };
        onSubmit(o.name);
    };

    return (
        <div className="search-bar">
            <Formik innerRef={formikRef} initialValues={initialValues} onSubmit={onHandleInputSubmit}>
                {({ values, setFieldValue }) => (
                    <Form className="search-bar__form">
                        <div className="search-bar__wrapper">
                            <div className="search-bar__input">
                                <IoSearchOutline size={25} onClick={onHandleInputSubmit} cursor={"pointer"} />
                                <Field
                                    type="text"
                                    name="name"
                                    placeholder={placeholder ? placeholder: "Search"}
                                    className="search-bar__field"
                                />
                            </div>
                            {/* <div className="search-bar__sortby">
                                <p className="m-txt m-txt--bold">
                                    {values.params?.label || 'Sort By'}
                                </p>
                                <ul className="search-bar__sortby-list">
                                    {options.map((o, i) => {
                                        const key = `${i}-option`;
                                        const sortByItemClass =
                                            values?.params === o.value
                                                ? 'search-bar__sortby-item search-bar__sortby-item--active'
                                                : 'search-bar__sortby-item';
                                        return (
                                            <li
                                                key={key}
                                                className={sortByItemClass}
                                                onClick={() => {
                                                    setFieldValue('params', o);
                                                    onHandleSortBySubmit(o, values.name);
                                                }}
                                            >
                                                {o.label}
                                            </li>
                                        );
                                    })}
                                </ul>
                                <IoChevronDown size={22} className="search-bar__sortby-icon" />
                            </div> */}
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default SearchBar;
