import React, { Fragment, useState, useEffect } from 'react';
import { IoAdd, IoRemove } from "react-icons/io5";

import Popup from 'components/popup/popup';
import CircleAvatar from 'components/circle-avatar/circle-avatar';
import Button from 'components/button/button';
import { useIsMount } from 'hooks/use-mount';
import api from 'services/api';

const ProductStock = ({ product, triggerRefresh }) => {
    const isMount = useIsMount();
    const [stockPopup, setStockPopup] = useState(false);
    const [quantity, setQuantity] = useState(0);
    const [variations, setVariations] = useState([]);

    useEffect(() => {
            if (product?.variations) {
                const data = product?.variations;
                let prevVariations = [];

                const filterOptions = (variationHaveTwoType) => {
                    const options = [];
                    data.forEach((o) => {
                        options.push({
                            name: variationHaveTwoType ? o.variations['variationOne'] + " - " + o.variations['variationTwo'] : o.variations['variationOne'],
                            stock: o.stock,
                            price: o.price,
                            id: o.id,
                        });
                    });
                    return options;
                };

                if (data[0]?.titles.length > 1) {
                    prevVariations.push({
                        title: `${data[0].titles[0]}, ${data[0].titles[1]}`,
                        options: filterOptions(true),
                    });
                } else {
                    prevVariations.push({
                        title: data[0].titles[0],
                        options: filterOptions(false),
                    });
                }

                setVariations(prevVariations);
            } else {
                setVariations([])
            }
    }, [product]);

    const handleStockDisplay = () => {
        if (product.stock === 0) {
            return (
                <div className="product_stock__action">
                    <p className='m-txt m-txt--error'>
                        Out of Stock
                    </p>
                    <div className="product_stock__action--add product_stock__action--error" onClick={() => handleStockPopup()}>+</div>
                </div>
            ) 
        }
        else if (product.stock < 10) {
            return (
                <div className="product_stock__action">
                    <p className='m-txt m-txt--warning'>
                        Low Stock ({product.stock})
                    </p>
                    <div className="product_stock__action--add product_stock__action--warning" onClick={() => handleStockPopup()}>+</div>
                </div>
            ) 
        }
        else {
            return (
                <p className='m-txt m-txt--valid'>
                    {product.stock}
                </p>
            ) 
        }
    }

    const handleStockPopup = () => {
        setStockPopup(true)

        if (variations.length < 1) {
            setQuantity(product.stock)
        }
    }

    const handleVariationQuantity = (input, index) => {
        const variationsCopy = [...variations]
        const list = [...variationsCopy[0].options]
        const focused = list[index]

        if (input == "add") {
            focused.stock += 1;
        } else if (input == "minus") {
            focused.stock -= 1;
        } else {
            focused.stock = input;
        }

        setVariations(variationsCopy)
    }

    const handleRefillStocks = async () => {
        try {
            const total = variations[0]?.options?.reduce((total,value) =>  total = total + value.stock , 0 )
    
            const payload = {
                productId: product.id,
                stock: variations.length > 0 ? total : quantity,
                variations: variations[0]?.options?.map((o) => ({ variationId: o.id, stock: o.stock ? +o.stock : 0 })),
            };

            const resp = await api.post.productRefillStock(payload);

            if (resp.data.success) {
                setStockPopup(false)
                triggerRefresh()
            }
        } catch (error) {
            console.log(error)
        }
        
    }

    return (
        <Fragment>
            {handleStockDisplay()}
            <Popup open={stockPopup} onPopupClose={() => setStockPopup(false)} >
                <div className="product_stock__popup">
                    <div className="product_stock__popup--header">
                        <CircleAvatar src={product.productImages[0]} size={56} />
                        <p className="m-txt m-txt--m m-txt--bold">{product.name}</p>
                    </div>
                    <hr />
                    <div className="product_stock__popup--body">
                        {variations.length > 0 ? 
                            variations[0]?.options?.map((o, i) => {
                                return (
                                    <div className="product_stock__popup--wrapper" key={i}>
                                        <p className="m-txt">{o.name}</p>
                                        <div className="product_stock__popup--action" key={i}>
                                            <span className="product_stock__popup--minus" onClick={() => handleVariationQuantity("minus", i)}>
                                                <IoRemove size={20}/>
                                            </span>
                                            <input type="number" onChange={(e) => handleVariationQuantity(e.target.value, i)} value={o.stock} />
                                            <span className="product_stock__popup--add" onClick={() => handleVariationQuantity("add", i)}>
                                                <IoAdd size={20}/>
                                            </span>
                                        </div>
                                    </div>

                                )
                            })
                            : 
                            <div className="product_stock__popup--action">
                                <span className="product_stock__popup--minus" onClick={() => setQuantity(quantity - 1)}>
                                    <IoRemove size={20}/>
                                </span>
                                <input type="number" onChange={(e) => setQuantity(e.target.value)} value={quantity} />
                                <span className="product_stock__popup--add" onClick={() => setQuantity(quantity + 1)}>
                                    <IoAdd size={20}/>
                                </span>
                            </div>
                        }
                    </div>
                    <div className="product_stock__popup--footer">
                        <Button text label="CANCEL" onClick={() => setStockPopup(false)} />
                        <Button text gold label="CONFIRM" onClick={() => handleRefillStocks()}/>
                    </div>
                </div>
            </Popup>
        </Fragment>
    )
}

export default ProductStock;