import React, { useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as yup from 'yup';


import Error from 'components/error/error';
import Button from 'components/button/button';
import TextInput from 'components/inputs/text-input/text-input';
import { yupPasswordConfig } from 'helpers/utility';
import overviewImage from 'assets/login/overview.svg';

import api from 'services/api';
import { pathnames } from 'routes/routes';

const initialValues = {
    password: '',
    confirmPassword: '',
};

const validationSchema = yup.object({
    password: yupPasswordConfig(),
    confirmPassword: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match'),
});

const ResetPasswordPage = (props) => {
    const history = useHistory();
    const { state } = useLocation();
    const [error, setError] = useState('');
    const [onSubmit, setOnSubmit] = useState(false);
    const submitBtnText = onSubmit ? "RESETTING" : "RESET";

    console.log(props);
    const onHandleSubmit = async (values) => {
        setOnSubmit(true);
        try {
            const payload = {
                password: values.password,
                userId: state.userId,
            };
            await api.post.resetPassword(payload);
            history.push(pathnames.login);
            props.onHandleShowMessage("Your password has been changed successfully.");

        } catch (error) {
            setError(error.response.data.message);
        } finally {
            setOnSubmit(false);
        }
    };

    return (
        <div className="reset-password m-full-h">

            <div className="container m-container m-container--center">

                <div className="reset-password__card m-card">

                    <section className="reset-password__section">
                        <img src={overviewImage} alt="" />
                    </section>

                    <div className="reset-password__divider" />

                    <section className="reset-password__section">
                        <p className="login__headline m-txt m-txt--xl m-txt">Reset Password</p>

                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={onHandleSubmit}
                        >
                            {({ errors }) => (
                                <Form>
                                    <TextInput
                                        type="password"
                                        name="password"
                                        placeholder="Password"
                                        disabled={onSubmit}
                                    />
                                    <TextInput
                                        type="password"
                                        name="confirmPassword"
                                        placeholder="Confirm Password"
                                        disabled={onSubmit}
                                    />

                                    <Error msg={error || errors.password || errors.confirmPassword} />

                                    <div className="reset-password__submit">
                                        <Button label={submitBtnText} type="submit" disabled={onSubmit} />
                                    </div>
                                </Form>
                            )}
                        </Formik>

                        <p className="reset-password__link m-txt m-txt--small">
                            Back to{' '}
                            <Link to={pathnames.login} className="m-link">
                                <b>login</b>
                            </Link>
                        </p>
                    </section>
                </div>
            </div>
        </div>
    );
};

export default ResetPasswordPage;