import { axiosService } from './axiosService';
import constanst from "../helpers/constants";
const endpointUrl = process.env.REACT_APP_API_ENDPOINT;

const request = {
    login: endpointUrl + 'user/merchant/login',
    profile: endpointUrl + 'user/merchant/profile/{userId}',
    merchantIndex: endpointUrl + "user/merchant/{userId}",
    forgetPassword: endpointUrl + 'user/forgotPassword',
    resendActivationCode: endpointUrl + 'user/resendActivationCode/{userId}/{platform}',
    signup: endpointUrl + 'user/register',
    activeteUser: endpointUrl + 'user/activate',
    resetPassword: endpointUrl + 'user/resetPassword',
    validatePasswordToken: endpointUrl + 'user/validatePasswordToken',
    categories: endpointUrl + "helper/category",
    activeCategories: endpointUrl + "helper/activeCategory",
    countries: endpointUrl + 'helper/country',
    couriers: endpointUrl + "courier/",
    cities: endpointUrl + "helper/cities",
    refreshToken: endpointUrl + 'user/refreshJwt',
    profileUpdate: endpointUrl + 'user/merchant/profile/update',
    companyDetailsUpdate: endpointUrl + "company/update",
    companyDetailsUpload: endpointUrl + "company/uploadCompanyDetails",
    bannerCreate: endpointUrl + "user/merchant/banner/create",
    products: endpointUrl + 'product/merchant/{companyId}',
    productUpdate: endpointUrl + "product/merchant/update",
    productCreate: endpointUrl + "product/merchant/create",
    productDelete: endpointUrl + "product/merchant/delete/{productId}",
    productRefillStock: endpointUrl + 'product/merchant/refillStock',
    productActivateAll: endpointUrl + 'product/merchant/activateAll/{companyId}',
    productReviewLists: endpointUrl + "helper/rating/merchant/{companyId}",
    productReviewsSeen: endpointUrl + "helper/rating/seen/{productId}",
    productReviewsReply: endpointUrl + "helper/rating/reply",
    productReviews: endpointUrl + "helper/rating/{productId}",  
    orders: endpointUrl + 'orders/merchant/{companyId}',
    orderUpdate: endpointUrl + 'orders/merchant/update',
    orderCancel: endpointUrl + 'orders/merchant/cancel',
    orderDetials: endpointUrl + 'orders/merchant/details/{orderId}',
    streams: endpointUrl + "stream/merchant/announcement/{companyId}/type/{type}/status/{status}/page/{page}",
    streamCreate: endpointUrl + "stream/merchant/announcement/create",
    streamDelete: endpointUrl + "stream/merchant/announcement/delete/{streamKey}",
    streamUpdate: endpointUrl + "stream/merchant/announcement/update",
    salesReport: endpointUrl + "salesReport/stats",
    notification: endpointUrl + "notification/merchant/{merchantId}",
	readNotification: endpointUrl + "notification/merchant/read",
};

const api = {
    get: {
        resendActivationCode: ({ userId }) => {
            const endpoint = request.resendActivationCode
                .replace('{userId}', userId)
                .replace('{platform}', constanst.PLATFORM);
            return axiosService.get(endpoint);
        },
        profile: (payload) => {
            const endpoint = request.profile.replace('{userId}', payload);
            return axiosService.get(endpoint);
        },  
        merchantIndex: (payload) => {
            const endpoint = request.merchantIndex.replace('{userId}', payload);
            return axiosService.get(endpoint);
        },  
        products: ({ companyId, ...params }) => {
            const endpoint = request.products.replace('{companyId}', companyId);
            return axiosService.get(endpoint, params);
        },
        productReviewLists: ({ id, ...params }) => {
            const endpoint = request.productReviewLists.replace("{companyId}", id);
            return axiosService.get(endpoint, params);
        },
        productReviews: ({ productId, ...params }) => {
            const endpoint = request.productReviews.replace("{productId}", productId);
            return axiosService.get(endpoint, params);
        },
        productReviewsSeen: (payload) => {
            const endpoint = request.productReviewsSeen.replace("{productId}", payload);
            return axiosService.get(endpoint);
        },      
        orders: ({ id, ...params }) => {
            const endpoint = request.orders.replace('{companyId}', id);
            return axiosService.get(endpoint, params);
        },
        orderDetials: (id) => {
            const endpoint = request.orderDetials.replace('{orderId}', id);
            return axiosService.get(endpoint);
        },
        countries: () => axiosService.get(request.countries),
        categories: () => axiosService.get(request.categories),
        activeCategories: () => axiosService.get(request.activeCategories),
        cities: () => axiosService.get(request.cities),
        couriers: () => axiosService.get(request.couriers),
        streams: ({ companyId, type, status, page, ...params }) => {
            const endpoint = request.streams.replace(
                "{companyId}",
                companyId
            )
            .replace("{type}", type)
            .replace("{status}", status)
            .replace("{page}", page);
            return axiosService.get(endpoint, params);
        },
        notifications: (merchantId, payload) => {
            const endpoint = request.notification.replace('{merchantId}', merchantId)
			return axiosService.get(endpoint, payload);
		},
    },
    post: {
        login: (payload) => axiosService.post(request.login, payload),
        forgetPassword: (payload) => {
            payload.platform = constanst.PLATFORM;
            return axiosService.post(request.forgetPassword, payload);
        },
        validatePasswordToken: (payload) => {
            payload.platform = constanst.PLATFORM;
            return axiosService.post(request.validatePasswordToken, payload);
        },
        resetPassword: (payload) => {
            payload.platform = constanst.PLATFORM;
            return axiosService.post(request.resetPassword, payload);
        },
        activateUser: (payload) => {
            payload.platform = constanst.PLATFORM;
            axiosService.post(request.activeteUser, payload)
        },
        signup: (payload) => {
            payload.role = constanst.ROLE;
            return axiosService.post(request.signup, payload);
        },
        readNotification: (payload) => {
			return axiosService.post(request.readNotification, payload);
		},
        bannerCreate: (payload) => {
            return axiosService.post(request.bannerCreate, payload);
        },    
        productReviewsReply: ({ id, reply }) => {
            const payload = { id, reply };
            return axiosService.post(request.productReviewsReply, payload);
        }, 
        productRefillStock: (payload) => {
			return axiosService.post(request.productRefillStock, payload);
		}, 
        productCreate: (payload) => axiosService.post(request.productCreate, payload),
        productUpdate: (payload) => axiosService.post(request.productUpdate, payload),
        refreshToken: (payload) => axiosService.post(request.refreshToken, payload),
        profileUpdate: (payload) => axiosService.post(request.profileUpdate, payload),
        companyDetailsUpdate: (payload) => axiosService.post(request.companyDetailsUpdate, payload),
        companyDetailsUpload: (payload) => axiosService.post(request.companyDetailsUpload, payload),
        orderUpdate: (payload) => axiosService.post(request.orderUpdate, payload),
        orderCancel: (payload) => axiosService.post(request.orderCancel, payload),
        streamCreate: (payload) => axiosService.post(request.streamCreate, payload),
        streamUpdate: (payload) => axiosService.post(request.streamUpdate, payload),
        salesReport: (payload) => axiosService.post(request.salesReport, payload),
    },
    patch: {
        productDelete: (payload) => {
            const endpoint = request.productDelete.replace("{productId}", payload);
            return axiosService.patch(endpoint, payload);
        },
        productActivateAll: (payload) => {
            const endpoint = request.productActivateAll.replace("{companyId}", payload);
            return axiosService.patch(endpoint, payload);
        },
        streamDelete: (payload) => {
            const endpoint = request.streamDelete.replace("{streamKey}", payload);
            return axiosService.patch(endpoint, payload);
        }
    },
    delete: {},
    put: {},
};

export default api;
