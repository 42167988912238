import React from 'react';
import moment from 'moment';
import { useLocation, useHistory } from 'react-router-dom';

import BreadCrumb from 'components/breadcrumb/breadcrumb';
import { orderStatusFormat, formatVariations } from 'helpers/utility';
import constants from 'helpers/constants';

const OrderProductDetailsPage = () => {
    const history = useHistory();
    const { state } = useLocation();
    const product = state.product;
    const status = orderStatusFormat(product?.product?.status);

    return (
        <div className="order-product-details">
            <div className="m-container">
                <BreadCrumb
                    paths={[{ onClick: history.goBack, text: 'Orders' }, 'Order Details']}
                />
            </div>
            <div className="m-container">
                <div className="m-wrapper">
                    <div
                        className="order-product-details__image"
                        style={{
                            backgroundImage: `url(${product?.product?.productImages[0]})`,
                        }}
                    />

                    <div className="order-product-details__product order-product-details__product--border">
                        <p className="m-txt m-txt--l m-txt--bold">
                            {product?.product?.name} {formatVariations(product?.product)}
                        </p>
                        <p className="m-txt m-txt--grey m-txt--bold">
                            Order Quantity: {product?.quantity}
                        </p>
                    </div>

                    <div className="order-product-details__product">
                        <p className="m-txt m-txt--bold order-product-details__label">
                            Status Update:
                        </p>
                        <p className={`m-txt m-txt--bold m-txt--${status.color}`}>
                            {status.label}
                            {'     '}
                            <span className="m-txt m-txt--grey m-txt--bold">
                                {moment(product?.product?.createdAt).format(constants.DATE)}
                            </span>
                        </p>
                    </div>

                    <div className="order-product-details__item">
                        <div className="order-product-details__item-wrapper">
                            <p className="m-txt m-txt--grey">Price/unit (RM)</p>
                            <p className="m-txt">{product?.product?.price}</p>
                        </div>
                    </div>
                    <br />
                    <div className="order-product-details__item">
                        <div className="order-product-details__item-wrapper">
                            <p className="m-txt m-txt--grey">Product Description</p>
                            <div
                                className="m-txt"
                                dangerouslySetInnerHTML={{ __html: product?.product?.description }}
                            />
                        </div>
                    </div>
                    <div className="order-product-details__item">
                        <div className="order-product-details__item-wrapper">
                            <p className="m-txt m-txt--grey">Product Category</p>
                            <p className="m-txt">{product?.product?.categoryName}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OrderProductDetailsPage;
