import React, { useState, useCallback, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as yup from 'yup';

import Error from 'components/error/error';
import Button from 'components/button/button';
import Dropdown from 'components/inputs/dropdown/dropdown';
import TextInput from 'components/inputs/text-input/text-input';
import overviewImage from 'assets/login/overview.svg';
import ItubePolicy from 'assets/documents/Itube_Policy.pdf';
import ItubePrivacy from 'assets/documents/Itube_Privacy.pdf';

import api from 'services/api';
import { pathnames } from 'routes/routes';
import { useIsMount } from 'hooks/use-mount';

const initialValues = {
    shopName: '',
    email: '',
    password: '',
    country: '',
    displayName: '',
    companyType: 1,
};

const validationSchema = yup.object().shape({
    shopName: yup.string().required('Shop name is required.'),
    email: yup.string().email().required('Email is required.'),
    country: yup.string().when('companyType', {
        is: (val) => val === 1,
        then: yup.string().required('Country is required.'),
    }),
    displayName: yup.string().when('companyType', {
        is: (val) => val === 2,
        then: yup.string().required('Full name is required.'),
    }),
    password: yup.string().required(),
});

const tabClassName = (o, currentType) =>
    o === currentType ? 'signup__type signup__type--active' : 'signup__type';

const RegisterPage = () => {
    const isMount = useIsMount();
    const history = useHistory();
    const [countries, setCountries] = useState([]);
    const [error, setError] = useState('');
    const [onSubmit, setOnSubmit] = useState(false);
    const submitBtnText = onSubmit ? 'SIGNING' : 'SIGN UP';

    const onHandleGetCountries = useCallback(async () => {
        try {
            const response = await api.get.countries();
            const mappedCountries = response.data.result.map((o) => ({
                label: o.country,
                value: o.id,
            }));
            const mysiaIndex = mappedCountries.findIndex(
                (o) => o.label.toLowerCase() === 'malaysia'
            );
            mappedCountries.unshift(mappedCountries[mysiaIndex]);
            mappedCountries.splice(mysiaIndex);
            setCountries(mappedCountries);
        } catch (error) {
            console.log(error.response.data.message);
        }
    }, []);

    useEffect(() => {
        if (isMount) {
            onHandleGetCountries();
        }
    }, [isMount, onHandleGetCountries]);

    const onHandleSubmit = async (values) => {
        setOnSubmit(true);
        try {
            const payload = { ...values };

            switch (values.companyType) {
                case 1:
                    payload.displayName = '';
                    break;
                default:
                    payload.country = '';
                    break;
            }

            const response = await api.post.signup(values);
            history.push(pathnames.verifyAccount, { userId: response.data.result.user.id });
            console.log(response);
        } catch (error) {
            setError(error.response.data.message);
        } finally {
            setOnSubmit(false);
        }
    };

    return (
        <div className="signup m-full-h">
            <div className="container m-container m-container--center">
                <div className="signup__card m-card">
                    <section className="signup__section">
                        <img src={overviewImage} alt="" />
                    </section>

                    <div className="signup__divider" />

                    <section className="signup__section">
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={onHandleSubmit}
                        >
                            {({ values, setFieldValue, errors }) => {
                                const isCorporate = values.companyType === 1;
                                return (
                                    <Form>
                                        <div className="signup__header">
                                            <p className="m-txt m-txt--xl">Sign up</p>
                                            <ul className="signup__types">
                                                <li
                                                    className={tabClassName(1, values.companyType)}
                                                    onClick={() => setFieldValue('companyType', 1)}
                                                >
                                                    Corporate
                                                </li>
                                                <li
                                                    className={tabClassName(2, values.companyType)}
                                                    onClick={() => setFieldValue('companyType', 2)}
                                                >
                                                    Individual
                                                </li>
                                            </ul>
                                            <p className="m-txt">
                                                Please make sure you have active business license
                                            </p>
                                        </div>

                                        <TextInput
                                            type="text"
                                            name="shopName"
                                            placeholder="Shop name"
                                            disabled={onSubmit}
                                        />
                                        <TextInput
                                            type="text"
                                            name="email"
                                            placeholder="Email address"
                                            disabled={onSubmit}
                                        />
                                        {isCorporate ? (
                                            <Dropdown
                                                value={
                                                    countries.filter(
                                                        (o) => o.value === values.country
                                                    )[0]
                                                }
                                                options={countries}
                                                disabled={onSubmit}
                                                placeholder="Country"
                                                onChange={(country) =>
                                                    setFieldValue('country', country.value)
                                                }
                                            />
                                        ) : (
                                            <TextInput
                                                type="text"
                                                name="displayName"
                                                placeholder="Full Name"
                                                disabled={onSubmit}
                                            />
                                        )}
                                        <TextInput
                                            type="password"
                                            name="password"
                                            placeholder="Create Password"
                                            disabled={onSubmit}
                                        />

                                        <Error msg={error} />

                                        <p className="signup__terms m-txt">
                                            By signing up you accept the{' '}
                                            <a
                                                className="m-link"
                                                href="https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf"
                                                target="_blank"
                                            >
                                                Terms & condition
                                            </a>{' '}
                                            &{' '}
                                            <a
                                                className="m-link"
                                                href="https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf"
                                                target="_blank"
                                            >
                                                Privacy Policy
                                            </a>
                                        </p>

                                        <Button
                                            label={submitBtnText}
                                            type="submit"
                                            disabled={onSubmit}
                                        />
                                    </Form>
                                );
                            }}
                        </Formik>

                        <p className="signup__link m-txt">
                            Already have an account?{' '}
                            <Link to={pathnames.login} className="m-link">
                                <b>Login</b>
                            </Link>
                        </p>
                    </section>
                </div>
            </div>
        </div>
    );
};

export default RegisterPage;
