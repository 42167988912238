import React, { memo } from 'react';
import { Field } from 'formik';

const Textarea = ({ outline, max, ...props }) => {
    const className = ['textarea__field'];

    if (outline) {
        className.push('textarea__field--outline');
    }
    if (max) {
        className.push('textarea__field--max');
    }

    return (
        <div className="textarea">
            <label className="m-txt m-txt--s textarea__label" htmlFor={props.name}>
                {props.label}
            </label>
            <div className="textarea__container">
                <Field {...props} as="textarea" className={className.join(' ')} />
            </div>
        </div>
    );
};

export default memo(Textarea);
