import React, { useState } from 'react';
import moment from 'moment';
import { IoChevronForward } from 'react-icons/io5';
import { useLocation } from 'react-router-dom';

import constanst from 'helpers/constants';
import { orderStatusFormat } from 'helpers/utility';
import CircleAvatar from 'components/circle-avatar/circle-avatar';
import HeaderSort from 'components/headerSort/headerSort';
import { formatCurrency } from 'helpers/utility';

const LiveStreamTable = ({ liveStreamList = [], onClick, onSort }) => {
    const location = useLocation();

    const [activeSort, setActiveSort] = useState(null);

    return (
        <>
            <table border="0" cellSpacing="0" cellPadding="0" className="live-stream-table">
                <thead>
                    <tr>
                        <th span="2">
                            <p className="m-txt m-txt--white">Live stream ID</p>
                        </th>
                        <th>
                            <div className="live-stream-table__header">
                                <p className="m-txt m-txt--white">Title</p>
                                <HeaderSort 
                                    sortFunction={onSort}
                                    sortKey={"title"}
                                    setActiveSortOrder={setActiveSort}
                                    activeSortOrder={activeSort}
                                />
                            </div>
                        </th>
                        <th>
                            <div className="live-stream-table__header">
                                <p className="m-txt m-txt--white">Scheduled date</p>
                                <HeaderSort 
                                    sortFunction={onSort}
                                    sortKey={"scheduledate"}
                                    setActiveSortOrder={setActiveSort}
                                    activeSortOrder={activeSort}
                                />
                            </div>
                        </th>
                        <th>
                            <p className="m-txt m-txt--white m-txt--bold">Time</p>
                        </th>
                        <th>
                            <div className="live-stream-table__header">
                                <p className="m-txt m-txt--white">Status</p>
                                <HeaderSort 
                                    sortFunction={onSort}
                                    sortKey={"status"}
                                    setActiveSortOrder={setActiveSort}
                                    activeSortOrder={activeSort}
                                />
                            </div>
                        </th>
                        <th />
                    </tr>
                </thead>
                <tbody>
                    {liveStreamList.map((o, i) => {
                        let status = {};

                        switch (o.streamStatus) {
                            case 0:
                                status = { label: "Upcoming", color: "valid" }
                                break;
                            case 1:
                                status = { label: "Ongoing", color: "warning" }
                                break;
                            case 2:
                                status = { label: "Past", color: "grey" }
                                break;
                            default:
                                break;
                        }

                        return (
                            <tr key={`${i}-order-item`}>
                                <td span="2">
                                    <p className="m-txt m-txt--bold">{o.id}</p>
                                </td>
                                <td>
                                    <p className="m-txt m-txt--bold">{o.title}</p>
                                </td>
                                <td>
                                    <p className="m-txt m-txt--bold m-txt--grey">{moment(o.streamDate).format(constanst.DATE)}</p>
                                </td>
                                <td>
                                    <p className="m-txt m-txt--bold m-txt--grey">
                                        {o.streamTime}
                                    </p>
                                </td>
                                <td>
                                    <p className={`m-txt m-txt--bold m-txt--${status.color}`}>
                                        {status.label}
                                    </p>
                                </td>
                                <td>
                                    <div className="live-stream-table__selector" onClick={() => onClick(o)}>
                                        <IoChevronForward size={22} className="m-txt m-txt--grey" />
                                    </div>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            {liveStreamList.length == 0 && <div className="m-container--center m-wrapper"><p>No items found</p></div>}
        </>
    );
};

export default LiveStreamTable;