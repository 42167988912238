import React, { useState } from 'react';
import { IoEllipseOutline, IoCheckmarkSharp, IoChevronForward } from 'react-icons/io5';

import { formatCurrency } from 'helpers/utility';
import { stockColor } from 'helpers/utility';
import CircleAvatar from 'components/circle-avatar/circle-avatar';
import HeaderSort from 'components/headerSort/headerSort';
import ProductStock from 'components/product-stock/product-stock';

const ProductTable = ({ selectedProduct = [], productList = [], onCheck, onNavigate, onSort, triggerRefresh, allDisabled }) => {
    const displayOnly = onCheck ? false : true;

    const [activeSort, setActiveSort] = useState(null);

    return (
        <>
            <table border="0" cellSpacing="0" cellPadding="0" className="product-table">
                <thead>
                    <tr>
                        <th />
                        <th span="2">
                            <div className="product-table__header">
                                <p className="m-txt m-txt--white m-txt--bold">Product Name</p>
                                <HeaderSort 
                                    sortFunction={onSort}
                                    sortKey={"name"}
                                    setActiveSortOrder={setActiveSort}
                                    activeSortOrder={activeSort}
                                />
                            </div>
                        </th>
                        <th span="2">
                            <p className="m-txt m-txt--white m-txt--bold">Product SKU</p>
                        </th>
                        <th>
                            <div className="product-table__header">
                                <p className="m-txt m-txt--white m-txt--bold">Category</p>
                                <HeaderSort 
                                    sortFunction={onSort}
                                    sortKey={"categoryName"}
                                    setActiveSortOrder={setActiveSort}
                                    activeSortOrder={activeSort}
                                />
                            </div>
                        </th>
                        <th span="2">
                            <div className="product-table__header">
                                <p className="m-txt m-txt--white m-txt--bold">Price (RM)</p>
                                <HeaderSort 
                                    sortFunction={onSort}
                                    sortKey={"price"}
                                    setActiveSortOrder={setActiveSort}
                                    activeSortOrder={activeSort}
                                />
                            </div>
                        </th>
                        <th>
                            <div className="product-table__header">
                                <p className="m-txt m-txt--white m-txt--bold">Stock</p>
                                <HeaderSort 
                                    sortFunction={onSort}
                                    sortKey={"stock"}
                                    setActiveSortOrder={setActiveSort}
                                    activeSortOrder={activeSort}
                                />
                            </div>
                        </th>
                        {displayOnly && !onNavigate ? null : <th />}
                    </tr>
                </thead>
                <tbody className={allDisabled ? "all-disable" : ""}>
                    {productList.map((o, i) => {
                        const selected = selectedProduct.findIndex((k) => k.id === o.id) > -1;
                        const stock = stockColor(o.stock);
                        const key = `${i}-product-item`;
                        const onClick = onCheck
                            ? () => onCheck(o)
                            : onNavigate
                            ? () => onNavigate(o)
                            : () => {};
                        
                        return (
                            <tr key={key} className={o.status == 0 ? "all-disable" : ""}>
                                <td span="2">
                                    <CircleAvatar src={o.productImages[0]} size={40} />
                                </td>
                                <td>
                                    <p className="m-txt m-txt--bold">{o.name}</p>
                                </td>
                                <td span="2">
                                    <p className="m-txt m-txt--bold m-txt--grey">{o.sku}</p>
                                </td>
                                <td>
                                    <p className="m-txt m-txt--bold">{o.categoryName}</p>
                                </td>
                                <td span="2">
                                    <p className="m-txt m-txt--bold">{formatCurrency(o.price)}</p>
                                </td>
                                <td>
                                    <ProductStock product={o} triggerRefresh={triggerRefresh} />
                                </td>
                                {!displayOnly ? (
                                    <td onClick={onClick}>
                                        <div className="product-table__selector">
                                            {selected ? (
                                                <IoCheckmarkSharp size={22} className="m-txt" />
                                            ) : (
                                                <IoEllipseOutline size={25} />
                                            )}
                                        </div>
                                    </td>
                                ) : null}
                                {onNavigate ? (
                                    <td onClick={onClick}>
                                        <div className="product-table__selector">
                                            <IoChevronForward size={22} className="m-txt m-txt--grey" />
                                        </div>
                                    </td>
                                ) : null}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            {productList.length == 0 && <div className="m-container--center m-wrapper"><p>No items found</p></div>}
        </>
    );
};

export default ProductTable;
