import React from 'react';

const Button = ({ outline, gold, red, text, small, ...props }) => {
    const className = ['button'];
    const textS = 'button--txt';
    const outlineS = 'button--outline';
    const goldS = 'button--gold';
    const smallS = 'button--small';
    const textGoldS = 'button--txt-gold';
    const redS = 'button--txt-red'; 
    const redOutlineS = 'button--outline-red';
    
    if (text) className.push(textS);
    if (text && gold) className.push(textGoldS);
    if (outline) className.push(outlineS);
    if (gold) className.push(goldS);
    if (small) className.push(smallS);
    if (red) className.push(redS);
    if (red && outline) className.push(redOutlineS);

    return (
        <button className={className.join(' ')} {...props} onClick={props.onClick}>
            <p className="button__txt">{props.label}</p>
        </button>
    );
};

export default Button;
