import React, { Fragment, useState, useEffect, useMemo, memo, useRef } from 'react';
import * as yup from 'yup';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Form, Formik, ErrorMessage } from 'formik';
import { IoAddSharp, IoAddCircleSharp , IoCloseCircleSharp, IoTrashOutline } from 'react-icons/io5';
import moment from 'moment';
import _ from 'lodash';

import Button from 'components/button/button';
import BreadCrumb from 'components/breadcrumb/breadcrumb';
import DateTimePicker from 'components/inputs/date-time-picker/date-time-picker';
import TextInput from 'components/inputs/text-input/text-input';
import { pathnames } from 'routes/routes';
import api from 'services/api';
import constanst from 'helpers/constants';
import config from 'helpers/config';
import { formatCurrency } from 'helpers/utility';
import CircleAvatar from 'components/circle-avatar/circle-avatar';
import LiveStreamSelectProductPage from '../live-stream-select-product/live-stream-select-product-page';
import Popup from 'components/popup/popup';
import DeleteStreamIcon from 'assets/icons/icon-stream-delete.svg';

const validationSchema = yup.object().shape({
    streamDate: yup.string().required("Stream date is required."),
    streamTitle: yup.string().required("Stream title is required."),
    image: yup.string().required("Cover image is required"),
});

const LiveStreamCreatePage = (props) => {
    const formikRef = useRef();
    const imageRef = useRef();
    const history = useHistory();
    const { state } = useLocation();
    const { id } = useSelector((state) => state.user.profile);
    const [onSubmit, setOnSubmit] = useState(false);
    const [selectProductPopup, setSelectProductPopup] = useState(false);
    const [deleteStreamPopup, setDeleteStreamPopup] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState([]);
    const submitBtnText = state?.editMode ? (onSubmit ? 'SAVING' : 'SAVE') : (onSubmit ? 'ADDING' : 'ADD');
    const initialValues = useMemo(
        () => ({
            streamDate: state?.stream?.streamDate || '',
            streamTime: state?.stream?.streamTime || '',
            streamTitle: state?.stream?.title || '',
            image: state?.stream?.image || '',
        }),
        [state]
    );

    useEffect(() => {
        if (state?.stream?.liveProducts) {
            let products = state?.stream?.liveProducts.map((o) => ({
                id: o.productId,
                ...o,
            }))
            setSelectedProduct(products)
        }
    }, [state])

    const onHandleOnSubmit = async (values) => {
        setOnSubmit(true);

        if (values.streamDate) {
            const future = moment(values.streamDate).isAfter(new Date());
            if (!future) {
                props.onHandleShowMessage(
                    'Stream cannot be created for passed date and time.',
                    true
                );
                return;
            }
        }
        if (!selectedProduct) {
            props.onHandleShowMessage('Mininum select one product.', true);
            return;
        }

        try {
            if (state?.createMode) {
                const payload = {
                    companyId: id,
                    streamTitle: values.streamTitle,
                    streamDate: state?.createMode ? moment(values.streamDate).format(constanst.DATE) : values.streamDate,
                    streamTime: moment(values.streamDate).format('HH:mm'),
                    streamType: "live",
                    image: values.image,
                    products: selectedProduct.map((o) => o.id).toString(),
                };

                const formData = new FormData();
                for (var key in payload) {
                    formData.append(key, payload[key]);
                }

                await api.post.streamCreate(formData);
            } else {
                const payload = {
                    id: state?.stream?.id,
                    streamTitle: values.streamTitle,
                    streamDate: values.streamDate,
                    streamTime: moment(values.streamDate).format('HH:mm'),
                    streamType: "live",
                    image: values.image,
                    products: selectedProduct.map((o) => o.id).toString(),
                };
                
                const formData = new FormData();
                for (var key in payload) {
                    formData.append(key, payload[key]);
                }

                await api.post.streamUpdate(formData)
            }
            history.push(pathnames.liveStreamList);
            props.onHandleShowMessage('Live Stream Created');
        } catch (error) {
            props.onHandleShowMessage(error, true);
            setOnSubmit(false)
        } finally {
            setOnSubmit(false);
        }
    };

    const onHandleRemoveProduct = (index) => {
        var selectedArray = [...selectedProduct];
        selectedArray.splice(index, 1)
        setSelectedProduct(selectedArray)
    }

    const onHandleStreamDelete = async () => {
        try {
            const resp = await api.patch.streamDelete(state?.stream?.streamKey)
            history.push(pathnames.liveStreamList);
            props.onHandleShowMessage('Live Stream Deleted');
        } catch (error) {
            props.onHandleShowMessage(error)
        }
    }

    const onHandleTriggerUpload = () => {
        imageRef.current.click();
    };

    const onHandleNavigateSelectProduct = () => {
        setSelectProductPopup(true)
    };

    const onHandleDisableUpdate = (isDirty) => {
        let isSameProducts = _.isEqual(selectedProduct.map(item => item.productId), state?.stream?.liveProducts.map(item => item.productId))

        if (isDirty) {
            return false;
        } else if (onSubmit) {
            return true;
        } else if (isSameProducts) {
            return true;
        }
    }

    return (
        <div className="live-stream-create">
            <div className="m-container">
                <div className="m-wrapper">
                    <BreadCrumb
                        paths={[{ onClick: history.goBack, text: "Live Streams" }, state?.createMode ? "Schedule New" : "Live stream details"]}
                    />
                </div>
                <div className="live-stream-create__wrapper m-wrapper m-card">
                    {state?.editMode !== false && !state?.createMode ?
                        <Button 
                            outline 
                            red 
                            small 
                            label={
                                <span className="m-container--center">
                                    <IoTrashOutline className="m-txt--error" size={20} />
                                    &nbsp;&nbsp;&nbsp;Delete
                                </span>
                            }
                            onClick={() => setDeleteStreamPopup(true)}
                        />
                        : null
                    }
                    <p className="live-stream-create__headline m-txt m-txt--bold m-txt--l">
                        {state?.createMode ? "Schedule new live stream" : "Live stream details"}
                    </p>
                    <Formik
                        innerRef={formikRef}
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={onHandleOnSubmit}
                    >
                        {({ values, errors, dirty, setFieldValue }) => (
                            <Form>
                                <div className="live-stream-create__input-wrapper">
                                    <DateTimePicker
                                        name="streamDate"
                                        value={values.streamDate && moment(values.streamDate).format(constanst.DATE) + " " + moment(values.streamDate).format("HH:mm")}
                                        label="Date & Time to Start"
                                        placeholder="Date & Time to Start"
                                        onChange={(date) => {
                                            setFieldValue('streamDate', date);
                                        }}
                                        disabled={onSubmit || state?.editMode == false}
                                    />
                                    <p className="m-txt m-txt--s m-txt--error">
                                        <ErrorMessage name="streamDate" />
                                    </p>
                                </div>
                                <div className="live-stream-create__input-wrapper">
                                    <TextInput
                                        outline
                                        max
                                        name="streamTitle"
                                        label="Live Title"
                                        placeholder="Enter live title"
                                        disabled={onSubmit || state?.editMode == false}
                                    />
                                    <p className="m-txt m-txt--s m-txt--error">
                                        <ErrorMessage name="streamTitle" />
                                    </p>
                                </div>
                                <div className="live-stream-create__input-wrapper">
                                    <p className="m-txt m-txt--s m-txt--grey">Cover Picture</p>
                                    <button
                                        type="button"
                                        className="live-stream-create__upload-button"
                                        onClick={onHandleTriggerUpload}
                                    >
                                        {values.image ? (
                                            <OptimzedCoverImage src={values.image} />
                                        ) : (
                                            <IoAddSharp
                                                size={22}
                                                className="live-stream-create__icon-add"
                                            />
                                        )}
                                    </button>
                                    <input
                                        type="file"
                                        name="image"
                                        hidden
                                        accept={config.file.images.toString()}
                                        ref={imageRef}
                                        onChange={(e) => {
                                            setFieldValue('image', e.target.files[0]);
                                        }}
                                    />
                                    <p className="m-txt m-txt--s m-txt--error">
                                        <ErrorMessage name="image" />
                                    </p>
                                </div>
                                <div className="live-stream-create__input-wrapper live-stream-create__input-wrapper--fullw">
                                    <div className="live-stream-create__add-product">
                                        <p className="m-txt m-txt--s m-txt--grey">
                                            Products (Maximum 10 products)
                                        </p>
                                        {selectedProduct.length > 0 && state?.editMode !== false ? (
                                            <div
                                                className="live-stream-create__add-action"
                                                onClick={onHandleNavigateSelectProduct}
                                            >
                                                <IoAddCircleSharp
                                                    size={23}
                                                    className="live-stream-create__add-icon"
                                                />
                                                <p className="m-txt m-txt--bold">Add/Edit Product</p>
                                            </div>
                                        ) : null}
                                    </div>
                                    <LiveStreamSelectProductPage 
                                        selectProductPopup={selectProductPopup} 
                                        onClose={setSelectProductPopup} 
                                        selectedProductList={selectedProduct}
                                        completeSelectProduct={setSelectedProduct}
                                    />
                                    {selectedProduct.length > 0 ? (
                                        <div className="live-stream-create__table-wrapper">
                                            <SelectedProductTable 
                                                selectedProduct={selectedProduct} 
                                                removeProduct={onHandleRemoveProduct}
                                                editMode={state?.editMode}
                                            />
                                        </div>
                                    ) : (
                                        <Fragment>
                                            <button
                                                type="button"
                                                className="live-stream-create__upload-button live-stream-create__upload-button--product"
                                                onClick={onHandleNavigateSelectProduct}
                                            >
                                                <div>
                                                    <IoAddSharp
                                                        size={22}
                                                        className="live-stream-create__icon-add"
                                                    />
                                                    <p className="m-txt m-txt--s m-txt--grey">Select product</p>
                                                </div>
                                            </button>
                                            <p className="m-txt m-txt--s m-txt--error">
                                                <ErrorMessage name="products" />
                                            </p>
                                        </Fragment>
                                    )}
                                </div>

                                <div className="live-stream-create__button-wrapper">
                                    {state?.editMode || state?.createMode ? 
                                        <Button
                                            type="submit"
                                            label={submitBtnText}
                                            disabled={onHandleDisableUpdate(dirty)}
                                        />
                                        : null
                                    }
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
                <Popup open={deleteStreamPopup} onPopupClose={() => setDeleteStreamPopup(false)} icon={<img src={DeleteStreamIcon} />} >
                    <div className="delete-stream__popup">
                        <div className="delete-stream__popup--msg">
                            <p className="m-txt m-txt--m m-txt--bold">Are you sure you want to permanently <br /> delete this record?</p>
                        </div>
                        <div className="delete-stream__popup--footer">
                            <Button small text label="CANCEL" type="button" onClick={() => setDeleteStreamPopup(false)} />
                            <Button small text red label="DELETE" type="button" onClick={onHandleStreamDelete} />
                        </div>
                    </div>
                </Popup>
            </div>
        </div>
    );
};

export default LiveStreamCreatePage;

const OptimzedCoverImage = memo(({ src }) => {    
    const onHandleImageSrc = () => {
        if (typeof src == "string") {
            return src
        }

        return URL.createObjectURL(src)
    }

    return (
        <div
            className="live-stream-create__uploaded-cover"
            style={{ backgroundImage: `url(${onHandleImageSrc()})` }}
        />
    )
});

const SelectedProductTable = ({ selectedProduct = [], removeProduct, editMode }) => {
    return (
        <>
            <table border="0" cellSpacing="0" cellPadding="0" className="product-table">
                <thead>
                    <tr>
                        <th />
                        <th span="2">
                            <p className="m-txt m-txt--white m-txt--bold">Product Name</p>
                        </th>
                        <th span="2">
                            <p className="m-txt m-txt--white m-txt--bold">Product SKU</p>
                        </th>
                        <th>
                            <p className="m-txt m-txt--white m-txt--bold">Price (RM)</p>
                        </th>
                        <th>
                            <p className="m-txt m-txt--white m-txt--bold">Avail Stock</p>
                        </th>
                        {editMode !== false ? <th /> : null}
                    </tr>
                </thead>
                <tbody>
                    {selectedProduct.map((o, i) => {
                        const key = `${i}-product-item`;
                        
                        return (
                            <tr key={key}>
                                <td span="2">
                                    <CircleAvatar src={o.productImage || o.productImages[0]} size={40} />
                                </td>
                                <td>
                                    <p className="m-txt m-txt--bold">{o.productName || o.name}</p>
                                </td>
                                <td span="2">
                                    <p className="m-txt m-txt--bold m-txt--grey">{o.productSKU || o.sku}</p>
                                </td>
                                <td>
                                    <p className="m-txt m-txt--bold">{formatCurrency(o.productPrice || o.price)}</p>
                                </td>
                                <td>
                                    <p className="m-txt m-txt--bold">{o.stock}</p>
                                </td>
                                {editMode !== false ? 
                                    <td>
                                        <div className="product-table__selector" onClick={() => removeProduct(i)}>
                                            <IoCloseCircleSharp size={25} className="m-txt--error" />
                                        </div>
                                    </td>
                                    : null
                                }
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </>
    );
};