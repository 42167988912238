import React, { useState, useMemo, useCallback } from 'react';
import { FiChevronRight } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';

import AccountBusinessInformation from 'pages/accounts/account-business-information/account-business-information-page';
import AccountResetPasswordPage from 'pages/accounts/account-reset-password/account-reset-password-page';
import AccountShippingPage from 'pages/accounts/account-shipping/account-shipping-page';
import BreadCrumb from 'components/breadcrumb/breadcrumb';
import Tabs from 'components/tabs/tabs';

const tabs = [
    {
        value: 0,
        label: 'Business Information',
    },
    {
        value: 1,
        label: 'Shipping',
    },
    {
        value: 2,
        label: 'Reset Password',
    },
];

const AccountSettingsPage = (props) => {
    const history = useHistory();
    const [tab, setTab] = useState(0);

    const onChangeTab = useCallback((nextTab) => {
        setTab(nextTab);
    }, []);

    const currentSection = () => {
        switch (tab) {
            case 0:
                return <AccountBusinessInformation {...props} />
            case 1:
                return <AccountShippingPage {...props} />
            case 2:
                return <AccountResetPasswordPage {...props} />
            default:
                break;
        }
    }

    return (
        <div className="account-settings">
            <div className="m-container">
                <div className="m-wrapper">
                    <BreadCrumb
                        paths={[
                            { onClick: history.goBack, text: 'My Account' },
                            'Account Settings',
                        ]}
                    />
                </div>
            </div>
            <div className="m-container">
                <div className="m-wrapper">
                    <Tabs items={tabs} value={tab} onChangeTab={onChangeTab} />
                    <div className="account-settings__section">
                        {currentSection()}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AccountSettingsPage;
