import React, { useRef, useState, useCallback, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { IoEllipseOutline, IoCheckmarkCircleSharp } from 'react-icons/io5';
import { useDispatch } from 'react-redux';

import Button from 'components/button/button';
import SearchBar from 'components/search-bar/search-bar';
import { useIsMount } from 'hooks/use-mount';
import { pathnames } from 'routes/routes';
import api from 'services/api';
import Popup from 'components/popup/popup';
import CircleAvatar from 'components/circle-avatar/circle-avatar';
import { formatCurrency } from 'helpers/utility';
import { setInfoShow } from 'redux/actions/info-actions';
import EmptyState from 'components/empty-state/empty-state';
import EmptyProductsIcon from 'assets/icons/icon-empty-products.svg';

const initialData = {
    productList: [],
    totalPage: 0,
};

const LiveStreamSelectProductPage = ({ selectProductPopup, onClose, selectedProductList = [], completeSelectProduct, ...props }) => {
    const pageRef = useRef(0);
    const isMount = useIsMount();
    const history = useHistory();
    const { state } = useLocation();
    const dispatch = useDispatch();
    const { companyId } = useSelector((state) => state.user.profile);
    const [merchant, setMerchant] = useState(initialData);
    const [selectedProduct, setSelectedProduct] = useState([]);
    const [search, setSearch] = useState();

    const onHandleGetProducts = useCallback(
        async (params = {}) => {
            try {
                const payload = { companyId, page: pageRef.current, status: "active", ...params };
                const response = await api.get.products(payload);
                const data = response.data.result;

                if (pageRef.current === 0) {
                    setMerchant(data);
                } else {
                    setMerchant({
                        ...merchant,
                        totalPage: data.totalPage,
                        productList: [...merchant.productList, ...data.productList],
                    });
                }
            } catch (error) {
                dispatch(setInfoShow(error));
                history.goBack();
            }
        },
        [companyId, merchant, props, history]
    );

    useEffect(() => {
        if (isMount) {
            onHandleGetProducts();  
        }
    }, [isMount, onHandleGetProducts]);

    useEffect(() => {
        if (selectedProductList) {
            setSelectedProduct(selectedProductList);
        }
    }, [selectedProductList])

    const onHandleSelectProduct = (o) => {
        if (selectedProduct.length) {
            const foundProduct = selectedProduct.filter((i) => i.id === o.id)[0];
            if (foundProduct) {
                setSelectedProduct([...selectedProduct.filter((k) => k.id !== o.id)]);
            } else {
                if (selectedProduct.length < 10) {
                    setSelectedProduct([...selectedProduct, o]);
                } else {
                    dispatch(setInfoShow('Maximum 10 products only', true));
                }
            }
        } else {
            setSelectedProduct([o]);
        }
    };

    const onHanldeScrollGetProduct = () => {
        if (!merchant) return;
        
        if (pageRef && pageRef.current + 1 < merchant.totalPage) {
            let increasedPage = (pageRef.current += 1);
            pageRef.current = increasedPage;
            onHandleGetProducts();
        }
    };

    const scrollRef = useCallback(node => {
        if (node !== null) {
            const onHandleScroll = (e) => {
                const target = e.target;
                const scrollHeight = target.scrollHeight - target.clientHeight;
                const currentScroll = target.scrollTop;
    
                if (scrollHeight <= currentScroll) {
                    onHanldeScrollGetProduct();
                }
            };
    
            node.addEventListener('scroll', onHandleScroll);
        }
    }, [merchant]);

    const onHandleComplete = () => {
        completeSelectProduct(selectedProduct)
        onClose(false)
    };

    const onHandleOnSubmit = (o) => {
        pageRef.current = 0;
        setSearch(o)

        onHandleGetProducts({ value: o });
    };

    const onHandleCreateProduct = (o) => {
        history.push(pathnames.productCreate);
    };

    return (
        <Popup open={selectProductPopup} onPopupClose={() => onClose(false)}>
            {merchant.productList.length == 0 ?
                <div className="select-product__popup">
                    <div className="select-product__popup--header">
                        <p className="m-txt m-txt--m m-txt--bold">Select products for this live stream</p>
                    </div>
                    <EmptyState 
                        emptyList={true} 
                        emptyListMsg="Sorry, no product in your store yet"
                        emptyListIcon={EmptyProductsIcon}
                        button={<Button small gold label="ADD PRODUCT" onClick={onHandleCreateProduct} />}
                    />
                </div> : 
                <div className="select-product__popup">
                    <div className="select-product__popup--header">
                        <p className="m-txt m-txt--m m-txt--bold">Select products for this live stream</p>
                        <div className="select-product__popup--search">
                            <SearchBar onSubmit={onHandleOnSubmit} value={search} placeholder="Search Product Id, Name"/>
                            <p className="m-txt m-txt--bold m-txt--grey"><span className="m-txt--valid">{selectedProduct.length}</span> /10</p>
                        </div>
                    </div>
                    <div className="select-product__popup--body" ref={scrollRef}>
                        {merchant.productList.map((o, i) => {
                            const selected = selectedProduct.findIndex((k) => k.id === o.id) > -1;

                            return (
                                <div className="select-product__popup--wrapper" key={`${o.name}-${i}`}>
                                    <div className="select-product__item">
                                        <div className="select-product__item select-product__item--left">
                                            <CircleAvatar src={o.productImages[0]} size={40} />
                                            <div>
                                                <p className="m-txt m-txt--bold">{o.name}</p>
                                                <p className="m-txt m-txt--s m-txt--grey">SKU: {o.sku}</p>
                                            </div>
                                        </div>
                                        <div className="select-product__item select-product__item--right">
                                            <div>
                                                <p className="m-txt m-txt--s m-txt--bold">RM {formatCurrency(o.price)}</p>
                                                <p className="m-txt m-txt--s m-txt--bold">Stock: {o.stock}</p>
                                            </div>
                                            <div onClick={() => onHandleSelectProduct(o)}>
                                            {selected ? 
                                                <IoCheckmarkCircleSharp size={25} className="m-txt--valid" /> :
                                                <IoEllipseOutline size={25} className="m-txt--grey" />
                                            }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className="select-product__popup--footer">
                        <Button small text label="CANCEL" type="button" onClick={() => onClose(false)} />
                        <Button small text gold label="DONE" type="button" onClick={onHandleComplete} />
                    </div>
                </div>
            }
        </Popup>
    );
};

export default LiveStreamSelectProductPage;
