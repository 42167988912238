import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useHistory, useLocation } from 'react-router';
import { FaStar } from 'react-icons/fa';

import BreadCrumb from 'components/breadcrumb/breadcrumb';
import api from 'services/api';
import CircleAvatar from 'components/circle-avatar/circle-avatar';
import RatingPicker from 'components/rating-picker/rating-picker';
import Button from 'components/button/button';
import { formatDate } from 'helpers/utility';
import { pathnames } from 'routes/routes';
import SearchBar from 'components/search-bar/search-bar';
import Filter from 'components/filter/filter';
import TextInput from 'components/inputs/text-input/text-input';
import usePrevious from 'hooks/use-previous';

const filterStar = (rating, text, reviews) => {
    return (
        <div>
            {[...Array(rating).keys()].map((star, i) => <FaStar key={i} size={16} color={"#eacb65"} style={{marginRight: "8px"}}/>)}
            {text} ({reviews})
        </div>
    )
}

const AccountProductReviewDetailPage = () => {
    const history = useHistory();
    const location = useLocation();
    const reviewItem = location.state.product;
    const totalReview = useRef(0);
    const [productReviews, setProductReviews] = useState([]);
    const [input, setInput] = useState([]);
    const [search, setSearch] = useState();
    const [ratingCount, setRatingCount] = useState({
        three: 0,
        four: 0,
        five: 0
    });
    const [selectedFilter, setSelectedFilter] = useState(0);

    const filters = [
        { label: "All", value: "" },
        { label: "With Image & Video", value: "withImageOrVideo" },
        { label: filterStar(3, "& below", ratingCount.three), value: 3 },
        { label: filterStar(4, "", ratingCount.four), value: 4 },
        { label: filterStar(5, "", ratingCount.five), value: 5 },
    ];

    useEffect(() => {
        fetchReviews()
    }, [])

    const fetchReviews = useCallback(async (params) => {
        const payload = {
            productId: reviewItem.productId,
            ...params
        }

        try {
            const resp = await api.get.productReviews(payload);
            setProductReviews(resp.data.result.records);
            setRatingCount({
                three: resp.data.result.threeStarAndBelowReviewsNumber,
                four: resp.data.result.fourStarReviewsNumber,
                five: resp.data.result.fiveStarReviewsNumber
            })
        } catch (error) {
            console.log(error);
        }
    }, [reviewItem, selectedFilter]);

    const onHandleInput = (value, index) => {
        let items = [...input]
        let item = items.find(x => x.index == index)

        if (item) {
            item.index = index;
            item.reply = value;
            setInput(items)
        } else {
            setInput([
                ...input,
                {
                    index: index,
                    reply: value
                }
            ])
        }
    }

    const onHandleReply = useCallback(async (index, id) => {
        const replyMessage = input[index].reply;
        if (!replyMessage) return;

        try {
            const resp = await api.post.productReviewsReply({ id: id, reply: replyMessage });
            setProductReviews(resp.data.result.records);
        } catch (error) {
            console.log(error);
        }
    }, [input]);

    const onHandleInputDisable = (index) => {
        let item = input.find(x => x.index == index)

        if (item && item.reply) {
            return false
        } else {
            return true
        }
    }

    const onHandleOnSubmit = (o) => {
        setSearch(o)
        fetchReviews({ search: o })
    };

    const handleFilter = (item) => {
        setSelectedFilter(_.findIndex(filters, item))
        setSearch("")

        if (item.value) {
            if (item.value == "withImageOrVideo") {
                fetchReviews({ withImageOrVideo: true });                
            } else if (item.value == 3) {
                fetchReviews({ threeStarAndBelow: true })
            } else if (item.value == 4) {
                fetchReviews({ fourStar: true })
            } else {
                fetchReviews({ fiveStar: true });                
            }
        } else {
            fetchReviews()
        }
    }

    return (
        <div className="account-product-review-detail">
            <div className="m-container">
                <div className="m-wrapper">
                    <BreadCrumb
                        paths={[
                            { onClick: () => history.push(pathnames.accounts), text: 'My account' },
                            { onClick: history.goBack, text: 'Product reviews' },
                            'Reviews'
                        ]}
                    />
                    <p className="m-txt m-txt--m m-txt--bold">Review details</p>
                    <div className="account-product-review-detail__product">
                        <div>
                            <p className="m-txt m-txt--s m-txt--grey account-product-review-detail__product--title">Product name</p>
                            <div className="account-product-review-detail__product--wrapper">
                                <CircleAvatar src={reviewItem.productImage} size={28} />
                                <p className="m-txt">{reviewItem.productName}</p>
                            </div>
                        </div>
                        <div>
                            <p className="m-txt m-txt--s m-txt--grey account-product-review-detail__product--title">Product SKU</p>
                            <p className="m-txt">{reviewItem.sku}</p>
                        </div>
                        <div>
                            <p className="m-txt m-txt--s m-txt--grey account-product-review-detail__product--title">No. reviews</p>
                            <p className="m-txt">{totalReview.current}</p>
                        </div>
                        <div>
                            <p className="m-txt m-txt--s m-txt--grey account-product-review-detail__product--title">Average ratings</p>
                            <div className="account-product-review-detail__product--rating">
                                <p className="m-txt">
                                    <span className="m-txt--bold">{reviewItem.rating}</span> 
                                    <span className="m-txt--grey"> / 5</span>
                                </p>
                                <RatingPicker value={reviewItem.rating} />
                            </div>
                        </div>
                    </div>
                    <div className="account-product-review__search">
                        <SearchBar onSubmit={onHandleOnSubmit} value={search} />
                        <Filter filters={filters} selected={selectedFilter} onClick={(item) => handleFilter(item)}/>
                    </div>
                    {productReviews.length > 0 ? 
                        productReviews.map((item, index) => {
                            const reviewImages = item.image.replace(/[\[\]']+/g,'').split(", ");
                            return (
                                <div className="account-product-review-detail__reviews--card" key={index}>
                                    <p className="m-txt m-txt--s account-product-review-detail__reviews--date">{formatDate(item.updatedAt)}</p>
                                    <div className="account-product-review-detail__reviews--wrapper">
                                        <CircleAvatar src={item.userImage} size={54} />
                                        <div>
                                            <p className="m-txt m-txt--m m-txt--bold">{item.userName} (ID: {item.userId})</p>
                                            <p className="m-txt m-txt--s m-txt--grey">{item.productVariation}</p>
                                            <div className="account-product-review-detail__reviews--body">
                                                <p className="m-txt m-txt--s m-txt--bold">Review:</p>
                                                <RatingPicker value={item.rating} />
                                                <p className="m-txt m-txt--s">{item.review}</p>
                                                <div className="account-product-review-detail__reviews--imageWrapper">
                                                    {item.image &&
                                                        reviewImages.map((image) => (
                                                            <div className="account-product-review-detail__reviews--image">
                                                                <a href={image} target="_blank">
                                                                    <img alt="review image" src={image} />
                                                                </a>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                            {item.replies ? 
                                                <div className="account-product-review-detail__reviews--replied">
                                                    <div>
                                                    <p className="m-txt m-txt--s">Your reply:</p>
                                                    <p className="m-txt m-txt--xs m-txt--grey">{formatDate(item.replyAt)}</p>
                                                    </div>
                                                    <p className="m-txt m-txt--s">{item.replies}</p>
                                                </div> : null
                                            }
                                            <div className="account-product-review-detail__reviews--reply">
                                                <input type="text" placeholder="Please write your reply here" onChange={(e) => onHandleInput(e.target.value, index)}/>
                                                <div>
                                                    <Button
                                                        small
                                                        gold
                                                        label="Reply"
                                                        disabled={onHandleInputDisable(index)}
                                                        onClick={() => onHandleReply(index, item.id)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }) 
                        : <p className="m-txt m-txt--bold">No items found</p>
                    }
                </div>
            </div>
        </div>
    )
}

export default AccountProductReviewDetailPage;