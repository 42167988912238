import * as yup from 'yup';
import moment from "moment";

export const orderStatusFormat = (statusCode) => {
    let status = {};

    switch (statusCode) {
        case "pendingPayment":
            status = { label: 'PENDING PAYMENT', color: 'warning' };
            break;
        case "toPack":
            status = { label: 'TO PACK', color: 'warning' };
            break;
        case "packing":
            status = { label: 'PACKING', color: 'warning' };
            break;
        case "shipped":
            status = { label: 'SHIPPED', color: 'warning' };
            break;
        case "cancelled":
            status = { label: 'CANCELLED', color: 'error' };
            break;
        case "cancelledByCustomer":
            status = { label: 'CANCELLED BY CUSTOMER', color: 'error' };
            break;
        case "delivered":
            status = { label: 'DELIVERED', color: 'valid' };
            break;
        case "refunded":
            status = { label: 'REFUNDED', color: 'valid' };
            break;
        default:
            break;
    }

    return status;
};

export const yupPasswordConfig = (fieldName = 'Password') =>
    yup
        .string()
        .matches(
            /^(?=.{8,18})(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=\]{};':".<>?]).*$/,
            `${fieldName} requires at least 1 Uppercase, 1 Lowercase, 1 Special Character and must be between 8 to 18 characters.`
        )
        .required(`${fieldName} is required.`);

export const formatPhoneNumber = (phoneNumber) => {
    let formatted = phoneNumber.replace(' ', '').replace(' ', '');
    if (formatted.substring(0, 2) !== '+6') {
        formatted = '+6' + formatted;
    }
    if (formatted.length >= 12) {
        return formatted.replace(/(\d{4})(\d{3})(\d{4})/, '$1 $2 $3');
    } else if (formatted.length <= 3) {
        return '+60';
    } else {
        return phoneNumber;
    }
};

export const validMobileNumbers = (value) => {
    if (!value) return false;
    const mobileRegex = /^(\+?6?01)[0-46-9]-*[0-9]{7,8}$/g;
    const validMobileNumbers = value.match(mobileRegex);
    return validMobileNumbers;
};

export const sanitizeError = (e) => {
    let errorObj = e;
    if (typeof e === 'object') {
        if (!!e.response) {
            if (!!e.response.data) {
                errorObj = e.response.data;
            } else {
                errorObj = e.response;
            }
        } else if (!!e.data) {
            errorObj = e.data;
        }

        return errorObj.message;
    } else {
        errorObj = e;
        return errorObj;
    }
};


export const parseQueryString = function (str) {
    const objURL = {};

    str.replace(
        new RegExp("([^?=&]+)(=([^&]*))?", "g"),
        function ($0, $1, $2, $3) {
            objURL[$1] = $3;
        }
    );
    return objURL;
};


export const formatCurrency = (num) => {
    if (!num) return '0.00';
    return Number(num)
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};


export const getVariationStatus = (o) => {
    if (o.variations) {
        const opt = o.variations[0];
        const optOne = opt.variations.variationOne;
        const optTwo = opt.variations.variationTwo;

        if (optTwo) {
            return optOne.concat(` - ${optTwo}`);
        }
        else {
            return optOne;
        }
    }
}

export const changeAddressToString = (address) => {
    if (address) {
        const result = JSON.parse(address);
        return result !== null
            ? Object.keys(result)
                .map((item) => result[item])
                .join(', ')
            : null;
    }
};

export const formatNRIC = (value) => {
    const val = value.replace(/-/g, "");
    const val_1 = val.substring(0, 6);
    const val_2 = val.substring(6, 8);
    const val_3 = val.substring(8, 12);

    if (val.length <= 6) {
        return val;
    } else if (val.length > 6 && val.length < 9) {
        return `${val_1}-${val_2}`;
    } else if (val.length >= 9) {
        return `${val_1}-${val_2}-${val_3}`;
    }
};

export const validNric = (value) => {
    if (!value) return false;

    const nricRegex = /^\d{6}-\d{2}-\d{4}$/g;
    const validNric = value.match(nricRegex);
    return validNric;
};

export const formatDate = (date, format) => {
    let formattedDate = moment(date).calendar(null, {
        lastDay: '[Yesterday]',
        sameDay: '[Today]',
        nextDay: '[Tomorrow]',
        lastWeek: 'DD MMM YYYY',
        nextWeek: 'DD MMM YYYY',
        sameElse: 'DD MMM YYYY',
    });

    if (format) {
        formattedDate = moment(date).format(format);

        if (format === "timestamp") {
            formattedDate = moment(date).unix() * 1000;
        }
    }

    return formattedDate;
};

export const formatVariations = (o) => {
    if (o.variationLevels) {
        const titleOne = o.variations[0].titles[0];
        const titleTwo = o.variations[0].titles[1];
        const optionOne = o.variationLevels.variationOne;
        const optionTwo = o.variationLevels.variationTwo;

        if (optionOne && !optionTwo) {
            return `${titleOne}: ${optionOne}`;
        }

        if (optionOne && optionTwo) {
            return `${titleOne}: ${optionOne}, ${titleTwo}: ${optionTwo}`;
        }
    }

    return '';
};


export const stockColor = (o) => {

    if (o === 0) {
        return { label: 'Out of stock', color: 'error' };
    }
    else if (o < 50) {
        return { label: `Low stock ${o}`, color: 'warning' };
    }
    else {
        return { label: o, color: 'valid' };
    }

}