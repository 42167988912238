import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as yup from 'yup';

import Button from 'components/button/button';
import Error from 'components/error/error';
import ReactCodeInput from 'react-verification-code-input';
import overviewImage from 'assets/login/overview.svg';

import api from "services/api"
import { pathnames } from 'routes/routes';

const initialValues = {
    verificationToken: '',
};

const validationSchema = yup.object().shape({
    verificationToken: yup.string().required('Token is required'),
});

const VerifyAccountPage = (props) => {
    const [error, setError] = useState('');
    const [onSubmit, setOnSubmit] = useState(false);
    const [onResendCode, setResendCode] = useState(false);
    const submitBtnText = onSubmit ? "VERIFYING" : "VERIFY";
    const onResendBtnText = onResendCode ? "Resending..." : "Resend Code";
    const location = useLocation();
    const history = useHistory();
    const { userId } = location.state;

    const onHandleSubmit = async (values) => {
        const payload = {
            ...values,
            userId
        }
        setOnSubmit(true);
        try {
            await api.post.activateUser(payload);
            history.push(pathnames.login);
            props.onHandleShowMessage("Sucessful Create, proceed with login");
        }
        catch (error) {
            setError(error.response.data.message);
        } finally {
            setOnSubmit(false);
        }
    };

    const onHandleResendCode = async () => {
        setResendCode(true);
        try {
            await api.get.resendActivationCode({ userId });
        }
        catch (error) {
            setError(error.response.data.message);
        } finally {
            setResendCode(false);
        }
    };

    return (
        <div className="verification-account m-full-h">

            <div className="container m-container m-container--center">
                <div className="verification-account__card m-card">

                    <section className="verification-account__section">
                        <img src={overviewImage} alt="" />
                    </section>

                    <div className="verification-account__divider" />

                    <section className="verification-account__section">

                        <p className="forgot-password__headline m-txt m-txt--xl m-txt">
                            Verify Your Account
                        </p>
                        <p className="m-txt m-txt--small">
                            Please enter the verification token sent to your email.
                        </p>

                        <p className="m-txt">
                            Enter code here
                        </p>

                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={onHandleSubmit}
                        >
                            {(props) => (
                                <Form>
                                    <ReactCodeInput
                                        className="verification-account__token-field"
                                        fields={4}
                                        fieldWidth={42}
                                        fieldHeight={42}
                                        name="verificationToken"
                                        type="number"
                                        disabled={onSubmit}
                                        onChange={(value) => props.setFieldValue('verificationToken', value)}
                                    />

                                    <Button label={onResendBtnText} text type="button" onClick={onHandleResendCode} disabled={onResendCode} />

                                    <Error msg={error} />

                                    <Button label={submitBtnText} type="submit" />
                                </Form>
                            )}
                        </Formik>
                    </section>
                </div>
            </div>
        </div>
    );
};

export default VerifyAccountPage;
