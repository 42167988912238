import React, { useState } from 'react';
import moment from "moment";
import { formatDate } from "helpers/utility"
import constants from 'helpers/constants';

const TimePeriodSelector = ({ item, setDates }) => {
    const today = new Date();
    const [selectedPeriod, setSelectedPeriod] = useState(0)

    const handleDatePicker = (index, range) => {
        let startDate = "";
        let endDate = "";
        
        setSelectedPeriod(index);
        endDate = formatDate(today, constants.DATE)

        switch (range) {
            case "week":
                startDate = moment().subtract("1", "week").format(constants.DATE);
                break;
            case "month":
                startDate = moment().subtract("1", "month").format(constants.DATE);
                break;
            case "3month":
                startDate = moment().subtract("3", "month").format(constants.DATE);
                break;
            case "year":
                startDate = moment().subtract("1", "year").format(constants.DATE);
                break;
            case "all":
                startDate = moment().subtract("5", "year").format(constants.DATE);
                break;
            default:
                break;
        }

       setDates({startDate, endDate})
    };

    return (
        <>
            {item.map((x, index) => {
                return (
                    <div
                        className={selectedPeriod === index ? "time-period time-period__selected" : "time-period"}
                        index={index}
                        onClick={() => handleDatePicker(index, x.type)}
                        key={`${index}_${x.label}`}
                    >
                        <div className="m-txt--ss">{x.label}</div>
                    </div>
                )
            })}
        </>
        
    )

}

export default TimePeriodSelector;