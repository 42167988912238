const config = {
    malaysia: {
        state: [
            { label: 'Johor', value: 'Johor' },
            { label: 'Kedah', value: 'Kedah' },
            { label: 'Kelantan', value: 'Kelantan' },
            { label: 'Kuala Lumpur', value: 'Kuala Lumpur' },
            { label: 'Labuan', value: 'Labuan' },
            { label: 'Malacca', value: 'Malacca' },
            { label: 'Negeri Sembilan', value: 'Negeri Sembilan' },
            { label: 'Pahang', value: 'Pahang' },
            { label: 'Perak', value: 'Perak' },
            { label: 'Perlis', value: 'Perlis' },
            { label: 'Penang', value: 'Penang' },
            { label: 'Sabah', value: 'Sabah' },
            { label: 'Sarawak', value: 'Sarawak' },
            { label: 'Selangor', value: 'Selangor' },
            { label: 'Terengganu', value: 'Terengganu' },
        ],
    },
    banks : [
        { label: "CIMB Bank", value: "CIMB Bank" },
        { label: "Public Bank", value: "Public Bank" },
        { label: "RHB Bank", value: "RHB Bank" },
        { label: "Hong Leong Bank", value: "Hong Leong Bank" },
        { label: "Bank Islam", value: "Bank Islam" },
        { label: "Ambank", value: "Ambank" },
        { label: "Affin Bank", value: "Affin Bank" },
        { label: "Alliance Bank", value: "Alliance Bank" },
        { label: "Bank Rakyat", value: "Bank Rakyat" },
        { label: "Bank Muamalat", value: "Bank Muamalat" },
        { label: "BSN", value: "BSN" },
        { label: "HSBC", value: "HSBC" },
        { label: "OCBC", value: "OCBC" },
        { label: "Standard Chartered", value: "Standard Chartered" },
        { label: "UOB", value: "UOB" },
        { label: "Others", value: "Others" },
    ],
    file: {
        images: ["image/jpg", "image/jpeg", "image/png"],
        videos: ["video/mp4"],
        docs: ["application/pdf"],
        businessDocs: ["image/jpg", "image/jpeg", "image/png", "application/pdf"],
        size: 20000000,
    },
    orders: {
        status: [
			{ label: 'To pack', value: 'toPack' },
			{ label: 'Packing', value: 'packing' },
			{ label: 'Shipped', value: 'shipped' },
			{ label: 'Cancel order', value: 'cancelled' },
			{ label: 'Delivered', value: 'delivered' },
		],
        reasonToCancel: [
			{ label: 'Product out of stock', value: 1 },
			{ label: 'Out of delivery area', value: 2 },
			{ label: 'Others', value: 3 },
		],
    }
}

export default config;