import React, { useCallback } from 'react';
import NavBar from 'components/nav-bar/nav-bar';
import Footer from 'components/footer/footer';
import SideNav from 'components/side-nav/side-nav';
import Scrollbars from 'rc-scrollbars';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { setUserLogout } from 'redux/actions/user-actions';
import { pathnames, routes } from 'routes/routes';
import { useIsMount } from 'hooks/use-mount';

const Layout = ({ children }) => {
    const isMount = useIsMount();
    const dispatch = useDispatch();
    const location = useLocation();
    const { auth, profile } = useSelector((state) => state.user);
    const logout = useCallback(() => dispatch(setUserLogout()), [dispatch]);
    const current = routes.filter((o) => o.path === location.pathname);

    if (isMobile && isMount) {
        return <Redirect to={pathnames.mobileUser} />;
    }

    if (isMount && current[0] && !isMobile) {
        const privateRoute = current[0].private;

        if (auth && profile && !privateRoute) {
            return <Redirect to={pathnames.dashboard} />;
        }

        if (auth && !profile && privateRoute) {
            logout();
            return <Redirect to={pathnames.login} />;
        }

        if (!auth && privateRoute) {
            return <Redirect to={pathnames.home} />;
        }
    }

    return (
        <div className="m-page">
            {auth && profile ? <SideNav /> : null}
            <main className="m-main">
                {!isMobile ? <NavBar /> : null }
                <Scrollbars id="rc-scroll">{children}</Scrollbars>
                <Footer />
            </main>
        </div>
    );
};

export default Layout;
