import React, { useState, useEffect } from 'react';

const Filter = ({ filters, selected, onClick }) => {
    const [currentFilter, setcurrentFilter] = useState(0);
    
    useEffect(() => {
        if (selected !== null) {
            setcurrentFilter(selected)
        }
    }, [selected])

    const onHandleFilterSelected = (index, item) => {
        setcurrentFilter(index)
        onClick(item)
    }

    return (
        <div className="filter">
            {filters.map((filter, index) => (
                <div
                    key={index}
                    className={currentFilter === index ? "filter__item filter__item--active m-txt m-txt--bold" : "filter__item m-txt m-txt--grey"}
                    onClick={() => onHandleFilterSelected(index, filter)}
                >
                    {filter.label}
                    <span></span>
                </div>
            ))}
        </div>
    )
}

export default Filter;