import LoginPage from 'pages/auth/login/login-page';
import ForgotPasswordPage from 'pages/auth/forgot-password/forgot-password-page';
import ForgotPasswordVerificationPage from 'pages/auth/forgot-password-verification/forgot-password-verification-page';
import ResetPasswordPage from 'pages/auth/reset-password/reset-password-page';
import RegisterPage from 'pages/auth/register/register-page';
import VerifyAccountPage from 'pages/auth/vefication-account/vefication-account-page';
import DashboardPage from 'pages/dashboard/dashboard-page';
import AccountPage from 'pages/accounts/account/account-page';
import AccountProfile from 'pages/accounts/account-profile/account-profile-page';
import AccountSettingsPage from 'pages/accounts/account-settings/account-settings-page';
import AccountProductReviewPage from 'pages/accounts/account-product-review/account-product-review-page';
import AccountProductReviewDetailPage from 'pages/accounts/account-product-review/account-product-review-detail-page';
import LiveStreamCreatePage from 'pages/live-stream/live-stream-create/live-stream-create-page';
import LiveStreamSelectProductPage from 'pages/live-stream/live-stream-select-product/live-stream-select-product-page';
import liveStreamListsPage from 'pages/live-stream/live-stream-lists/live-stream-lists-page';
import OrdersPage from 'pages/orders/orders/orders-page';
import OrderDetailsPage from 'pages/orders/order-details/order-details-page';
import OrderProductDetailsPage from 'pages/orders/order-product-details/order-product-details-page';
import ProductsPage from 'pages/products/products/products-page';
import ProductCreatePage from 'pages/products/product-create/product-create-page';
import NotFoundPage from 'pages/common/not-found/not-found-page';
import MobileUserPage from 'pages/common/mobile-user/mobile-user-page';
import PolicyPage from 'pages/common/policy/policy-page';
import TermsPage from 'pages/common/terms/terms-page';
import AboutUsPage from 'pages/common/about-us/about-us-page';
import HelpPage from 'pages/common/help/help-page';
import FAQPage from 'pages/common/faq/faq-page';
import AdApplicationPage from 'pages/ad-application/ad-application-page';

export const pathnames = {
    login: '/',
    forgotPasssword: '/forgot-password',
    forgotPasswordVerification: '/forgot-password-verification',
    resetPassword: '/reset-password',
    register: '/register',
    verifyAccount: '/verify-account',
    dashboard: '/dashboard',
    orders: '/orders',
    orderDetails: '/order/details',
    OrderProductDetails: '/order/details/product',
    liveStream: '/live-stream/create',
    liveStreamList: '/live-stream/list',
    liveStreamSelectProduct: '/live-stream/products',
    products: '/products',
    productCreate: '/product/create',
    adApplication: '/ads',
    accounts: '/accounts',
    accountProfile: '/account/profile',
    accountSettings: '/account/settings',
    accountProductReview: '/account/product-review',
    accountProductReviewDetail: '/account/product-review/detail',
    mobileUser: '/mobile-user',
    about: '/about',
    help: '/help',
    faq: '/faq',
    terms: '/terms',
    policy: '/policy',
    notFound: '/*',
};

export const routes = [
    { path: pathnames.login, component: LoginPage, nav: false },
    { path: pathnames.forgotPasssword, component: ForgotPasswordPage, nav: false },
    {
        path: pathnames.forgotPasswordVerification,
        component: ForgotPasswordVerificationPage,
        nav: false,
    },
    { path: pathnames.resetPassword, component: ResetPasswordPage, nav: false },
    { path: pathnames.register, component: RegisterPage, nav: false },
    { path: pathnames.verifyAccount, component: VerifyAccountPage, nav: false },
    { path: pathnames.dashboard, component: DashboardPage, nav: true },
    { path: pathnames.accounts, component: AccountPage, nav: true, private: true },
    { path: pathnames.accountProfile, component: AccountProfile, nav: true, private: true },
    { path: pathnames.accountSettings, component: AccountSettingsPage, nav: true, private: true },
    { path: pathnames.accountProductReview, component: AccountProductReviewPage, nav: true, private: true },
    { path: pathnames.accountProductReviewDetail, component: AccountProductReviewDetailPage, nav: true, private: true },
    { path: pathnames.liveStream, component: LiveStreamCreatePage, nav: true, private: true },
    {
        path: pathnames.liveStreamSelectProduct,
        component: LiveStreamSelectProductPage,
        nav: true,
        private: true,
    },
    {
        path: pathnames.liveStreamList,
        component: liveStreamListsPage,
        nav: true,
        private: true,
    },
    {
        path: pathnames.orders,
        component: OrdersPage,
        nav: true,
        private: true,
    },
    {
        path: pathnames.orderDetails,
        component: OrderDetailsPage,
        nav: true,
        private: true,
    },
    {
        path: pathnames.OrderProductDetails,
        component: OrderProductDetailsPage,
        nav: true,
        private: true,
    },
    {
        path: pathnames.adApplication,
        component: AdApplicationPage,
        nav: true,
        private: true,
    },
    { path: pathnames.products, component: ProductsPage, nav: true },
    { path: pathnames.productCreate, component: ProductCreatePage, nav: true },
    { path: pathnames.mobileUser, component: MobileUserPage, nav: true },
    { path: pathnames.about, component: AboutUsPage, nav: true },
    { path: pathnames.help, component: HelpPage, nav: true },
    { path: pathnames.faq, component: FAQPage, nav: true },
    { path: pathnames.terms, component: TermsPage, nav: true },
    { path: pathnames.policy, component: PolicyPage, nav: true },
    { path: pathnames.notFound, component: NotFoundPage, nav: true },
];
