import React, { useCallback, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';

import Button from 'components/button/button';
import TextInput from 'components/inputs/text-input/text-input';
import Error from 'components/error/error';
import Popup from 'components/popup/popup';
import overviewImage from 'assets/login/overview.svg';
import IconUserError from 'assets/icons/icon-user-error.svg';

import api from 'services/api';
import { getUserProfile, setUserAuth } from 'redux/actions/user-actions';
import { pathnames } from 'routes/routes';

const initialValues = {
    email: '',
    password: '',
};
const validationSchema = yup.object().shape({
    email: yup.string().email().required('Email is required.'),
    password: yup.string().required(),
});

const LoginPage = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [error, setError] = useState('');
    const [showPopup, setShowPopup] = useState(false);
    const onHandleSetUserAuth = useCallback((o) => dispatch(setUserAuth(o)), [dispatch]);
    const onHandleGetUserProfile = useCallback((o) => dispatch(getUserProfile(o)), [dispatch]);

    const onHandleLogin = async (values) => {
        try {
            const response = await api.post.login(values);
            const data = response.data.result;
            
            if (data.locked) {
                setShowPopup(true)
            } else {
                localStorage.setItem('@storage_token', data.jsonWebToken);
                onHandleSetUserAuth(data);
                onHandleGetUserProfile(data.userId);
                history.push(pathnames.dashboard);
            }
        } catch (error) {
            setError(error.response.data.message);
        }
    };

    return (
        <div className="login m-full-h">
            <div className="container m-container m-container--center">
                <div className="login__card m-card">
                    <section className="login__section">
                        <img src={overviewImage} alt="" />
                    </section>

                    <div className="login__divider" />

                    <section className="login__section">
                        <p className="login__headline m-txt m-txt--xl m-txt">Login</p>

                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={onHandleLogin}
                        >
                            {() => (
                                <Form>
                                    <TextInput
                                        type="text"
                                        name="email"
                                        placeholder="Email address"
                                    />
                                    <TextInput
                                        type="password"
                                        name="password"
                                        placeholder="Password"
                                    />

                                    <Error msg={error} />

                                    <Link
                                        className="m-link login__forgot"
                                        to={pathnames.forgotPasssword}
                                    >
                                        Forgot Password?
                                    </Link>

                                    <Button label="LOGIN" type="submit" />
                                </Form>
                            )}
                        </Formik>

                        <p className="login__signup m-txt m-txt--s">
                            Dont't have account yet?{' '}
                            <Link to={pathnames.register} className="m-link">
                                <b>Sign up</b>
                            </Link>
                        </p>
                    </section>
                </div>
                <Popup open={showPopup} onPopupClose={() => setShowPopup(false)} icon={<img src={IconUserError}/>} >
                    <div className="login__popup">
                        <div className="login__popup--msg">
                            <p className="m-txt m-txt--m m-txt--bold">Your account has been temporarily locked</p>
                            <p className="m-txt m-txt--s m-txt--grey">
                                We’ve detected you may have violated our terms of service. To un-locked your account, please email us at <a href="mailto:sample2021@gmail.com">sample2021@gmail.com</a>. 
                            </p>
                        </div>
                    </div>
                </Popup>
            </div>
        </div>
    );
};

export default LoginPage;
