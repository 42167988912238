import React, { useRef, useState, useCallback, useEffect, Fragment } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { BsExclamation } from 'react-icons/bs';
import Modal from 'react-responsive-modal';
import { useSelector } from 'react-redux';
import { Form, Formik, ErrorMessage } from 'formik';
import * as yup from 'yup';
import moment from 'moment';

import Dropdown from 'components/inputs/dropdown/dropdown';
import BreadCrumb from 'components/breadcrumb/breadcrumb';
import CircleAvatar from 'components/circle-avatar/circle-avatar';
import TextInput from 'components/inputs/text-input/text-input';
import Textarea from 'components/inputs/textarea/textarea';
import Button from 'components/button/button';
import { useIsMount } from 'hooks/use-mount';
import { pathnames } from 'routes/routes';
import {
    changeAddressToString,
    orderStatusFormat,
    formatCurrency,
    formatVariations,
} from 'helpers/utility';
import cancelIcon from 'assets/icons/icon-cancel-order.svg';
import constants from 'helpers/constants';
import api from 'services/api';
import config from 'helpers/config';

const OrderDetailsPage = (props) => {
    const isMount = useIsMount();
    const history = useHistory();
    const formikRef = useRef();
    const scrollableRef = useRef();
    const { state } = useLocation();
    const { id } = useSelector((state) => state.user.profile);
    const [cancelModalVisible, setCancelModalVisible] = useState(false);
    const [initialValues, setInitialValues] = useState({ trackingNumber: '', orderStatus: 0 });
    const [totals, setTotals] = useState({ shippingFee: 0, subTotal: 0, total: 0 });
    const [orderStatus, setOrderStatus] = useState(0);
    const [order, setOrder] = useState();
    const [editMode, setEditMode] = useState(state.editMode);
    const orderCreatedDate = moment(order?.orders?.createdAt).format(constants.DATE);
    const orderUpdatedDate = moment(order?.orders?.updatedAt).format(constants.DATE);
    const orderDeliveryAddress = changeAddressToString(
        JSON.stringify(order?.orders?.deliveryAddress)
    );

    const onHandleGetOrderDetails = useCallback(async () => {
        try {
            const response = await api.get.orderDetials(state.orderId);
            const data = response.data.result;
            const status = config.orders.status.filter((o) => o.value === data.orders.status);
            
            if (data.orders.status == "delivered" 
                || data.orders.status == "cancelled" 
                || data.orders.status == "cancelledByCustomer"
                || data.orders.status == "shipped"
                || data.orders.status == "pendingPayment"
                || data.orders.status == "refunded") {
                setEditMode(false)
            }

            setOrder(data);
            onHandleSumTotal(data);
            setOrderStatus(orderStatusFormat(data.orders.status));
            setInitialValues({
                orderStatus: status[0],
                trackingNumber: data.orders.trackingNumber,
            });
        } catch (error) {
            props.onHandleShowMessage(error, true);
        }
    }, [state, props]);

    const onHandleSumTotal = (data) => {
        let shippingFee = data.orders.shippingFee;
        let subTotal = 0;
        let total = 0;
        data.products.forEach((o) => {
            subTotal += o.product.price * o.quantity;
        });

        total += subTotal + shippingFee;

        setTotals({
            shippingFee,
            subTotal,
            total,
        });
    };
    
    useEffect(() => {
        onHandleGetOrderDetails();

        const interval = setInterval(() => {
            onHandleGetOrderDetails();
        }, 3000);
        return () => clearInterval(interval);
    }, []);   

    const onHandleUpdateOrder = async (values) => {
        let updateOrder = null;
        let payload = null;

        if (values.cancellationType) {
            payload = {
                id: order.orders.id,
                cancellationReason: values.cancellationReason || null,
                cancellationType: values.cancellationType || null,
            };
            updateOrder = api.post.orderCancel;
        } else {
            payload = {
                companyId: id,
                deliveryAddress: null,
                deliveryName: null,
                id: order.orders.id,
                orderStatus: values.orderStatus.value,
                shippingFee: null,
                trackingNumber: values.trackingNumber.trim(),
            };
            updateOrder = api.post.orderUpdate;
        }

        try {
            await updateOrder(payload);
            history.push(pathnames.orders);
            props.onHandleShowMessage('Order Updated');
        } catch (error) {
            props.onHandleShowMessage(error, true);
        }
    };

    const onHandleProductDetails = (o) => {
        history.push(pathnames.OrderProductDetails, { product: o });
    };

    const onHandleCancelModal = (visible, reason) => {
        const newReason = reason;
        const previousReason =
            formikRef && formikRef.current && formikRef.current.values.cancellationType;
        setCancelModalVisible(visible);

        if (!visible && !previousReason && !newReason) {
            formikRef.current.setFieldValue('orderStatus', initialValues.orderStatus);
        }

        if (reason) {
            formikRef.current.setFieldValue('cancellationReason', reason.cancellationReason);
            formikRef.current.setFieldValue('cancellationType', reason.cancellationType);
        }
    };

    const onHandleResetCancellationReason = () => {
        formikRef.current.setFieldValue('cancellationReason', '');
        formikRef.current.setFieldValue('cancellationType', '');
    };

    const formatOrderStatusLabel = () => {
        switch (orderStatus.label) {
            case "CANCELLED":
                return "Order cancelled by you"
            case "CANCELLED BY CUSTOMER":
                return "Order cancelled by customer"
            case "SHIPPED":
                return "Shipped"
            case "DELIVERED":
                return "Delivered"  
            case "PENDING PAYMENT":
                return "Pending Payment"
            case "REFUNDED":
                return "Refunded" 
            default:
                break;
        }
    }

    return (
        <div className="order-details" ref={scrollableRef}>
            <div className="m-container">
                <div className="order-details__header m-wrapper">
                    <BreadCrumb
                        paths={[{ onClick: history.goBack, text: 'Orders' }, 'Order Details']}
                    />
                    <p className="m-txt m-txt--m m-txt--bold m-txt--grey">
                        Order No: #{order?.orders?.orderNumber}
                    </p>
                </div>
            </div>
            <div className="m-container">
                <div className="m-wrapper">
                    <Formik
                        innerRef={formikRef}
                        enableReinitialize
                        initialValues={initialValues}
                        onSubmit={onHandleUpdateOrder}
                    >
                        {({ values, setFieldValue, submitForm, dirty }) => (
                            <Form>
                                <div className="order-details__content">
                                    <div>
                                        <p className="m-txt m-txt--bold">
                                            {order?.orders?.deliveryName}
                                        </p>
                                        <p className="m-txt m-txt--grey">{order?.orders?.email}</p>
                                    </div>
                                    <div>
                                        <p className="m-txt m-txt--grey">
                                            Placed on{' '}
                                            <span className="m-txt m-txt--bold">
                                                {orderCreatedDate}
                                            </span>
                                        </p>
                                    </div>
                                </div>

                                <div className="order-details__content">
                                    <div>
                                        <p className="m-txt m-txt--grey">Ship to :</p>
                                        <p className="m-txt m-txt--bold">{orderDeliveryAddress}</p>
                                    </div>
                                </div>

                                {editMode ? 
                                    (
                                        <div className="order-details__status">
                                            <p className="m-txt m-txt--grey m-txt--bold">
                                                Status update:
                                            </p>
                                            <ul className="order-details__status-list">
                                                {config.orders.status.map((o, i) => {
                                                    const key = `${i}-status-option`;
                                                    const statusClass =
                                                        values?.orderStatus?.value === o.value
                                                            ? 'order-details__status-action order-details__status-action--active'
                                                            : 'order-details__status-action';
                                                    return (
                                                        <li
                                                            key={key}
                                                            className={statusClass}
                                                            onClick={() => {
                                                                setFieldValue('orderStatus', o);
                                                                if (o.value === 'cancelled') {
                                                                    onHandleCancelModal(true);
                                                                } else {
                                                                    onHandleResetCancellationReason();
                                                                }
                                                            }}
                                                        >
                                                            <p className="m-txt m-txt--grey">
                                                                {o.label}
                                                            </p>
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </div>
                                    ) : 
                                    (
                                        <div className="order-details__status">
                                            <p className="m-txt m-txt--grey m-txt--bold">Status:</p>
                                            <p
                                                className={`m-txt m-txt--m m-txt--bold m-txt--${orderStatus.color} order-details__status--item`}
                                            >
                                                {formatOrderStatusLabel()}
                                                {'     '}
                                                <span className="m-txt m-txt--s m-txt--grey m-txt--bold">
                                                    {orderUpdatedDate}
                                                </span>
                                                {orderStatus.label == "SHIPPED" && 
                                                    <div
                                                        className={
                                                            values?.orderStatus?.value === "delivered"
                                                            ? 'order-details__status-action order-details__status-action--active'
                                                            : 'order-details__status-action'
                                                        }
                                                        onClick={() => {
                                                            setFieldValue('orderStatus', {
                                                                label: "DELIVERED",
                                                                value: "delivered"
                                                            });
                                                            submitForm()                                                               }}
                                                    >
                                                        <p className="m-txt m-txt--s m-txt--grey">
                                                            DELIVERED
                                                        </p>
                                                    </div>
                                                }
                                            </p>
                                        </div>
                                    )
                                }

                                {values.cancellationType ? (
                                    <div className="order-details__item order-details__item--tracking-no">
                                        <div className="order-details__item-wrapper">
                                            <div className="order-details__cancel-status">
                                                <div className="order-details__badge">
                                                    <BsExclamation size={20} />
                                                </div>
                                                <p className="m-txt m-txt--error">
                                                    Cancellation Reason{' '}
                                                    <span className="m-txt m-txt--s m-txt--grey">
                                                        ({config.orders.reasonToCancel.filter((o) => o.value === values.cancellationType)[0].label})
                                                    </span>
                                                </p>
                                            </div>
                                            {values.cancellationType === 3 ? <p className="m-txt">{values.cancellationReason}</p> : null}
                                        </div>
                                    </div>
                                ) : null}

                                <div className="order-details__item order-details__item--tracking-no">
                                    <div className="order-details__item-wrapper">
                                        <p className="m-txt m-txt--grey">Tracking No. (Optional)</p>
                                        <TextInput
                                            outline
                                            max
                                            disabled={!editMode && orderStatus.label !== "SHIPPED"}
                                            name="trackingNumber"
                                        />
                                    </div>
                                </div>

                                <div className="order-details__products">
                                    {order?.products.map((o, i) => {
                                        const key = `${i}-product`;
                                        return (
                                            <div
                                                key={key}
                                                className="order-details__item"
                                                onClick={() => onHandleProductDetails(o)}
                                            >
                                                <div>
                                                    <p className="m-txt m-txt--grey">
                                                        SKU: {o.product.sku}
                                                    </p>
                                                    <div className="order-details__item-wrapper order-details__item-wrapper--product">
                                                        <CircleAvatar
                                                            src={o.product.productImages[0]}
                                                            size={55}
                                                        />
                                                        <div className="order-details__item-product">
                                                            <p className="m-txt m-txt--m m-txt--bold">
                                                                {o.product.name}{' '}
                                                            </p>
                                                            <span className="m-txt m-txt--s m-txt--grey">
                                                                {formatVariations(o.product)}
                                                            </span>
                                                            <p className="m-txt m-txt--grey m-txt--bold">
                                                                RM {formatCurrency(o.product.price)}/pc
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="order-details__item-wrapper order-details__item-wrapper--total">
                                                    <div className="order-details__item-product">
                                                        <p
                                                            className={`m-txt m-txt--s m-txt--${orderStatus.color}`}
                                                        >
                                                            {orderStatus.label}
                                                        </p>
                                                        <p className="m-txt m-txt--m m-txt--bold">
                                                            x {o.quantity}
                                                        </p>
                                                        <p className="m-txt m-txt--grey m-txt--bold">{`RM ${formatCurrency(o.quantity * o.product.price)}`}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                                <div className="order-details__products">
                                    <div className="order-details__total">
                                        <p className="m-txt m-txt--m m-txt--bold">#{order?.orders?.orderNumber}</p>
                                        <div className="order-details__total-price">
                                            <p className="m-txt m-txt--m m-txt--grey m-txt--bold">
                                                Subtotal: RM {formatCurrency(totals.subTotal)}
                                            </p>
                                            <p className="m-txt m-txt--m m-txt--grey m-txt--bold">
                                                Shipping Fee: RM{' '}
                                                {formatCurrency(totals.shippingFee)}
                                            </p>
                                        </div>
                                    </div>

                                    <div className="order-details__total order-details__total--white">
                                        <div className="order-details__total-price">
                                            <p className="m-txt m-txt--m m-txt--bold m-txt--grey">
                                                Total:{' '}
                                                <span className="m-txt--error">
                                                    RM {formatCurrency(totals.total)}
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                {editMode ? (
                                    <div className="order-details__button-wrapper">
                                        <Button label="UPDATE" type="submit" disabled={!dirty} />
                                    </div>
                                ) : null}
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
            <CancellationModal
                onClick={onHandleCancelModal}
                visible={cancelModalVisible}
                initialValues={formikRef?.current?.values}
            />
        </div>
    );
};

export default OrderDetailsPage;

const initialValuesModal = {
    cancellationReason: '',
    cancellationType: '',
};

const validationSchema = yup.object().shape({
    cancellationReason: yup.string().when('cancellationType', {
        is: (value) => value === 3,
        then: yup.string().required('Cancellation Reason is required'),
    }),
});

const CancellationModal = ({ visible, onClick, initialValues }) => {
    const formInitvalue = initialValuesModal.cancellationType ? initialValuesModal : initialValues;

    const onHandleSubmit = (reason) => {
        onClick(false, reason);
    };

    return (
        <Modal center open={visible} showCloseIcon={false} onClose={() => onClick(false)}>
            <div className="order-details__cancel-card m-card">
                <img src={cancelIcon} alt="" />
                <p className="m-txt m-txt--bold">Confirm cancel this order?</p>
                <p className="m-txt m-txt--grey">The customer will get notified of this cancellation.</p>
                <Formik
                    initialValues={formInitvalue}
                    validationSchema={validationSchema}
                    onSubmit={onHandleSubmit}
                >
                    {({ values, setFieldValue, dirty }) => (
                        <Form>
                            <div className="order-details__cancel-dropdown">
                                <p className="m-txt m-txt--xs m-txt--grey m-txt--bold">Reason to cancel</p>
                                <Dropdown
                                    max
                                    value={
                                        config.orders.reasonToCancel.filter(
                                            (o) => o.value === values.cancellationType
                                        )[0]
                                    }
                                    options={config.orders.reasonToCancel}
                                    placeholder="Select reason to cancel"
                                    onChange={(d) => {
                                        setFieldValue('cancellationReason', d.label);
                                        setFieldValue('cancellationType', d.value);
                                    }}
                                />
                            </div>
                            {values.cancellationType === 3 ? (
                                <Fragment>
                                    <Textarea
                                        outline
                                        name="cancellationReason"
                                        placeholder="Enter the reason of cancellation"
                                    />
                                    <p className="m-txt m-txt--s m-txt--error">
                                        <ErrorMessage name="cancellationReason" />
                                    </p>
                                </Fragment>
                            ) : null}
                            <div className="order-details__button-wrapper">
                                <Button
                                    text
                                    small
                                    label="CANCEL"
                                    type="button"
                                    onClick={() => onClick(false)}
                                />
                                <Button
                                    text
                                    small
                                    red
                                    label="CONFIRM"
                                    type="submit"
                                    disabled={!dirty}
                                />
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </Modal>
    );
};
