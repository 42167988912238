import React from "react";
import { VscTriangleDown, VscTriangleUp } from "react-icons/vsc";

const HeaderSort = ({ sortFunction, sortKey, setActiveSortOrder, activeSortOrder }) => {
	const handleSort = (order) => {
		sortFunction(sortKey, order);
		setActiveSortOrder({ [sortKey]: order });
	};

	return (
		<div className="header__sort">
			<div
				className={activeSortOrder && activeSortOrder[sortKey] === "asc" && "header__sort--white"}
				onClick={() => handleSort("asc")}
			>
				<VscTriangleUp />
			</div>
			<div
				className={activeSortOrder && activeSortOrder[sortKey] === "desc" && "header__sort--white"}
				onClick={() => handleSort("desc")}
			>
				<VscTriangleDown />
			</div>
		</div>
	);
};

export default HeaderSort;
